import DashboardManager from './views/DashboardManager.js';
import Market from './views/Market';
// import Funds from './views/Funds';
import Trade from './views/Trade';
import Faq from './views/Faq';
// import FundDetails from './views/FundDetails';
import FundDetails from './views/FundDetails/index';
import Strategies from './views/Strategies';
// import Help from 'views/Help';
var routesManager = [
  {
    path: '/dashboard',
    name: 'Manager Dashboard',
    // icon: "tim-icons icon-chart-pie-36",
    iconImageName: 'DashboardIcon',
    component: DashboardManager,
    layout: '/manager',
  }, 
  // {
  //   path: '/Funds',
  //   name: 'Funds',
  //   // icon: "tim-icons icon-bell-55",
  //   iconImageName: 'FundIcon',
  //   component: Funds,
  //   layout: '/manager',
  // },
  // {
  //   path: '/trade',
  //   name: 'Trade',
  //   // icon: "tim-icons icon-atom",
  //   iconImageName: 'TradeIcon',
  //   component: Trade,
  //   layout: '/manager',
  // },
  {
    path: '/trade',
    name: 'Trading Terminal',
    // icon: "tim-icons icon-atom",
    iconImageName: 'TradeIcon',
    component: Trade,
    layout: '/manager',
  },
  {
    path: '/strategies',
    name: 'Fund Strategies ',
    // icon: "tim-icons icon-atom",
    iconImageName: 'BulbIcon',
    component: Strategies,
    layout: '/manager',
  },
  {
    path: '/details',
    name: 'Details',
    // icon: "tim-icons icon-pin",
    iconImageName: 'DetailsIcon',
    component: FundDetails,
    layout: '/manager', 
  },
  {
    path: '/market',
    name: 'Market',
    // icon: "tim-icons icon-pin",
    iconImageName: 'MarketIcon',

    component: Market,
    layout: '/manager',
  },
  {
    path: '/faq',
    name: 'Faq',
    // icon: "tim-icons icon-atom",
    iconImageName: 'CommentRoundedIcon',

    component: Faq,
    layout: '/manager',
  },
  //  {
  //   path: '/details/:fAddress',
  //   name: 'Details',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'MarketIcon',
  //   component: FundDetails,
  //   layout: '/user',
  //   style: 'hidden',
  // },
  // {
  //   path: '/help',
  //   name: 'Help',
  //   // icon: "tim-icons icon-atom",
  //   iconImageName: 'MarketIcon',
    
  //   component: Help,
  //   layout: '/manager',
  // },
];
export default routesManager;
