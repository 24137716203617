const SET_FUND_ADDRESS = 'SET_FUND_ADDRESS';

const fundAddressReducer = (state = '', action) => {
  const fundAddress = action.fundAddress;
  switch (action.type) {
    case SET_FUND_ADDRESS:
      return {
        fundAddress,
      };
    default:
      return state;
  }
};

export default fundAddressReducer;
