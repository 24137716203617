import React, { useState, useEffect } from 'react';
// import { NavLink, Link } from "react-router-dom";

import { useHistory } from 'react-router';

import {
  Modal,
  ModalHeader,
  ModalBody,
  Card,
  CardBody,
  CardFooter,
  Button,
  Label,
  Form,
  FormGroup,
  Input,
  Spinner,
  Tooltip
} from 'reactstrap';
// import Radio from '@material-ui/core/Radio';
import HelpIcon from '@material-ui/icons/Help';

import { Slider, MuiThemeProvider } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';

import { setGlobalFundAddress ,setGlobalFactoryAddress } from '../../redux/actions';
// redux web3 state
import { useSelector, useDispatch } from 'react-redux';
// import { fundFactoryAbi } from '../../utils/getAbi';
import RouterDefi from '../../contracts/RouterDefi.json';

import {
  // FUND_FACTORY_PANCAKESWAP_ADDR,
  // FUND_FACTORY_WAULTSWAP_ADDR,
  FUND_FACTORY_DEFI_ADDR} from '../../utils/constants';
import { sendTrxNotify } from '../../utils/notify';

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      root: {
        color: "#6f8eff",
        height: 3,
        padding: "13px 0",
      },
      valueLabel: {
        color: '#818394'
      },
      thumb: {
        color: " #5c308a",
      },
      track: {
        color: '#5c308a'
      },
      rail: {
        color: 'black'
      },
      mark: {
        color: 'black'
      },
      markLabel: {
        color: '#818394'
      },
      markLabelActive: {
        color: '#5c308a'
      }
    }
  }
});

const marks = [
  {
    value: 5,
    label: '5%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 60,
    label: '60%',
  },
];

const performanceMarks = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 10,
    label: '10%',
  },
  {
    value: 20,
    label: '20%',
  },
  {
    value: 30,
    label: '30%',
  },
  {
    value: 40,
    label: '40%',
  },
];

function valuetext(value) {
  return `${value}%`;
}

export default function StartFund(props) {
  const history = useHistory();
  const web3 = useSelector((state) => state.web3Reducer.web3);
  // const address = useSelector((state) => state.addressReducer.address);
  // const fundAddress = useSelector((state) => state.fundAddressReducer.fundAddress);
  const dispatch = useDispatch();

  const [termsAndConditions, setTermsAndConditions] = useState(false);

  const [miatooltipOpen, setMiaTooltipOpen] = useState(false);
  // const [dexMiatooltipOpen, setDexMiaTooltipOpen] = useState(false);
  const [mrtooltipOpen, setMrTooltipOpen] = useState(false);
  const [pftooltipOpen, setPfTooltipOpen] = useState(false);


  const toggleMiaToolTip = () => setMiaTooltipOpen(!miatooltipOpen);
  // const toggleDexMiaToolTip = () => setDexMiaTooltipOpen(!dexMiatooltipOpen);
  const toggleMrToolTip = () => setMrTooltipOpen(!mrtooltipOpen);
  const togglePfToolTip = () => setPfTooltipOpen(!pftooltipOpen);

  const [error, setError] = useState("");
  const [errorTC, setErrorTC] = useState("");
  const [minAmount, setMinAmount] = useState(0);
  const [minReturn, setMinReturn] = useState(10);  // should change the default display also
  const [performanceFeePercentage, setPerformanceFeePercentage] = useState(20);  // should change the default display also
  const [createFundLoading, setCreateFundLoading] = useState(false);

  useEffect(() => {
    if (!web3) {
      console.log('No web3');
      alert('No web3 Please connect Wallet');
      history.push('../../user/dashboard');
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCheck = (event) => {
    setTermsAndConditions(event.target.checked);
    if (event.target.checked) {
      setErrorTC("");
    }
  }

  const handleMinAmountChange = (event) => {
    console.log("Min:", parseInt(event.target.value));
    if (parseInt(event.target.value) < 1) {
      setError("Minimum Amount should be greater than 1");
    } else {
      setError("");
    }
    setMinAmount(event.target.value);
  }

  // const [fundFactoryAddress, setFundFactoryAddress] = useState(FUND_FACTORY_DEFI_ADDR);
  const fundFactoryAddress = FUND_FACTORY_DEFI_ADDR;
  // const [factoryAbiType, setFactoryAbiType] = useState(1);

  // CURRENTLY NOT USED SINCE ONLY 
  // const handleDexSelect = (event) => { 
  //   if (event.target.value == '1') {
  //     setFundFactoryAddress(FUND_FACTORY_PANCAKESWAP_ADDR);
  //     setFactoryAbiType(1);
  //   }   
  //   else if (event.target.value == '2') {
  //     setFundFactoryAddress(FUND_FACTORY_WAULTSWAP_ADDR);
  //     setFactoryAbiType(2);
  //   }
  //   else {
  //     setFundFactoryAddress(FUND_FACTORY_DEFI_ADDR);
  //     setFactoryAbiType(3);
  //   }
  // };

  const handleStartFund = async (event) => {
    event.preventDefault();
    event.persist();
    if (!termsAndConditions) {
      setErrorTC("You must accept the Terms & Conditions");
      return;
    }
    console.log("minAmount:", minAmount);
    if (minAmount < 1) {
      setError("Minimum Amount should be greater than 1");
      return;
    }
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];

    const STMinAmount = web3.utils.toWei(minAmount); //event.target.STMinAmount.value;
    const STMinReturn = minReturn * 1000000; //event.target.STMinReturn.value;
    const STperformanceFeePercentage = performanceFeePercentage * 1000000;

    console.log(' STMinAmount:' + STMinAmount + ' STMinReturn: ' + STMinReturn + ' STperformanceFeePercentage: ' + STperformanceFeePercentage + ' user address: ' + address);
    console.log(`handleStartFund fundFactoryAddress : `, fundFactoryAddress);
    const fundFactoryInstance = new web3.eth.Contract(
      RouterDefi, //fundFactoryAbi(factoryAbiType),
      fundFactoryAddress
    );
    console.error('testing fundFactoryI :', fundFactoryInstance);

    try {
      setCreateFundLoading(true);
      let CreatedFund = fundFactoryInstance.methods.createFund(
        STMinAmount,
        STMinReturn,
        STperformanceFeePercentage
      );

      await sendTrxNotify(CreatedFund, address, "Fund Created sucessfully..");

      let fundAddress = await fundFactoryInstance.methods
        .getManagerToFund(address)
        .call({ from: address });
      console.log('fundAddress:', fundAddress);
      dispatch(setGlobalFundAddress(fundAddress));
      dispatch(setGlobalFactoryAddress(FUND_FACTORY_DEFI_ADDR));

      history.push('../../manager/dashboard');

      setCreateFundLoading(false);

    } catch (error) {
      console.error('handleCreateST: ', error);
      setCreateFundLoading(false);
    }
  };



  return (
    <div className="content">
      <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader
          className="text-center"
          cssModule={{ 'modal-title': 'w-100 text-center' }}
          toggle={props.toggle}
          style={{ height: '40px' }}
        >
          <div className="text-center"><h3>Start Fund</h3></div>
        </ModalHeader>
        <ModalBody className=" ml-5 mr-5">
          <Form onSubmit={handleStartFund}>
            <Card style={{ boxShadow: 'none' }}>
              <CardBody>

                {/*   REMOVED SINCE WILL NOW BE HAVING DEFI FUNDS WITH PANCAKE ONLY
                <FormGroup>
                  <Label style={{color: 'white', fontWeight : '600'}}>Choose Fund Type</Label>
                  <span>
                    <HelpIcon
                      className="ml-2"
                      id="dexMiaTooltip"
                      fontSize="small"
                      style={{ color: '#7639a7' }}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={dexMiatooltipOpen}
                      target="dexMiaTooltip"
                      autohide={true}
                      toggle={toggleDexMiaToolTip}
                      style={{ background: '#141634', color: 'white' }}
                    >
                      Trading engine for the fund  
                    </Tooltip>
                  </span>
                  <div style={{  textAlign: "center", color: 'white' }}>
                PancakeSwap
                  <Radio checked={fundFactoryAddress === FUND_FACTORY_PANCAKESWAP_ADDR}
                    onChange={handleDexSelect}
                    value="1"
                      name="radio-button-demo"
                      style={{color: '#818394'}}
                  />
                  WaultSwap
                  <Radio checked={fundFactoryAddress === FUND_FACTORY_WAULTSWAP_ADDR}
                    onChange={handleDexSelect}
                    value="2"
                      name="radio-button-demo"
                      style={{color: '#818394'}}
                    />
                     Defi Fund 
                  <Radio checked={fundFactoryAddress === FUND_FACTORY_DEFI_ADDR}
                    onChange={handleDexSelect}
                    value="3"
                      name="radio-button-demo"
                      style={{color: '#818394'}}
                    />
                    </div>
                </FormGroup> 
                */}
                <FormGroup>
                  <Label style={{color: 'white'}}>Minimum Investible Amount in BUSD</Label>
                  <span>
                    <HelpIcon
                      className="ml-2"
                      id="miaTooltip"
                      fontSize="small"
                      style={{ color: '#7639a7' }}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={miatooltipOpen}
                      target="miaTooltip"
                      autohide={true}
                      toggle={toggleMiaToolTip}
                      style={{ background: '#141634', color: 'white' }}
                    >
                      Minimum amount your fund would be accepting from investors.
                      Below this threshold investors won’t be able to invest in your fund.
                      We suggest to keep it in range of 100-500$. Beware this can be set only once per fund creation.
                    </Tooltip>
                  </span>
                  <Input
                    type="string"
                    placeholder="Minimum Investible Amount in USDT"
                    name="STMinAmount"
                    value={minAmount}
                    onChange={handleMinAmountChange}
                  />
                  <p style={{ color: "red" }}>{error}</p>
                </FormGroup>
                <div style={{ width: 300 }}>
                  <Label style={{ color: 'white' }} >
                    Minimum Return %
                    </Label>
                  <span>
                    <HelpIcon
                      className="ml-2"
                      id="mrtooltip"
                      fontSize="small"
                      style={{ color: '#7639a7' }}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={mrtooltipOpen}
                      target="mrtooltip"
                      toggle={toggleMrToolTip}
                      style={{ background: '#141634', color: 'white' }}
                    >
                      This is the profit percentage at which you will be able to collect your performance fee.
                      We suggest you keep it in a range of 8-15%. Beware this can be set only once per fund creation.
                    </Tooltip>
                  </span>
                  <MuiThemeProvider theme={muiTheme}>
                    <Slider
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider-restrict"
                      defaultValue={20}
                      step={1}
                      min={5}
                      max={60}
                      style={{ color: '#8a8c9c' }}
                      valueLabelDisplay="auto"
                      marks={marks}
                      onChange={(event, newValue) => setMinReturn(newValue)}
                    />
                  </MuiThemeProvider>
                </div>
                <div style={{ width: 300 }}>
                  <Label style={{ color: 'white' }} >
                    Performance Fee %
                    </Label>
                  <span>
                    <HelpIcon
                      className="ml-2"
                      id="pfTooltip"
                      fontSize="small"
                      style={{ color: '#7639a7' }}
                    />
                    <Tooltip
                      placement="right"
                      isOpen={pftooltipOpen}
                      target="pfTooltip"
                      autohide={true}
                      toggle={togglePfToolTip}
                      style={{ background: '#141634', color: 'white' }}
                    >
                      This is the performance fee percentage you will collecting on the profits made for investors.
                      We suggest you keep it in a range of 20-35%. Beware this can be set only once per fund creation.
                    </Tooltip>
                  </span>
                  <MuiThemeProvider theme={muiTheme}>
                    <Slider
                      getAriaValueText={valuetext}
                      aria-labelledby="discrete-slider-restrict"
                      defaultValue={20}
                      step={1}
                      min={0}
                      max={40}
                      style={{ color: '#8a8c9c' }}
                      valueLabelDisplay="auto"
                      marks={performanceMarks}
                      onChange={(event, newValue) => setPerformanceFeePercentage(newValue)}
                    />
                  </MuiThemeProvider>


                  <FormControlLabel
                    control={
                      <Checkbox
                        // checked={termsAndConditions}
                        onChange={handleCheck}
                        name="checkedB"
                        style={{color: '#818394'}}
                      />
                    }
                    label=""
                    style={{marginRight: '3px'}}
                  />        
                    <Label style={{ color: 'white' , fontSize: '1rem',  fontFamily: "Roboto"  }} >
                    I agree to the
                    {/* 
                    <a href="https://enzyme.finance/terms" target="_blank"
                      style={{ color: 'rgba(111,86,253,1)', fontWeight: '500', fontSize: '1rem', fontFamily: "Roboto" }}>
                      {`Terms & Conditions`}</a>. 
                      */}
                     <Link target="_blank" to={`/user/terms`}
                                style={{ color: 'rgba(111,86,253,1)', fontWeight: '500', fontSize: '1rem', fontFamily: "Roboto" }}>
                                {` Terms & Conditions`}
                            </Link>.
                  </Label>
                  <p style={{ color: "red" }}>{errorTC}</p>
                  
                </div>
              </CardBody>

              <CardFooter className="p-0">
                <div className="text-center">
                  <Button
                    className="btn-round btn-submit"
                    size="md"
                    type="submit"
                    // disable='true'
                  >
                    {createFundLoading && (
                      <>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        <span>Creating Fund</span>
                      </>
                    )}
                    {!createFundLoading && 'Start Fund'}
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
}
