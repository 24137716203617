import React from 'react';
import { InputGroup } from 'reactstrap';
const SearchBox = ({ searchfield, searchChange }) => {
  const elementStyle = {
    // border: 'solid',
    borderRadius: '28px',
    // position: 'relative',
    // left: '10vh',
    // height: '5vh',
    // width: '50vh',
    // marginTop:'5vh',
    // marginBottom:'10vh'
  };

  const onBlurFunc = (event) => {
    // event.target.value = '';
  };

  return (
    <div style={elementStyle} className="content">
      <InputGroup className="blue search-bar" tag="li">
        <input
          className="form-control mr-sm-2 ml-2"
          type="search"
          placeholder="Search..."
          onChange={(e) => searchChange(e)}
          onBlur={onBlurFunc}
        />
        <button
          aria-label="Close"
          className="close mr-3"
          data-dismiss="modal"
          type="button"
        >
          <i className="tim-icons icon-zoom-split" />
        </button>
      </InputGroup>
    </div>
  );
};
export default SearchBox;
