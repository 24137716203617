export const displayEther = (ether, decimals) => {
  // input will be a number like ether = 1.454.... i.e after toWei() is called
  let str = ether.toString();
  let d = str.indexOf('.');
  str = str.substring(0, d) + str.substring(d, d + decimals + 1);
  return str;
};
export const displayUSDT = (amt, precision) => {
  // input will be a number like amtUSDT = 980000 will return 9.8
  if (!amt) return ''
  let str = amt.toString();
  // let d = str.indexOf(".");
  // str = str.substring(0, d) + str.substring(d, d + decimals + 1);
  let decimals = str.slice(-18); // slice(str.length -6)
  let no = str.slice(0, -18) || '0';
  let r = no + '.' + decimals.slice(0, precision);
  return r;
};
export const roundDownTo6Decimals = (value) => {
  if (value) {
    return parseFloat(Math.floor(value * 1000000) / 1000000).toFixed(6)
  } else {
    return 0
  }
}

export const roundDownTo4Decimals = (value, decimal=4) => {
  if (value) {
    return parseFloat(Math.floor(value * 10000) / 10000).toFixed(decimal)
  } else {
    return 0
  }
}