import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import PropTypes from 'prop-types';

function Pie({heading,data}) {

  let labels=[], balances=[];
  if (data) {
    // console.log("Pie chart data:", data);
    
    // data =
      data.sort((a, b) => parseFloat(b.balance) - parseFloat(a.balance));
    // console.log("Pie chart data after sort:", data);
    let top= data;
    if(data.length>4){
     top= data.slice(0,4);
      const others = data.slice(4,data.length);
      const othersBal= others.map((b => b.balance)).reduce((a,b)=> parseFloat(a)+parseFloat(b),0);
      top.push({symbol : "OTHERS", balance : othersBal});
    }
    const total = top.map((b => b.balance)).reduce((a,b)=> parseFloat(a)+parseFloat(b),0);
    labels = top.map(i => i.symbol + '%');
    // console.log("Pie chart labels : ", labels)
    balances = top.map(i => ((i.balance / total) * 100).toFixed(2));
    // console.log("Pie chartbalances:", balances);
    
  }
  

  const options = {
    labels,
    datasets: [
      {
        data : balances,
        backgroundColor: ['#4055d4', '#3344aa', '#26337f', '#202b6a', "#1a2255"],
        // hoverBackgroundColor: ['#2b2b53', '#6B54F0'],
      },
    ],
    text: '30%',
  };
  return (
    <div>
      <h3 style={{ textAlign: 'center' }}>{heading}</h3>
      
      <Doughnut data={options} />
    </div>
  );
}

export default Pie;

Pie.propTypes = {
  heading: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired
}


