import { CHAIN_ID } from "./constants";

const BSC_CHAIN_CONFIG = {
    chainId: "0x38",
    chainName: "Binance Smart Chain Mainnet",
    rpcUrls: [
      "https://bsc-dataseed1.binance.org",
      "https://bsc-dataseed2.binance.org",
      "https://bsc-dataseed3.binance.org",
      "https://bsc-dataseed4.binance.org",
      "https://bsc-dataseed1.defibit.io",
      "https://bsc-dataseed2.defibit.io",
      "https://bsc-dataseed3.defibit.io",
      "https://bsc-dataseed4.defibit.io",
      "https://bsc-dataseed1.ninicoin.io",
      "https://bsc-dataseed2.ninicoin.io",
      "https://bsc-dataseed3.ninicoin.io",
      "https://bsc-dataseed4.ninicoin.io",
      "wss://bsc-ws-node.nariox.org"
    ],
    nativeCurrency: {
      "name": "Binance Chain Native Token",
      "symbol": "BNB",
      "decimals": 18
    },
    blockExplorerUrls: ["https://bscscan.com/"]
}
  
const KOVAN_CHAIN_CONFIG = {
    chainId: "0x2A",
    chainName: "Kovan Test Network",
    rpcUrls: [
      "https://kovan.infura.io/v3/undefined"
    ],
    nativeCurrency: {
      "name": "KOVAN ETHEREUM",
      "symbol": "ETH",
      "decimals": 18
    },
    blockExplorerUrls: ["https://kovan.etherscan.io/"]
}
  
export const getNetworkConfig = () => {
    switch (CHAIN_ID) {
        case 42:
            return KOVAN_CHAIN_CONFIG;
        case 56:
            return BSC_CHAIN_CONFIG;
        default:
            break;
    }
}