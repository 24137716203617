// eslint-disable-next-line
import React, { useState, useEffect } from 'react'
import { Container, Row, Col, FormGroup, Input, Spinner } from 'reactstrap';
import Chip from '@material-ui/core/Chip';
// import { ReactComponent as TractorIcon } from '../assets/img/sidebar-icons/tractor.svg'
// import { ReactComponent as InvestinIcon } from '../assets/img/logoWithoutText.svg'
import logo from '../assets/img/logo.png';
// import AnimatedNumber from 'react-animated-number';
// import IERC20Abi from '../contracts/IERC20.json';
import LPTokenAbi from '../contracts/LPTokenAbi.json';
import {
    // TOKEN_PER_BLOCK,
    // getPoolApy
} from "../views/Staking";
import { MaxUint256 } from '@ethersproject/constants';
import BigNumber from 'bignumber.js';
import { sendTrxNotify } from '../utils/notify';
import { useSelector } from 'react-redux';
import FundA from '../contracts/Fund.json';
import { roundDownTo4Decimals } from '../utils/helperFunctions';


const StakeCard = ({ web3, heading, multiplier, FARMING_ADDRESS_HERE, abi, address }) => {

    const [stakeValue, setStakeValue] = useState(0);
    const [unStakevalue, setUnStakeValue] = useState(0);
    const [farmingInstance, setFarmingInstance] = useState(undefined)

    const [apy, setApy] = useState(0);
    const [pendingReward, setPendingReward] = useState(0);
    const [allowance, setAllowance] = useState(0);
    const [amount, setAmount] = useState(0)
    const [lpTokenBalance, setLpTokenBalance] = useState(0);
    const [lpTokenInstance, setLPTokenInstance] = useState({});

    const [harvestLoading, setHarvestLoading] = useState(false);
    const [stakeLoading, setStakeLoading] = useState(false);
    const [unStakeLoading, setUnStakeLoading] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);

    const investments = useSelector(
        (state) => state.investmentsReducer.investments
    );
    const fundAddress = useSelector(
        (state) => state.fundAddressReducer.fundAddress
    );
    const [disable10x, setDisable10x] = useState(true);
    const [minI, setMinI] = useState(0);
    const [minAUM, setMinAUM] = useState(0);
    const [tokenIds, setTokenIds] = useState([]);
    const [activeInvesterAmount, setActiveInvesterAmount] = useState(0);
    const [managerAUM, setManagerAUM] = useState(0);

    useEffect(() => {
        fetchData();
        const interval = setInterval(() => {
            fetchData();
        }, 2000);
        return () => clearInterval(interval);
    }, []) // eslint-disable-line react-hooks/exhaustive-deps


    useEffect(() => {
        (async () => {
            if (multiplier === '10x' && minI !== 0 && minAUM !== 0) {
                console.log('MinI and MinAUM :', minI, minAUM);

                let sufficientTokenIds = [];

                if (investments && investments.length !== 0) {
                    let activeInvestments = investments.filter((i) => i.status === 'Active');
                    console.log('activeInvestments : ', activeInvestments);
                    let orderInvestements = activeInvestments.sort((a, b) => (parseFloat(a.amount) < parseFloat(b.amount)) ? 1 : -1);
                    console.log('orderInvestements: ', orderInvestements);

                    let tamt = 0;
                    for (const i of orderInvestements) {
                        tamt += parseFloat(i.amount);
                        sufficientTokenIds.push(i.tokenId)
                        if (tamt >= minI) {
                            break;
                        }
                    }
                    console.log('tamt:', tamt, minI);
                    setActiveInvesterAmount(tamt);

                    if (tamt >= minI) {
                        console.log('sufficientTokenIds:', sufficientTokenIds);
                        setTokenIds(sufficientTokenIds);
                        setDisable10x(false);
                        return;
                    }
                    console.log('insufficeint investedAmt : ' + tamt + ' < ' + minI);
                }

                if (web3 && fundAddress && fundAddress !== '0x0000000000000000000000000000000000000000') {
                    let aum = 0;
                    const instance = new web3.eth.Contract(FundA, fundAddress);
                    aum = (await instance.methods.getFundDetails().call())[4];
                    aum = parseFloat(web3.utils.fromWei(aum, 'ether'));

                    console.log("** aum ", aum);
                    setManagerAUM(aum.toFixed(2));
                    if (aum >= minAUM) {
                        setTokenIds([0]);
                        setDisable10x(false);
                        return;
                    }
                    console.log('insufficeint AUM: ' + aum + ' < ' + minAUM);
                }

                setDisable10x(true);
                setTokenIds(null);
            }
        })()

    }, [investments, fundAddress, minI, minAUM]); // eslint-disable-line react-hooks/exhaustive-deps



    const fetchData = async () => {

        // const BNBaddr = '0x617aecb6137b5108d1e7d4918e3725c8cebdb848'; 
        const response = await
            fetch(`https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd`);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        const ethPrice = json.binancecoin.usd; //json[BNBaddr]['usd']  //2199
        // console.log("ethPrice: ", ethPrice);

        const farmingInstance = new web3.eth.Contract(abi, FARMING_ADDRESS_HERE);
        setFarmingInstance(farmingInstance);

        if (multiplier === '10x') {
            let minInvestmentAmt = await farmingInstance.methods.minI().call();
            setMinI(parseFloat(web3.utils.fromWei(minInvestmentAmt, "ether")));
            let minAUMAmt = await farmingInstance.methods.minAUM().call();
            setMinAUM(parseFloat(web3.utils.fromWei(minAUMAmt, "ether")));
        }

        // const totalAllocPoint = await farmingInstance.methods.totalAllocPoint().call();

        const rewardToken = await farmingInstance.methods.rewardToken().call();
        let userInfoVar = await farmingInstance.methods.userInfo(address).call();
        // console.log("userInfo::", userInfoVar);

        // setAmount(parseFloat(web3.utils.fromWei(userInfoVar.amount, "ether")).toFixed(4));
        // console.log('parseFloat(web3.utils.fromWei(userInfoVar.amount, "ether")) BALANCE:', parseFloat(web3.utils.fromWei(userInfoVar.amount, "ether")))
        setAmount(parseFloat(web3.utils.fromWei(userInfoVar.amount, "ether")));

        //change deposited call to userinfo call and then "amount"
        const pending = await farmingInstance.methods.pendingReward(address).call();
        // setPendingReward(parseFloat(web3.utils.fromWei(pending)).toFixed(4));
        setPendingReward(roundDownTo4Decimals(parseFloat(web3.utils.fromWei(pending))));

        //pid=0
        const poolInfo = (await farmingInstance.methods.poolInfo(0).call())
        // console.log("ethPrice , rewardToken  , pending , poolInfo ", ethPrice, rewardToken, pending, poolInfo);

        const lpTokenInstance = new web3.eth.Contract(LPTokenAbi, poolInfo["lpToken"]);
        setLPTokenInstance(lpTokenInstance);
// eslint-disable-next-line
        let balanceOfFarmingContract = parseFloat(await lpTokenInstance.methods.balanceOf(FARMING_ADDRESS_HERE).call());

        let lpTokenBalanceVar = await lpTokenInstance.methods.balanceOf(address).call();
        // lpTokenBalanceVar = parseFloat(web3.utils.fromWei(lpTokenBalanceVar, "ether")).toFixed(4);
        lpTokenBalanceVar = roundDownTo4Decimals(parseFloat(web3.utils.fromWei(lpTokenBalanceVar, "ether")));
        // console.log('LPTOKEN BALANCE:', lpTokenBalanceVar)
        setLpTokenBalance(lpTokenBalanceVar);

        const allowance = await lpTokenInstance.methods.allowance(address, FARMING_ADDRESS_HERE).call();
        setAllowance(allowance)
// eslint-disable-next-line
        const tokenPerBlock = await farmingInstance.methods.rewardPerBlock().call();

        // const tokenPerBlock = (parseFloat(poolInfo["allocPoint"]) * (TOKEN_PER_BLOCK)) / (parseFloat(totalAllocPoint))

        // console.log("balanceOfFarmingContract, allowance, tokenPerBlock", balanceOfFarmingContract, allowance, tokenPerBlock);
// eslint-disable-next-line
        let stakingTokenPrice = 0;let rewardTokenPrice = 0
        let token1 = await lpTokenInstance.methods.token1().call()
        let { _reserve0, _reserve1 } = await lpTokenInstance.methods.getReserves().call()
        //          globalReserve0 = _reserve0;
        //          globalReserve1 = _reserve1;
        const totalSupply = await lpTokenInstance.methods.totalSupply().call()
        // console.log(`totalSupply : `, totalSupply)
        if (rewardToken === token1) {
            stakingTokenPrice = (new BigNumber(_reserve0 * ethPrice)).div(totalSupply).times(2)
            rewardTokenPrice = (new BigNumber(_reserve0 * ethPrice)).div(_reserve1)
            // console.log("1 stakingTokenPrice , rewardTokenPrice :", stakingTokenPrice, rewardTokenPrice);
        } else {
            stakingTokenPrice = (new BigNumber(_reserve1 * ethPrice)).div(totalSupply).times(2)
            rewardTokenPrice = (new BigNumber(_reserve1 * ethPrice)).div(_reserve0)
            // console.log("2 stakingTokenPrice , rewardTokenPrice :", stakingTokenPrice, rewardTokenPrice);
        }

        // console.log(`ethPrice : `, ethPrice)
        // console.log(`reserves : `, _reserve0, _reserve1)
        // console.log(`stakingTokenPrice : `, parseFloat(stakingTokenPrice))
        // console.log(`rewardTokenPrice : `, parseFloat(rewardTokenPrice))
        let apy = 0;
        // apy = parseFloat(getPoolApy(stakingTokenPrice, rewardTokenPrice, balanceOfFarmingContract, tokenPerBlock)).toFixed(2);
        // console.log("apy = ", apy);
        setApy(apy);
    }

    const harvest = async () => {
        // pid =0 , 0= dont withdraw my stake with withdraw rewards
        console.log("harvest called:");
        // await farmingInstance.methods.withdraw(0).send({ from: address });
        setHarvestLoading(true);
        try {
            const withdrawHarvest = farmingInstance.methods.withdraw(0);
            await sendTrxNotify(withdrawHarvest, address, "Harvested sucessfully..");
        } catch (error) {
            console.error("error in harvest :", error);
            setHarvestLoading(false);
            return;
        }
        setHarvestLoading(false);
    }

    const deposit = async () => {
        console.log('** tokenids:', tokenIds);

        // pid value
        console.log("deposit called value :", stakeValue);
        // await farmingInstance.methods.deposit(web3.utils.toWei(`${stakeValue}`)).send({ from: address });
        setStakeLoading(true);
        try {
            let stakeLP;
            if (multiplier === '10x') {
                stakeLP = farmingInstance.methods.deposit(web3.utils.toWei(`${stakeValue}`), tokenIds);
            }
            else {
                stakeLP = farmingInstance.methods.deposit(web3.utils.toWei(`${stakeValue}`));
            }
            await sendTrxNotify(stakeLP, address, "Staked sucessfully..");
        } catch (error) {
            console.error("error in stake :", error);
            setStakeLoading(false);
            return;
        }
        setStakeLoading(false);
    }

    const unstake = async () => {
        console.log("unstake called value :", unStakevalue);
        // await farmingInstance.methods.withdraw(web3.utils.toWei(`${unStakevalue}`)).send({ from: address });
        setUnStakeLoading(true);
        try {
            const unstakeLP = farmingInstance.methods.withdraw(web3.utils.toWei(`${amount}`));
            await sendTrxNotify(unstakeLP, address, "UnStaked sucessfully..");
        } catch (error) {
            console.error("error in unStake :", error);
            setUnStakeLoading(false);
            return;
        }
        setUnStakeLoading(false);
    }

    const approve = async () => {
        console.log("approve called :");
        // await lpTokenInstance.methods.approve(FARMING_ADDRESS_HERE, MaxUint256).send({ from: address });
        setApproveLoading(true);
        try {
            const approval = lpTokenInstance.methods.approve(FARMING_ADDRESS_HERE, MaxUint256);
            await sendTrxNotify(approval, address, "Approved sucessfully..");
        } catch (error) {
            console.error("error in approve :", error);
            setApproveLoading(false);
            return;
        }
        setApproveLoading(false);
    }
    return (
        <Container className="card farming-card" style={{ display: 'inline-table' }}>
            {/* heading */}
            <h4>{heading}</h4>
            <Row className="pb-3">
                <Col><img src={logo} alt="" width="50px" /></Col>
                {/* <Col style={{ height: '10px' }}><InvestinIcon style={{  color: '#9595A9' }}    /></Col> */}
                <Col className="text-right pr-1">
                    <div style={{ fontSize: '1.3em', color: 'white' }} className="font-weight-bold">IVN-BNB</div>
                    <div><Chip
                        style={{ background: '#B442D4', color: 'white', height: '20px' }}
                        label={multiplier}
                    /></div>
                </Col>
            </Row>
            {/* APR */}
            <Row className="pb-3">
                <Col><p>APY:</p></Col>
                <Col className="text-right "><div className="font-weight-bold" style={{ color: 'white' }}>{apy}%</div></Col>
            </Row>
            <Row className="pb-3">
                <Col><p>EARN:</p></Col>
                <Col className="text-right"><p className="font-weight-bold">IVN</p></Col>
            </Row>
            <Row className="">
                <Col><p style={{ fontSize: "0.8em" }}> IVN EARNED</p></Col>
            </Row>
            <Row className="pb-4 align-items-center">
                <Col><p className="font-weight-bold" style={{ fontSize: '1.3em' }}>{pendingReward}</p></Col>
                <Col className="text-right p-0">
                    <button className="btn btn-round btn-submit" onClick={harvest}>
                        {harvestLoading && (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Harvesting ..</span>
                            </>
                        )}
                        {!harvestLoading && 'HARVEST'}
                    </button>
                </Col>
            </Row>
            {
                allowance > 0 &&
                <>
                    <Row className="">
                        <Col><p className="font-weight-bold" style={{ fontSize: "1em" }}>Available Balance : {lpTokenBalance}</p></Col>
                    </Row>

                    <Row className="pb-4 align-items-center">
                        {
                            <>
                                <Col>
                                    <FormGroup className="pt-2">
                                        <Input
                                            className="font-weight-bold"
                                            style={{ fontSize: '1.3em' }}
                                            type="number"
                                            name="email"
                                            id="exampleEmail"
                                            placeholder="0"
                                            onChange={(event) => setStakeValue(event.target.value)} />
                                    </FormGroup>
                                </Col>
                                <Col className="text-right p-0">
                                    <button className="btn btn-round btn-submit" onClick={() => deposit(`${stakeValue}`)} disabled={true}>
                                        {stakeLoading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span>STAKING ...</span>
                                            </>
                                        )}
                                        {!stakeLoading && 'STAKE LP'}
                                    </button>
                                </Col>
                            </>

                        }
                    </Row>
                    <Row className="">
                        <Col><p className="font-weight-bold" style={{ fontSize: "1em" }}>IVN-BNB LP STAKED : {roundDownTo4Decimals(amount)}</p></Col>
                    </Row>

                    <Row className="pb-1 align-items-center">
                        {

                            <>
                                <Col lg='5' xs='8'>
                                    <FormGroup className="pt-2">
                                        <Input
                                            className="font-weight-bold"
                                            style={{ fontSize: '1.3em', display: 'inline' }}
                                            type="number"
                                            name="email"
                                            id="exampleEmail"
                                            placeholder="0"
                                            value = {(unStakevalue)}
                                            onChange={(event) => setUnStakeValue(event.target.value)} />

                                    </FormGroup>
                                </Col>
                                <Col lg='1' xs='4'>
                                    <button className="btn btn-round btn-submit" style={{ padding: '10px', marginLeft:'-10px' }} onClick={() => setUnStakeValue(`${amount}`)}>
                                        MAX
                                    </button>
                                </Col>
                                <Col lg='1'></Col>
                                <Col lg='5' xs='12' className="text-right p-0">

                                    <button className="btn btn-round btn-submit" onClick={() => unstake(`${unStakevalue}`)}  >
                                        {unStakeLoading && (
                                            <>
                                                <Spinner
                                                    as="span"
                                                    animation="border"
                                                    size="sm"
                                                    role="status"
                                                    aria-hidden="true"
                                                />
                                                <span>UNSTAKING ..</span>
                                            </>
                                        )}
                                        {!unStakeLoading && 'UNSTAKE LP'}
                                    </button>
                                </Col>
                            </>
                        }
                    </Row>
                </>
            }
            {
                allowance <= 0 &&
                <Row className="pb-1 align-items-center">
                    {

                        <Col className="text-right p-0">
                            <button className="btn btn-round btn-submit w-100" onClick={approve}
                                // disabled={multiplier === '10x' && disable10x}
                                disabled={true}
                            >
                                {approveLoading && (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>Approving ..</span>
                                    </>
                                )}
                                {!approveLoading && 'Approve Contract'}
                            </button>
                        </Col>

                    }
                </Row>
            }
            { multiplier === '10x' &&
                <Row className="pb-3">
                    <Col className="text-center ">
                        {
                            !disable10x
                                ?

                                <div className="font-weight-bold" style={{ color: 'chartreuse', fontSize: '0.9rem', paddingTop: "20px" }}>
                                    STAKING: ELIGIBLE
                                </div>

                                :
                                <div>
                                    <div className="font-weight-bold" style={{ color: 'red', fontSize: '1.1rem', paddingTop: "20px" }}>
                                        STAKING : INELIGIBLE
                                    </div>
                                    <p style={{ color: 'lavender', fontSize: '0.8rem', paddingTop: "5px" }}>
                                        Active Invested Amount: {activeInvesterAmount} AUM: {managerAUM} </p>
                                </div>

                        }

                    </Col>
                </Row>
            }

            { multiplier === '10x' &&
                <Row className="pb-3">
                    <Col className="text-center ">
                        <div className="font-weight-bold" style={{ color: 'cornflowerblue', fontSize: '0.8rem' }}>
                            To stake in this pool you should be an Investor with minimum of {minI} BUSD as Active invested amount or a Manager with minimum of {minAUM} BUSD as AUM on Investin.
                        </div>
                    </Col>
                </Row>
            }
        </Container>
    )
}

export default StakeCard
