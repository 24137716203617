
import { ReactComponent as CakeIcon } from '../assets/img/pancakeswap-cake.svg';
// import { ReactComponent as SushiIcon } from '../assets/img/sushiswap-sushi.svg';
// import { ReactComponent as BnbIcon } from '../assets/img/bnb.svg';

import React from 'react';
import strategyConfiguration from './strategyConfiguration.json';

import PanCakeSwapMasterChef from '../contracts/PanCakeSwapMasterChef.json';
import WaultSwapMasterChef from '../contracts/WaultSwapMasterChef.json';
import BeltMasterChef from '../contracts/BeltMasterChef.json';

// import MerlinStrategyDashboard from '../contracts/MerlinStrategyDashboard.json';
import LPTokenAbi from '../contracts/LPTokenAbi.json';

import beltLPTokenAbi from '../contracts/beltLPTokenAbi.json';
import TetherTokenA from '../contracts/TetherToken.json';
import FundDefiAbi from '../contracts/FundDefi.json';
import InvestinStrategyAlpacaAbi from '../contracts/InvestinStrategyAlpaca.json';

import BigNumber from 'bignumber.js';
import FundAbi from '../contracts/FundDefi.json';
import { sendTrxNotify } from '../utils/notify';
import { roundDownTo4Decimals } from '../utils/helperFunctions';
import { MaxUint256 } from '@ethersproject/constants';
import { ethers } from 'ethers';
import { ethersProvider } from './web3/getProvider';

import InvestinStakingAbi from '../contracts/InvestinStaking.json';
import { API_URL, FUND_FACTORY_DEFI_ADDR } from './constants';

import {
  setGlobalPrices
} from '../redux/actions';

// let prices={};

export const fetchPrices = async (dispatch) =>{
  // console.log("calling fetch prices----------------------");
  let pricesoftokens ={};
  // strategyConfiguration.filter((i) => i.type === "LP")
  //  for (const x of strategyConfiguration) {
  //      prices[`${x?.token0CoingeckoId}`] = 0;
  //    prices[`${x?.token1CoingeckoId}`] = 0;
  //    prices[`${x?.rewardtokenCoingeckoId}`] = 0;
  //  }
  strategyConfiguration.forEach(x => {
     pricesoftokens[`${x?.token0CoingeckoId}`] = 0;
     pricesoftokens[`${x?.token1CoingeckoId}`] = 0;
     pricesoftokens[`${x?.rewardtokenCoingeckoId}`] = 0;
  });
  //  strategyConfiguration.map((x)=>{
  //    prices[`${x.token0CoingeckoId}`] = 0;
  //    prices[`${x.token1CoingeckoId}`] = 0;
  //    prices[`${x.rewardtokenCoingeckoId}`] = 0;
  // })
  // console.log("prices:",prices);
  //fetch prices using coin gecko 

  let tokens = Object.keys(pricesoftokens);
  // console.log("tokens:",tokens);


     const fetchPrices = (await CoinGeckoClient.simple.price({ ids: [...tokens], vs_currencies: "usd" })).data
            // console.log('fetchPrices:', fetchPrices);
            // prices =fetchPrices ;

            // const token0Price = fetchPrices[token0CoingeckoId]['usd'];
            // const token1Price = fetchPrices[token1CoingeckoId]['usd'];
            // const rewardTokenPrice = fetchPrices[rewardtokenCoingeckoId]['usd'];
      // fetchPrices.forEach((i)=>{
      //   prices[i]
      // })
      try {
         const {data} = await (await fetch(
              `${API_URL}/alpaca`
            )).json();
            // console.log(` api Alpaca  response : `, data);
            fetchPrices['ibBNB'] = data.filter((i)=> i.symbol==='ibBNB')[0];
            fetchPrices['ibUSDT'] = data.filter((i)=> i.symbol==='ibUSDT')[0];
            fetchPrices['ibBUSD'] = data.filter((i)=> i.symbol==='ibBUSD')[0];

      } catch (error) {
        console.error("error:",error)
      }

  
   dispatch(setGlobalPrices(fetchPrices));

}


export const getSTIcon = (name) => {
    
     switch (name) {
         case "WBNBxBUSD":
         return <div style={{ textAlign: 'left', paddingLeft: 0, paddingRight: 0 }}>               
                {/* <CakeIcon className="strategy-logo logo-top-right" /> */}
                {/* <BnbIcon className="strategy-logo" /> */}
                <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
           <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo" />
                {/* <BnbIcon className="strategy-logo" /> */}
           
             </div>;
      case "BTCBxBUSD":
       return <div>
                 {/* <CakeIcon className="strategy-logo logo-top-right" />
                  <BnbIcon className="strategy-logo" /> */}
                 <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/7598/small/wrapped_bitcoin_wbtc.png" alt="" className="strategy-logo" />
         </div>;
       
       case "USDCxBUSD":
       return <div>
                <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png" alt="" className="strategy-logo" />
         </div>;
       
       case "USDTxBUSD":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/325/small/Tether-logo.png" alt="" className="strategy-logo" />
             </div>;
       
       case "TUSDxBUSD":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/3449/small/tusd.png" alt="" className="strategy-logo" />
             </div>;  
       
       case "USTxBUSD":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/12681/small/UST.png"  alt="" className="strategy-logo" />
             </div>;  
       
        case "DAIxBUSD":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/9956/small/dai-multi-collateral-mcd.png"  alt="" className="strategy-logo" />
             </div>;  
       
       
        case "CAKExBUSD":
         return <div>
                 <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                 <CakeIcon className="strategy-logo" />
             </div>;  
       
         case "CAKExBNB":
         return <div>
                <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo logo-top-right" />           
                 <CakeIcon className="strategy-logo" />
             </div>;  
       
        case "WEXxBNB":
         return <div>
                <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo logo-top-right" />                      
                 <img src="https://assets.coingecko.com/coins/images/14882/small/200x200_logo_in_circle_wswap.png" alt="" className="strategy-logo" />
             </div>;  
       
        case "WEXxUSDT":
         return <div>
                 <img src="https://assets.coingecko.com/coins/images/325/small/Tether-logo.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/14882/small/200x200_logo_in_circle_wswap.png" alt="" className="strategy-logo" />
             </div>;  
       
        case "IVNxBNB":
         return <div>
                <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo logo-top-right" />                      
                 <img src="https://assets.coingecko.com/coins/images/15588/small/ivn_logo.png" alt="" className="strategy-logo" />
             </div>;  
       
        case "USDCxBNB":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo logo-top-right" />
                 <img src="https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png" alt="" className="strategy-logo" />
         </div>;
       
        case "WEX":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/14882/small/200x200_logo_in_circle_wswap.png" alt="" className="strategy-logo" />
         </div>;
       
       case "beltBNB":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg" alt="" className="strategy-logo" />
         </div>;
       
       case "beltBUSD":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg" alt="" className="strategy-logo" />
         </div>;
       
        case "beltUSDT":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/325/small/Tether-logo.png" alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg" alt="" className="strategy-logo" />
         </div>;
       
        case "beltUSDC":
       return <div>
                  <img src="https://assets.coingecko.com/coins/images/6319/small/USD_Coin_icon.png" alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg" alt="" className="strategy-logo" />
             </div>;

       case "ibBUSD-ALPACA":
       return <div>
                  <img  src="https://assets.coingecko.com/coins/images/14165/small/Logo200.png"  alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png" alt="" className="strategy-logo" />
             </div>; 

        case "ibBNB-ALPACA":
       return <div>
                  <img  src="https://assets.coingecko.com/coins/images/14165/small/Logo200.png"  alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/12591/small/binance-coin-logo.png" alt="" className="strategy-logo" />
             </div>;      

        case "ibUSDT-ALPACA":
       return <div>
                  <img  src="https://assets.coingecko.com/coins/images/14165/small/Logo200.png"  alt="" className="strategy-logo logo-top-right" />
                  <img src="https://assets.coingecko.com/coins/images/325/small/Tether-logo.png" alt="" className="strategy-logo" />
             </div>;  

      default:
             return <></>
    }
}

export const getProtocolIcon = (name) => {
    
     switch (name) {
         case "PancakeSwap":
             return <div>
                 <CakeIcon className="protocol-logo" />
             </div>;
       
      case "WaultSwap":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/14882/small/200x200_logo_in_circle_wswap.png" alt="" className="protocol-logo" />
             </div>;
       
       case "Belt":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/14319/small/belt_logo.jpg" alt="" className="protocol-logo" />         
             </div>;

       case "Alpaca":
       return <div>
                 <img src="https://assets.coingecko.com/coins/images/14165/small/Logo200.png" alt="" className="protocol-logo" />         
             </div>;

      default:
         return <div>
            <img src="https://assets.coingecko.com/coins/images/15588/small/ivn_logo.png" alt="" className="protocol-logo" />
           </div>
    }
}
                 // all 4 params in wei in string format          
export const getPoolApy = (stakingTokenPrice, rewardTokenPrice, totalStaked, tokenPerBlock) => {
   
      const BSC_BLOCK_TIME = 3;
      //  const TOKEN_PER_BLOCK = new BigNumber(1 * 10 ** 18)
      //  const CAKE_PER_BLOCK = new BigNumber(3 * 10 ** 18)
      const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365)
     
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR);
  // console.log(`getPoolApy-- totalRewardPriceYear : `, totalRewardPricePerYear.toNumber());
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
  // console.log(`getPoolApy-- totalStakingTokenInPool : `, totalStakingTokenInPool.toNumber());

  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  // console.log('getPoolApy--  apr:', apr.toNumber());

  // (1+daily yield)^365 -1 
  const daily_yield = apr.div(365).div(100).plus(1);
  // console.log(' getPoolApy--  daily_yield.toNumber():', daily_yield.toNumber());

  let apy = daily_yield.pow(365).minus(1).times(100);
  // console.log('getPoolApy--  apy.toNumber():', apy.toNumber());
   
  return (apy.isNaN() || !apy.isFinite()) ? null : apy.toNumber()
}

export const getPoolApyFromApr = (APRinPercentage) => {
  // input can be stirng or number
  const apr = new BigNumber(APRinPercentage);
   const daily_yield = apr.div(365).div(100).plus(1);
    let apy = daily_yield.pow(365).minus(1).times(100);
    // console.log("getAPY : ",APRinPercentage, apy.toNumber())
    return (apy.isNaN() || !apy.isFinite()) ? null : apy.toNumber()
}

const CoinGecko = require('coingecko-api');
const CoinGeckoClient = new CoinGecko();

export const calculateAPY = async ( strategyInstance , strategyName, protocol , rewardPoolAddress, lpTokenAddress , pid,  functionName , token0CoingeckoId , token1CoingeckoId , rewardtokenCoingeckoId ,prices ) => {
      // ( strategyInstance , rewardPoolAddress , pid , [token0CoingeckoId, token1CoingeckoId, rewardtokenCoingeckoId] )

      if(protocol ==='Alpaca'){
        // fetch from API
        return 50;

        // minAmount check during ENTER
        // noOfIntersetBearingTOken(AmountOutMin)===  AmountENtered/baseTokenPerShare 
      }


      let DynamicAbi;
        if (protocol === "PancakeSwap") {
          DynamicAbi = PanCakeSwapMasterChef;
        }
        else if (protocol === "WaultSwap") {
          DynamicAbi = WaultSwapMasterChef;
        }
        else if (protocol === "Belt") {
          // DynamicAbi = BeltMasterChef;
          return '10%'; //already given in config file
        } 
        else {
          // further   
          console.error("error incoreect protocol")
          DynamicAbi = WaultSwapMasterChef;
        }
        /// SINCE USING ETHERS.JS it will return values in BigNumber of it own type 
        const rewardPoolInstance = new ethers.Contract(rewardPoolAddress, DynamicAbi, ethersProvider);
        // console.log('rewardPoolInstance: ', rewardPoolInstance);
        const tokenPerBlockOverAllPools = await rewardPoolInstance[functionName]();
        // console.log('**tokenPerBlockOverAllPools: ', strategyName, protocol ,tokenPerBlockOverAllPools); // *poolAllocPoint /totalAll
        // const pid = await strategyInstance.pid(); 
        const totalAllocPoint = await rewardPoolInstance.totalAllocPoint(); //72413
        const { allocPoint } = await rewardPoolInstance.poolInfo(pid); //100
        // console.log('***pid, totalAllocPoint, allocPoint:', strategyName, protocol ,pid.toString(), totalAllocPoint.toString(), allocPoint.toString());
        const tokenPerBlock = (((tokenPerBlockOverAllPools).mul(allocPoint)).div(totalAllocPoint)).toString();
        // console.log('strategyName, protocol , tokenPerBlock,functionName:',strategyName, protocol , tokenPerBlock,functionName);
        
  

        // const lpTokenAddress = await strategyInstance.lPToken(); 
        const lpTokenInstance = new ethers.Contract(lpTokenAddress,LPTokenAbi,ethersProvider);
        // no of LP's staked in the rewardPool 
        const totalLpTokenStakedAtPool = (await lpTokenInstance.balanceOf(rewardPoolAddress)).toString();
        // console.log("totalLpTokenStakedAtPool:", totalLpTokenStakedAtPool);

        

      // if (protocol === 'Belt' && strategyName !== "4-belt") {
        if (strategyName === "beltBNB") {
          const lpTokenInstance = new ethers.Contract(lpTokenAddress,beltLPTokenAbi,ethersProvider);
          //call getPricePerFullShare
          // const stakingTokenPriceinWei = (await lpTokenInstance.getPricePerFullShare()).toString();
          // console.log("stakingTokenPriceinWei:", stakingTokenPriceinWei);
          
          try {
            const totalLpTokenStakedAtPool = (await lpTokenInstance.calcPoolValueInToken()).toString(); 
            // const fetchPrices = (await CoinGeckoClient.simple.price({ ids: [token0CoingeckoId,rewardtokenCoingeckoId], vs_currencies: "usd" })).data;
            const token0Price = prices[token0CoingeckoId]['usd']; //fetchPrices[token0CoingeckoId]['usd'];
            const token0PriceinWei = ethers.utils.parseEther(`${token0Price}`).toString();
            const rewardTokenPrice = prices[rewardtokenCoingeckoId]['usd']; //fetchPrices[rewardtokenCoingeckoId]['usd'];
            const rewardTokenPriceinWei = ethers.utils.parseEther(`${rewardTokenPrice}`).toString();
            // console.log("rewardTokenPriceinWei:", rewardTokenPriceinWei);
            // console.log("totalLpTokenStakedAtPool:", totalLpTokenStakedAtPool);
            // console.log("tokenPerBlock:", tokenPerBlock);
          
            let APY =  getPoolApy(token0PriceinWei, rewardTokenPriceinWei, totalLpTokenStakedAtPool, tokenPerBlock); // all 4 params in wei in string format
            return APY * 4;
          } catch (error) {
            console.log(" beltBNB APY coingecko error:", error);
            return '';
          }
          
        }

  
        if (strategyName === "WEX") {
          try {
            // const fetchPrices = (await CoinGeckoClient.simple.price({ ids: ["waultswap"], vs_currencies: "usd" })).data;
            const wexPriceinWei = prices["waultswap"]['usd']; //fetchPrices["waultswap"]['usd'];
            return  getPoolApy(wexPriceinWei, wexPriceinWei, totalLpTokenStakedAtPool, tokenPerBlock); // all 4 params in wei in string format
          } catch (error) {
            console.error("WEX APY coingecko error:", error);
            return '';
          }
        }


        // let token0 = await lpTokenInstance.methods.token0().call();
        // let token1 = await lpTokenInstance.methods.token1().call();
        let { _reserve0, _reserve1 } = await lpTokenInstance.getReserves();
        const totalSupply = await lpTokenInstance.totalSupply();
        // console.log(`totalSupply : `, totalSupply)

        try {
          //  const fetchPrices = (await CoinGeckoClient.simple.price({ ids: [token0CoingeckoId,token1CoingeckoId,rewardtokenCoingeckoId], vs_currencies: "usd" })).data
            // console.log('fetchPrices:', fetchPrices);
            const token0Price =  prices[token0CoingeckoId]['usd']; // fetchPrices[token0CoingeckoId]['usd'];
            const token1Price = prices[token1CoingeckoId]['usd']; //fetchPrices[token1CoingeckoId]['usd'];
            const rewardTokenPrice = prices[rewardtokenCoingeckoId]['usd']; //fetchPrices[rewardtokenCoingeckoId]['usd'];
            const rewardTokenPriceinWei =  ethers.utils.parseEther(`${rewardTokenPrice}`).toString();

            const pricesProduct = ethers.utils.parseEther(`${token0Price}`).toString() * ethers.utils.parseEther(`${token1Price}`).toString();
            // console.log("pricesProduct : ", pricesProduct);
            const stakingTokenPriceinWei = ( 2 * (Math.sqrt(_reserve0 * _reserve1 * pricesProduct)) / totalSupply ).toString();
            // console.log("stakingTokenPriceinWei : ", stakingTokenPriceinWei);
          //  console.log("getPoolApy(stakingTokenPriceinWei, rewardTokenPriceinWei, totalLpTokenStakedAtPool, tokenPerBlock) strategyName : ",strategyName, stakingTokenPriceinWei, rewardTokenPriceinWei, totalLpTokenStakedAtPool, tokenPerBlock);
            const APY = getPoolApy(stakingTokenPriceinWei, rewardTokenPriceinWei, totalLpTokenStakedAtPool, tokenPerBlock); // all 4 params in wei in string format
            return APY;
        } catch (error) {
          console.error("APY cpingecko fetching error:", strategyName , error);
          return '';
        }
       
}
  
export const getStrategiesWithBalances = async (fundAddress) => {
   
  try {
    const fundInstance = new ethers.Contract(fundAddress, FundAbi, ethersProvider);
      let stWhileList = await fundInstance.getStrategyList();

      stWhileList  = strategyConfiguration.filter((i)=> stWhileList.includes(i.strategyAddress))
      
        let list = [];

      for (const strategy of stWhileList) {
            // ( async () => {
              const strategyInstance = new ethers.Contract(strategy.strategyAddress, InvestinStakingAbi, ethersProvider);
              let balance = await strategyInstance.balanceOf(fundAddress);
              balance = parseFloat(ethers.utils.formatEther(balance, 'ether'));
              if (balance) {
                let x = {  tokenAddress: strategy.strategyAddress, symbol: `ST-${strategy.name}`, price:0 ,balance: 0 };
                x.balance = balance;
                // console.error("balance of :", strategy.name, balance);
                let price = await strategyInstance.getStrategyTokenPrice(); 
                price = parseFloat(ethers.utils.formatEther(price, 'ether'));
                if (price) {
                  x.price = price;
                  x.balance = balance * price;
                  list.push(x);
                }
              }
            // })()  //donot use async block
      }
        return list;
  } catch (error) {
    console.error("getStrategiesWithBalances error:", error);
    return [];
  }
  


  //  list = strategyConfiguration.map((strategy) => {
      
  //      let x = {  tokenAddress: strategy.strategyAddress, symbol: `ST-${strategy.name}`, price:0 ,balance: 0 };
      
  //     ( async () => {
  //         const strategyInstance = new ethers.Contract(strategy.strategyAddress, InvestinStakingAbi, ethersProvider);
  //         let balance = await strategyInstance.balanceOf(fundAddress);
  //         balance = parseFloat(ethers.utils.formatEther(balance, 'ether'));
  //         x.balance = balance;
  //         if (balance) {
  //           console.error("balance of :", strategy.name, balance);
  //           let price = await strategyInstance.getStrategyTokenPrice(); 
  //           price = parseFloat(ethers.utils.formatEther(price, 'ether'));
  //           x.price = price;
  //           x.balance = balance * price;
  //         }
  //       })()

  //      return x;
  //   });
   
   

    // let addressList = strategyConfiguration.map((st) => st.strategyAddress);
    // let tokensBalances = [];
    // try {
    //   // console.log("balanceChecker ethersProvider :", ethersProvider);
    //   // console.log(object)
    //   tokensBalances = await getTokensBalance(ethersProvider, fundAddress, addressList, { contractAddress: INVESTIN_BALANCE_CHECKER });
    //   // console.log("balanceChecker getTokensBalances :", tokensBalances);
    // } catch (error) { 
    //   console.error("getTokensBalance error:", error);
    // }

    // list = tokensBalances.map((i) => {
    //   if (i) {
    //     // value = 
    //     return {}
    //   }
    // })
}

export const fetchFundData = async ( fundAddress ,fundFactoryAddress ) => {
     if (fundAddress) {
          const fundInstance = new ethers.Contract( fundAddress, FundAbi, ethersProvider);
       let fundDetails = await fundInstance.getFundDetails();
      //  console.log("fund details : ", fundDetails);
       let performance //= (((parseFloat(fundDetails[5]) / 10000) - 1).toFixed(4) * 100).toFixed(2);
        if (fundFactoryAddress === FUND_FACTORY_DEFI_ADDR) {
          performance = ((((parseFloat(fundDetails[5]) / 100000000) - 1).toFixed(4) * 100).toFixed(2));
          console.log("performance DEFI :",performance)
            } else {
              performance = ((((parseFloat(fundDetails[5]) / 10000) - 1).toFixed(4) * 100).toFixed(2));
            }
       const aum = parseFloat(`${ethers.utils.formatEther(fundDetails[4], 'ether')}`).toFixed(4);
       let amountInRouter = await fundInstance.getAmountInRouter();
       amountInRouter = (ethers.utils.formatEther(amountInRouter, 'ether'));
       return {
         fundInstance,
         performance,
         aum,
         amountInRouter
       }
  }
  return {
         fundInstance : {},
         performance : 0,
         aum : 0,
         amountInRouter: 0
       }
}
  
export const handleTransferToVaultHelper = async (web3,fundAddress,address) => {
  try {
       const fundInstance = new web3.eth.Contract(FundDefiAbi, fundAddress);
      
      let trx = fundInstance.methods.transferToVault();
      await sendTrxNotify(
        trx,
        address,
        'Transfered to Vault done sucessfully..'
      );
    } catch (error) {
      console.error('handleTransferToVault: ', error);
    }
};
  
export const amountInRouterHelper = async (fundInstance) => {
   try {
     let amountInRouter = await fundInstance.getAmountInRouter();
      amountInRouter = ethers.utils.formatEther(amountInRouter, 'ether')
      // setAmountInRouter();
      return amountInRouter; 
    } catch (error) {
      console.error('getAmountInRouter error: ', error);
      return 0;
    }
}

export const handleDepositStrategyManager = async ( web3,fundAddress,strategyAddress,address,depositAmt) => {
  try {
       const fundInstance = new web3.eth.Contract(FundDefiAbi, fundAddress);
      
      // let trx = strategyInstance.methods.enter(web3.utils.toWei(`${depositAmt}`) , false); //false=user , 
      let trx = fundInstance.methods.enterStrategy( strategyAddress, ethers.utils.parseEther(`${depositAmt}`).toString()); // true=fund passed internally

      await sendTrxNotify(
        trx,
        address,
        'Invested in strategy sucessfully..'
      );
    } catch (error) {
      console.error('handleDepositStrategyManager: ', error);
    }
}

export const handleDepositStrategyUser = async ( web3,strategyName, strategyAddress,address,depositAmt,prices) => {
  try {
       let strategyInstanceTemp, trx;

        if(strategyName==='ibUSDT-ALPACA' || strategyName==='ibBNB-ALPACA' || strategyName==='ibBUSD-ALPACA' ){
          // get price  and send amountOutMin
          try {
                //  const {data} = await (await fetch(
                //   `${API_URL}/alpaca`
                // )).json();
                // console.log(` from api : response : `, data);
                let baseTokenPerShare;
                if(strategyName=== 'ibUSDT-ALPACA'){
                  //  baseTokenPerShare = (data.filter( (i)=> i.symbol ==='ibUSDT'))[0].baseTokenPerShare;
                  baseTokenPerShare = prices?.ibUSDT?.baseTokenPerShare;
                  console.log("baseTokenPerShare ibUSDT:",baseTokenPerShare);

                }else if(strategyName=== 'ibBNB-ALPACA'){
                  // baseTokenPerShare = (data.filter( (i)=> i.symbol ==='ibBNB'))[0].baseTokenPerShare;
                  baseTokenPerShare = prices?.ibBNB?.baseTokenPerShare;
                  console.log("baseTokenPerShare ibBNB:",baseTokenPerShare);

                } else if(strategyName=== 'ibBUSD-ALPACA'){
                  // baseTokenPerShare = (data.filter( (i)=> i.symbol ==='ibBUSD'))[0].baseTokenPerShare;
                  baseTokenPerShare = prices?.ibBUSD?.baseTokenPerShare;
                  console.log("baseTokenPerShare ibBUSD:",baseTokenPerShare);

                }
                  const amountOutMin = depositAmt*(0.979)/baseTokenPerShare;
                  console.log("amountOutMin:",amountOutMin);
                  
                  strategyInstanceTemp = new web3.eth.Contract(InvestinStrategyAlpacaAbi, strategyAddress);
                  console.log("strategyInstanceTemp:",strategyInstanceTemp);
                  // console.log("STTT:", ethers.utils.parseEther(`${depositAmt}`).toString() ,false , ethers.utils.parseEther(`${amountOutMin}`).toString())
                  trx = strategyInstanceTemp.methods['enter(uint256,bool,uint256)']( ethers.utils.parseEther(`${depositAmt}`).toString() ,false , ethers.utils.parseEther(`${amountOutMin}`).toString() ); 
          } catch (error) {
            console.error("error in api for alpaca:",error);
          }

        } else{
           strategyInstanceTemp = new web3.eth.Contract(InvestinStakingAbi, strategyAddress);
           trx = strategyInstanceTemp.methods.enter( ethers.utils.parseEther(`${depositAmt}`).toString() ,false); 
        }
      
      // let trx = strategyInstance.methods.enter(web3.utils.toWei(`${depositAmt}`) , false); //false=user , 
      // let trx = strategyInstance.enter( ethers.utils.parseEther(`${depositAmt}`).toString() ,false); 

      await sendTrxNotify(
        trx,
        address,
        'Invested in strategy sucessfully..'
      );
    } catch (error) {
      console.error('handleDepositStrategyUser: ', error);
    }
}

export const handleWithdrawStrategyManager = async (web3,fundAddress,strategyAddress,address,withdrawAmt) => {
  try {
       const fundInstance = new web3.eth.Contract(FundDefiAbi, fundAddress);
      
       // let trx = strategyInstance.methods.exit(web3.utils.toWei(`${withdrawAmt}`)); 
      let trx = fundInstance.methods.exitStrategy( strategyAddress, ethers.utils.parseEther(`${withdrawAmt}`).toString()); 

      await sendTrxNotify(
        trx,
        address,
        'Withdraw done sucessfully..'
      );
    } catch (error) {
      console.error('handleWithdraw: ', error);
    }
}

export const handleWithdrawStrategyUser = async (web3,strategyAddress,address,withdrawAmt) => {
  try {
      
    const strategyInstanceTemp = new web3.eth.Contract(InvestinStakingAbi, strategyAddress);
      let trx = strategyInstanceTemp.methods.exit( ethers.utils.parseEther(`${withdrawAmt}`).toString()); 
    
       // let trx = strategyInstance.methods.exit(web3.utils.toWei(`${withdrawAmt}`)); 
      // let trx = strategyInstance.exit( ethers.utils.parseEther(`${withdrawAmt}`).toString()); 

      await sendTrxNotify(
        trx,
        address,
        'Withdraw done sucessfully..'
      );
    } catch (error) {
      console.error('handleWithdraw: ', error);
    }
}

export const handleHarvestManagerOrUser = async (web3, strategyAddress,address) => {
  try {

    const strategyInstanceTemp = new web3.eth.Contract(InvestinStakingAbi, strategyAddress);
    console.error("check handleHarvest strategyInstance,address :", strategyInstanceTemp, address);
    
    let trx = strategyInstanceTemp.methods.reinvest();
    // let trx = strategyInstance.reinvest();
    await sendTrxNotify(
      trx,
      address,
      'Harvest done sucessfully..'
    );
  } catch (error) {
    console.error('handleHarvest: ', error);
  }
}

export const getBaseAndStrategyTokenBalanceManager = async (strategyInstance,baseTokenInstance,fundAddress) => {
   try {
      let balance = await strategyInstance.balanceOf(fundAddress);
      balance = ethers.utils.formatEther(balance, 'ether');
     
       let baseTokenBalance = await baseTokenInstance.balanceOf(fundAddress);
        baseTokenBalance = ethers.utils.formatEther(baseTokenBalance, 'ether')
     return {
       strategyTokenBalance: balance,
       baseTokenBalance : baseTokenBalance
     }
    } catch (error) {
      console.error('getBaseAndStrategyTokenBalanceManager error: ', error);
    }
}

export const getBaseAndStrategyTokenBalanceUser = async (strategyInstance,baseTokenInstance,address) => {
   try {
      let balance = await strategyInstance.balanceOf(address);
      balance = ethers.utils.formatEther(balance, 'ether');
     
     
       let baseTokenBalance = await baseTokenInstance.balanceOf(address);
        baseTokenBalance = ethers.utils.formatEther(baseTokenBalance, 'ether')
     return {
       strategyTokenBalance: balance,
       baseTokenBalance : baseTokenBalance
     }
    } catch (error) {
      console.error('getBaseAndStrategyTokenBalanceManager error: ', error);
    }
}

export const getPendingRewards = async (strategyInstance) => {
  try {
      let pendingRewards = await strategyInstance.viewPendingRewards();
        // console.log(' pendingRewards:', pendingRewards);
        pendingRewards = roundDownTo4Decimals(parseFloat(ethers.utils.formatEther(pendingRewards, 'ether')), 2);
        // setPendingRewards(pendingRewards);
      return pendingRewards;
    } catch (error) {
    console.error('getPendingRewards error: ', error);
    return (0);
    }
}

export const getAllowanceManager = async (fundInstance,strategyAddress) => {
  
   const strategyList = await fundInstance.getStrategyList();
  let allowance = 0;
    for (const st of strategyList) {
      if (st === strategyAddress) {
        allowance = MaxUint256;
        // console.log('st whitelisted :', st);
      }
  }
  return allowance;
}

export const getAllowanceUser = async (baseTokenInstance, strategyAddress,address) => {
  
  let allowance = await baseTokenInstance.allowance(address, strategyAddress);
        allowance = ethers.utils.formatEther(allowance, 'ether');
  
  return allowance;
}

export const handleApproveStrategyUser = async (web3,baseTokenAddress, strategyAddress, address) => {
  
  try {
       const baseTokenInstance = new web3.eth.Contract(TetherTokenA, baseTokenAddress);
        const trx = baseTokenInstance.methods.approve(strategyAddress, MaxUint256);
          //  const trx = baseTokenInstance.approve(strategyAddress, MaxUint256);
          await sendTrxNotify(trx, address, "Strategy Approved sucessfully..");
         
  } catch (error) { console.error('handleApproveStrategyUser:', error); }
  
}

export const handleApproveStrategyManager = async (web3,fundAddress,strategyAddress,address) => {
  
  try {
       const fundInstance = new web3.eth.Contract(FundDefiAbi, fundAddress);
          
        // const trx = baseTokenInstance.methods.approve(strategyAddress, MaxUint256);
           const trx = fundInstance.methods.addStrategies([strategyAddress]);
          await sendTrxNotify(trx, address, "Strategy Approved sucessfully..");
        
          // const allowance = await baseTokenInstance.methods.allowance(address, strategyAddress).call();
          // console.log('allowance:', allowance);
          // setAllowance(allowance);
         
  } catch (error) { console.error('handleApproveStrategyManager:', error); }
  
}

export const handleRemoveStrategyManager = async (web3,fundAddress,strategyAddress,address) => {
  
  try {
       const fundInstance = new web3.eth.Contract(FundDefiAbi, fundAddress);
          
          // const trx = baseTokenInstance.methods.approve(strategyAddress, MaxUint256);
           const trx = fundInstance.methods.deleteStrategies([strategyAddress]);
          await sendTrxNotify(trx, address, "Strategy Remove Approved sucessfully..");
        
          // const allowance = await baseTokenInstance.methods.allowance(address, strategyAddress).call();
          // console.log('allowance:', allowance);
          // setAllowance(allowance);
         
  } catch (error) { console.error('handleRemoveStrategyManager:', error); }
  
}


// export const handleDepositStrategyManagerOrUser = async (web3, fundOrStrategyInstance,strategyAddress,address,depositAmt,isManager=true) => {
//   try {
      
//     let trx;
//     if (isManager) {
//         //fundInstance
//        trx = fundOrStrategyInstance.methods.enterStrategy(strategyAddress, web3.utils.toWei(`${depositAmt}`)); // true=fund passed internally
//       }
//     else {
//       // strategyInstance
//         trx = fundOrStrategyInstance.methods.enter(web3.utils.toWei(`${depositAmt}`) , false); // false=user , 
//       }

//       await sendTrxNotify(
//         trx,
//         address,
//         'Transfered to Vault done sucessfully..'
//       );
//     } catch (error) {
//       console.error('handleDeposit: ', error);
//     }
// }