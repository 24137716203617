import React, {
  useEffect,
  // useState
} from 'react';
import {
  useSelector,
  useDispatch
} from 'react-redux';
import {
  setGlobalAddress,
  // setGlobalChainId,
  setGlobalWeb3,
  setGlobalFundAddress,
  setGlobalFactoryAddress,
  setGlobalUSDTBalance,
  setGlobalApprovedAmt
} from '../redux/actions';
import { ToastContainer } from 'react-toastify';
import { getWeb3 } from '../utils/getWeb3';
import {
  TETHERTOKEN_ADDR,
  CHAIN_ID,
  ROUTER_PANCAKESWAP_ADDR,
  ROUTER_WAULTSWAP_ADDR,
  ROUTER_DEFI_ADDR,
  FUND_FACTORY_PANCAKESWAP_ADDR,
  FUND_FACTORY_WAULTSWAP_ADDR,
  FUND_FACTORY_DEFI_ADDR,
} from '../utils/constants';
import { fetchInvestments } from '../utils/fetchInvestmentsHelper';
import TetherTokenA from '../contracts/TetherToken.json';

import Confetti from 'react-dom-confetti';
import { setGlobalFireConfetti } from '../redux/actions';

import { toast } from 'react-toastify';
// import { Button } from 'reactstrap';
import { fundFactoryAbi } from '../utils/getAbi';
import { getNetworkConfig } from '../utils/networkConfigs';

import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';


const ChangeNetwork = () => {
  return (
    <div>
      Network not supported
      <br />
      <b>Please change the network to ChainId : {CHAIN_ID}</b>
      {/* <Button>change network</Button> */}
    </div>
  )
}

const config = {
  angle: 90,
  spread: "91",
  startVelocity: 40,
  elementCount: 70,
  dragFriction: 0.12,
  duration: "1880",
  stagger: 3,
  width: "10px",
  height: "10px",
  perspective: "500px",
  colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
};


const RootWeb3 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
    
  const fireConfetti = useSelector((state) => state.fireConfettiReducer.fireConfetti);

  useEffect(() => {
    if (fireConfetti) {
      setTimeout(() => dispatch(setGlobalFireConfetti(false)), 1500);
    }
  }, [fireConfetti]) // eslint-disable-line react-hooks/exhaustive-deps

  history.listen((location) => {
    console.error(`You changed the page to: ${window.location.origin} ${location.pathname}`);
    ReactGA.set({ page: window.location.origin });
    ReactGA.pageview(window.location.origin+location.pathname)
  })
    // useEffect(() => {
          // return history.listen((location) => { 
            // console.error(`You changed the page to: ${location.pathname}`);
            // ReactGA.set({ page: location.pathname });
            // ReactGA.pageview(location.pathname)
            // window.location.pathname + window.location.search
            // console.error("window ::", window.location.pathname + window.location.search)
          // }) 
      // },[history]) 
  
  useEffect(() => {
    if (window.ethereum) {
      refetchData();

      window.ethereum.on('accountsChanged', async (accounts) => {
        console.log('accounts new changed', accounts);
        refetchData()
      });

      window.ethereum.on('chainChanged', async (chainId) => {
          //chainId fetched will be in hex hence parseInt
          // called when network changed
        if (parseInt(chainId) !== parseInt(CHAIN_ID)) {
          toast.error(<ChangeNetwork />, {
            position: "top-right",
            autoClose: false,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });

          window.ethereum.request({
            "id": 1,
            "jsonrpc": "2.0",
            "method": "wallet_addEthereumChain",
            "params": [getNetworkConfig()]
          })
        } else {
          refetchData();
        }

      });
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  const refetchData = async () => {
    try {
      const web3 = await getWeb3()
      dispatch(setGlobalWeb3(web3));

      const accounts = await web3.eth.getAccounts();
      dispatch(setGlobalAddress(accounts[0]));

      const chainId = await web3.eth.getChainId();
      // dispatch(setGlobalChainId(chainId));

      //  console.log("first time chainId", chainId, typeof (chainId)); // number

      if (chainId !== parseInt(CHAIN_ID)) {
        toast.error(<ChangeNetwork />, {
          position: "top-right",
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        window.ethereum.request({
          "id": 1,
          "jsonrpc": "2.0",
          "method": "wallet_addEthereumChain",
          "params": [getNetworkConfig()]
        })
        return;
      }

      await reduxUpdatesAfterWalletConnect(web3,accounts);

      await fetchInvestments(web3, dispatch);
    } catch (error) {
      console.error(error)
    }
  }

  const reduxUpdatesAfterWalletConnect = async (web3,accounts) => {
     
      const tetherTokenInstance = new web3.eth.Contract(TetherTokenA,TETHERTOKEN_ADDR);
      let balanceUSDT = await tetherTokenInstance.methods.balanceOf(accounts[0]).call();
      balanceUSDT = parseFloat(web3.utils.fromWei(balanceUSDT, "ether"))  //.toFixed(4);
      //truncate to 4 decimal without rounding
      balanceUSDT = (Math.trunc(balanceUSDT * 10000) / 10000).toFixed(4);
      dispatch(setGlobalUSDTBalance(balanceUSDT));

    let pancakeSwapAllowance = await tetherTokenInstance.methods.allowance(accounts[0], ROUTER_PANCAKESWAP_ADDR).call();
     pancakeSwapAllowance = parseFloat(web3.utils.fromWei(pancakeSwapAllowance, "ether"));
    

      let waultSwapAllowance = await tetherTokenInstance.methods.allowance(accounts[0], ROUTER_WAULTSWAP_ADDR).call();
      waultSwapAllowance = parseFloat(web3.utils.fromWei(waultSwapAllowance, "ether"));

      let StrategyFundAllowance = await tetherTokenInstance.methods.allowance(accounts[0], ROUTER_DEFI_ADDR).call();
      StrategyFundAllowance = parseFloat(web3.utils.fromWei(StrategyFundAllowance, "ether"));

      dispatch(setGlobalApprovedAmt(
        {
          [ROUTER_PANCAKESWAP_ADDR]: pancakeSwapAllowance,
          [ROUTER_WAULTSWAP_ADDR]: waultSwapAllowance,
          [ROUTER_DEFI_ADDR] : StrategyFundAllowance,
        }
      ));

      const pancakeFundFactoryInstance = new web3.eth.Contract(
        fundFactoryAbi(1),
        FUND_FACTORY_PANCAKESWAP_ADDR
      );
      let pancakeFundAddress = await pancakeFundFactoryInstance.methods.getManagerToFund(accounts[0]).call();
      let pancakeFundStatus = false;
      if (pancakeFundAddress) {
        pancakeFundStatus = await pancakeFundFactoryInstance.methods.getFundMapping(pancakeFundAddress).call();
      }

      const waultFundFactoryInstance = new web3.eth.Contract(fundFactoryAbi(2),FUND_FACTORY_WAULTSWAP_ADDR);
      let waultFundAddress = await waultFundFactoryInstance.methods.getManagerToFund(accounts[0]).call();
      let waultFundStatus = false;
      if (waultFundAddress) {
        waultFundStatus = await waultFundFactoryInstance.methods.getFundMapping(waultFundAddress).call();
      }

      const StrategytFundFactoryInstance = new web3.eth.Contract(fundFactoryAbi(2),FUND_FACTORY_DEFI_ADDR);
       let StrategyFundAddress = await StrategytFundFactoryInstance.methods.getManagerToFund(accounts[0]).call();
      let StrategyFundStatus = false;
      if (StrategyFundAddress) {
        StrategyFundStatus = await StrategytFundFactoryInstance.methods.getFundMapping(StrategyFundAddress).call();
      }
        
      const addressValid = (address) => {
        return address !== '' && address !== '0x0000000000000000000000000000000000000000';
      }

      if (addressValid(waultFundAddress) && waultFundStatus) {
        console.log('**waultswap fund');
        dispatch(setGlobalFundAddress(waultFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_WAULTSWAP_ADDR));
      }
      else if (addressValid(pancakeFundAddress) && pancakeFundStatus) {
        console.log('**pancakeswap fund');
        dispatch(setGlobalFundAddress(pancakeFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_PANCAKESWAP_ADDR));
      }
      else if (addressValid(StrategyFundAddress) && StrategyFundStatus) {
        console.log('**strategy fund');
        dispatch(setGlobalFundAddress(StrategyFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_DEFI_ADDR));
      }
      else {
        console.log('Not a manager any of 3 types of funds');
      }
  }

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
       <div className="Toastify">
        <div className="confetti-container">
          <Confetti className="investin-confetti" active={fireConfetti} config={config} style={{position: "absolute", top: "50%", left: "50%"}} />
        </div>
      </div>
    </>
  );
};

export default RootWeb3;
