import React from 'react'
import { Row, Col } from "reactstrap";
import PercentButton from './PercentButton';
import PropTypes from 'prop-types';

const PercentButtons = (props) => {
    const {
        value,
        callback,
        // trade
    } = props;

    // console.log("value :",value);
    return (
        <Row style={{ marginTop: '8px' }} className="d-flex justify-content-center">
            <Col lg="2" md="3">
                <PercentButton value={value || 0} percentage={25} callback={callback}  trade />
            </Col>
            <Col lg="2" md="3">
                <PercentButton value={value || 0} percentage={50} callback={callback} trade />
            </Col>
            <Col lg="2" md="3">
                <PercentButton value={value || 0} percentage={75} callback={callback} trade />
            </Col>
            <Col lg="2" md="3">
                <PercentButton value={value || 0} percentage={100} callback={callback} trade />
            </Col>
        </Row>
    )
}

export default PercentButtons;

PercentButtons.propTypes = {
    value: PropTypes.any,
    callback: PropTypes.func
}
