import TetherTokenA from '../contracts/TetherToken.json';
import {
  INVESTIN_BALANCE_CHECKER, ROUTER_DEFI_ADDR,
  // ROUTER_PANCAKESWAP_ADDR,
  TETHERTOKEN_ADDR, WBNB_ADDR
} from './constants';
import { routerAbi } from './getAbi';
import fundAbi from '../contracts/Fund.json';
// import RouterWeth from '../contracts/RouterWeth.json';

import zipobject from 'lodash.zipobject'
import COINGECKO_TOKENS from './coingeckoTokens.json';
import { getTokensBalance } from "@mycrypto/eth-scan";
import { ethersProvider } from './web3/getProvider';
import BigNumber from 'bignumber.js';

const CoinGecko = require('coingecko-api');

//2. Initiate the CoinGecko API Client
const CoinGeckoClient = new CoinGecko();

const getTokenSymbolsWithAddresses = async (web3, tokenAddresses) => {
  try {
    let newData = []
    const promises = []
    newData = tokenAddresses.map((t) =>  { return {tokenAddress: t} });
    for (const tokenAddress of tokenAddresses) {
      const tokenInstance = new web3.eth.Contract(
        TetherTokenA,
        `${tokenAddress}`
      );
      promises.push(tokenInstance.methods.symbol().call()); 
    }
    try {
      const results = await Promise.allSettled(promises);
      newData = zipobject(newData.map(d => d.tokenAddress), await Promise.all(results.filter((results) => results.status === "fulfilled").map((r) => r.value)))
      newData = Object.keys(newData).map((n) => { return { tokenAddress: n, symbol: newData[n] } });
     // console.log('getTokenSymbolsWithAddresses : ', newData);
    } catch (error) {
      console.error("getTokenSymbolsWithAddresses error:",error);
    }
    // console.log('getTokenSymbolsWithAddresses : ', newData);

    return newData;

  } catch (error) {
    console.error("getTokenSymbolsWithAddresses error:", error);
    return [];
  }
  
}

export const getRouterTokenWhitelist = async (web3, routerAddress) => {
  const routerInstance = new web3.eth.Contract(routerAbi(1), routerAddress);
  const list = await routerInstance.methods.getTokenWhiteList().call();
  // console.log(`routerTokens : getTokenWhiteList :: `, list)
  return list;
}

export const getFundTokenWhitelist = async (web3, fundAddress,routerAddress) => {
  const contractInstance = new web3.eth.Contract(fundAbi, fundAddress);
  let list = await contractInstance.methods.getTokenList().call();
  if (routerAddress === ROUTER_DEFI_ADDR) {
    list = [ TETHERTOKEN_ADDR.toString() ,WBNB_ADDR.toString(), ...list]
  }
  // console.log("Fund : getFundTokenWhitelist:",list)
  return list;
}

export const getFundUnapproveTokenList = async (web3, fundAddress, routerAddress) => {
  const whitelistTokens = await getRouterTokenWhitelist(web3, routerAddress);
  const approvedTokenList = await getFundTokenWhitelist(web3, fundAddress, routerAddress);// updated for v4
  const FundUnApprovedTokenList = whitelistTokens.filter(function (obj) { return approvedTokenList.indexOf(obj) === -1; });
  // console.log("FundUnApprovedTokenList list :",FundUnApprovedTokenList )
  return FundUnApprovedTokenList;
}

// if usdtEvaluation is false then it will give quantites
// if balancesRequired=false will send only whitelisted tokens from router
const getAssetsWithBalances = async (web3, routerAddress, balancesRequired = true, fundAddress = '0x', usdtEvaluation = false, tokenList = []) => {
  
  let list = [];
  try {
    const routerInstance = new web3.eth.Contract(routerAbi(1), routerAddress);
    list = tokenList.length > 0 ? tokenList : await routerInstance.methods.getTokenWhiteList().call(); //NEW_CHANGE
    // console.log(`getTokenWhiteList list ::::: `, list)
  } catch (error) {
    console.error("getTokenWhiteList error:", error);
  }

  let data = await getTokenSymbolsWithAddresses(web3, list);

  
  try {
      if (!balancesRequired) {
      return data;
      }
      
      // if usdtEvaluation requested get all the prices from coingecko
      if (usdtEvaluation) {
        const symbols = data.map((d) => d.symbol.toLowerCase()); 
        const coingeckoTokens = COINGECKO_TOKENS.filter((token) => symbols.includes(token.symbol)) 
        const coingekoIds = coingeckoTokens.map((t) => t.id);
        const fetchPrices = (await CoinGeckoClient.simple.price({ ids: coingekoIds, vs_currencies: "usd" })).data
        coingeckoTokens.map((c) => c['price'] = fetchPrices[c.id].usd) 
        data.map((d) => d['price'] = coingeckoTokens.find((c) => c.symbol.toLowerCase() === d.symbol.toLowerCase())?.price ?? 0)
      }
  } catch (error) {
    console.error("coingecko getprices error:", error);
  }
  
  let tokensBalances = [];
  try {
    // console.log("balanceChecker ethersProvider :", ethersProvider);
    // console.log(object)
    tokensBalances = await getTokensBalance(ethersProvider, fundAddress, list, { contractAddress: INVESTIN_BALANCE_CHECKER });
    // console.log("balanceChecker getTokensBalances :", tokensBalances);
  } catch (error) { 
    console.error("getTokensBalance error:", error);
  }
  

  try {
    for (const token of data) {
       
      if (balancesRequired) {
        //QUANTITY or BALANCE
        let quantity = 0;
        try {
          quantity = (new BigNumber(tokensBalances[`${token.tokenAddress}`]));
          // console.log("quantity of :",token.tokenAddress,quantity.toNumber())
        } catch (error) {
          console.error("error in getAssetsBalances-balanceOf (incorrect tokenAddr or fundAddr) SO skipping it: ", error);
          continue;
        }
        quantity = web3.utils.fromWei(`${quantity}`, 'ether');
          // console.log("quantity of :",token.tokenAddress,quantity)

        // usdtEvaluation
        let balance = quantity;
        if (usdtEvaluation && token.tokenAddress !== TETHERTOKEN_ADDR) {
          const price = token.price ?? 0
          balance = quantity * parseFloat(price)
        }
        token['balance'] = balance;
      }
    }
  } catch (error) {
    console.error("error in getAssetsBalances : ", error);
  }

  // console.log("getAssetsWithBalances :", data);
  return data;
}

export default getAssetsWithBalances;