import React from 'react';

import {
  Line,
  // Chart
} from 'react-chartjs-2';

export default function CustomLine({ labels, data }) {
  let graphData = {};
  let datasets = [];
  let datasetObj = {
    label: '',
    borderColor: '#4EAAFF',
    fontColor: '#105A9E',
    radius: 0, // radius is 0 for only this dataset
  };

  // const options = {
  //     elements: {
  //         point:{
  //             radius: 0
  //         }
  //     }
  // }

  const updateDatasetObj = { ...datasetObj, data: data };
  datasets.push(updateDatasetObj);
  graphData.labels = labels;
  graphData.datasets = datasets;

  const options = {
    responsive: true,
    elements: {
      point: {
        radius: 0,
      },
    },
    legend: {
      display: false,
    },
    scales: {
      xAxes: [{ display: false }],
      yAxes: [{ display: false }],
    },
  };

  return (
    <div className="App">
      <Line options={options} data={graphData} />
    </div>
  );
}

// Chart.defaults.global.legend.display = false;
