const SET_ADDRESS = 'SET_ADDRESS';

const addressReducer = (state = '', action) => {
  const address = action.address;
  switch (action.type) {
    case SET_ADDRESS:
      return {
        address,
      };
    default:
      return state;
  }
};

export default addressReducer;
