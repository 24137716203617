import { setGlobalInvestments,setGlobalUSDTBalance } from '../redux/actions';

import {  API_URL, INVESTIN_MULTI_CALL ,ROUTER_DEFI_ADDR,ROUTER_PANCAKESWAP_ADDR,ROUTER_WAULTSWAP_ADDR,TETHERTOKEN_ADDR } from './constants';


// import { investinMulticall2Abi } from './getAbi';

import Fusion_V3_V4_MulticallAbi from '../contracts/Fusion_V3_V4_Multicall.json';
import TetherTokenA from '../contracts/TetherToken.json';


// import { useSelector } from 'react-redux';

export const getUnusedTokenId = (investments) => {
        //fetch investments and check if any with address != '' && address != '0x0000000000000000000000000000000000000000'
  // const investments = useSelector(
  //   (state) => state.investmentsReducer.investments
  // );
  if (investments && investments.length !== 0) {
    console.log("checking investments with fundAdd == 0x0000000000000000000000000000000000000000 ", investments);
       let UnUsed = investments.filter((i) => (i.fundAddress === '0x0000000000000000000000000000000000000000'));
       console.log("usused list:", UnUsed);
       if (UnUsed[0]) {
         return UnUsed[0].tokenId;
       }
     }
     return 0;
    }

function statusString(amount, startPerformance) {
  if (amount > 0 && startPerformance > 0) {
    return "Active";
  }
  else if (amount > 0 && startPerformance === '0') {
    return "inActive";
  }
  else {
    return "deleted";
  }
}

export const fetchInvestments = async (web3, dispatch,updateBalances=false) => {
  const accounts = await web3.eth.getAccounts();
  const address = accounts[0];
  let investmentStructs = [];

  try{
    const InvestinMulticallInstance = new web3.eth.Contract(Fusion_V3_V4_MulticallAbi, INVESTIN_MULTI_CALL);
    // console.log("InvestinMulticallInstance :", InvestinMulticallInstance);
    
  const investments_multi = await InvestinMulticallInstance.methods.getInvestmentsByInvestorAddress(address).call();
    console.log("investments_multicall  getInvestmentsByInvestorAddress:", investments_multi);
  for (let i of investments_multi) {
    let { tokenId, fundAddress, routerAddress, baseToken, investedAmount:amount , startPerformance, currentPerformance, currentReturns } = i;
    // if (fundAddress=='' || fundAddress == "0x0000000000000000000000000000000000000000")
    // {
    //   continue;
    // }
    if (routerAddress !== "0x0000000000000000000000000000000000000000" &&  (routerAddress !== ROUTER_DEFI_ADDR) && (routerAddress !== ROUTER_PANCAKESWAP_ADDR) && (routerAddress !== ROUTER_WAULTSWAP_ADDR) )
    {
      console.error("skipping some old investments in old testing router: tokenId: ", routerAddress, tokenId);
      continue;
    }
    amount = parseFloat(`${web3.utils.fromWei(amount, 'ether')}`).toFixed(2);
    let fundName = `${fundAddress.substring(0, 3)}...${fundAddress.substr(fundAddress.length - 5)}`;
    try {
        const { fund: apiFund } = await (await fetch(
          `${API_URL}/fund/${fundAddress}/decentralised`
        )).json();
        // console.log(`fund deatils from api : response : `, fund)
        console.log("apiFund ::: ", apiFund);
       
        if (apiFund) {
          fundName = apiFund.name; 
          // setFundAbout(apiFund.about)
        }
      } catch (error) {
        console.error("error fetching fundName : ", error)
      }
    // if (routerAddress == ROUTER_DEFI_ADDR) {
    //   console.log("TESTING check for performance")
    //   currentPerformance = (((parseFloat(currentPerformance) / 100000000) - 1).toFixed(4) * 100);
    // } else {
    //   currentPerformance = (((parseFloat(currentPerformance) / 10000) - 1).toFixed(4) * 100);
    // }
    // currentPerformance not used in investments page to show currently
    currentReturns = parseFloat(`${web3.utils.fromWei(currentReturns, 'ether')}`).toFixed(2);
    let status = statusString(amount, startPerformance);
    investmentStructs.push( { tokenId, fundAddress, routerAddress, fundName, baseToken, amount, startPerformance, currentReturns,currentPerformance ,status })
  }

    dispatch(setGlobalInvestments(investmentStructs));

    if (updateBalances) {
       const tetherTokenInstance = new web3.eth.Contract(
        TetherTokenA,
        TETHERTOKEN_ADDR
      );
       let balanceUSDT = await tetherTokenInstance.methods.balanceOf(address).call();
      // setBalanceUSDT(balanceUSDT);
      dispatch(setGlobalUSDTBalance(parseFloat(web3.utils.fromWei(balanceUSDT, "ether")).toFixed(4)));
    }

    // console.log("fetchInvestmentsHelper Multicall investmentStructs:",investmentStructs)
    
  } catch (error) {
    console.error('fetchInvestmentsHelper error: ', error);
  }

};
