import React, { useState } from 'react';
import { Row, Col, Button } from 'reactstrap';
import Accordion from '../components/Accordion';

export const Faq = () => {
  const [investorFaqs, setInvestorFaqs] = useState(true);

  const handleFaqSwitch = (userType) => {
    if (userType === 'investor') {
      setInvestorFaqs(true);
    } else {
      setInvestorFaqs(false);
    }
  };

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <h2 style={{ textAlign: 'center' }}>Frequently Asked Questions</h2>
        </Col>
        <Col md="12" className="d-flex justify-content-center">
          <Button onClick={() => handleFaqSwitch('investor')}>Investors</Button>
          <Button onClick={() => handleFaqSwitch('manager')}>Managers</Button>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg="10">
          {investorFaqs && (
            <>
              <Accordion
                name=" Who am I sending tokens to?"
                about=" The tokens are sent to a contract which stores the assets for the sender in a non-custodial way which means the sender can withdraw the assets any time as he/she wills. There is no function in the contract that will allow ownership of those assets to any other addresses except for the sender’s address in any condition. These assets held by the contract are then used by fund manager/ trader to buy/sell various assets and they won’t be able to withdraw those assets for themselves as well."
              />
              <Accordion
                name=" Are there any fees on investments?"
                about=" Yes, there is a fee for managing assets which will be set by relevant fund manager/trader on the creation of the contract, you will be able to find the fee on the details page of each Fund. On withdrawal, if the manager meets expected returns the performance fee will be accrued on the gains. In the case the manager doesn’t make expected profits and the investor happens to withdraw their assets there will be no performance fee deducted on the gains made."
              />
              <Accordion
                name=" Who trades using my assets?"
                about=" The deployer of the fund contract will be able to trade with funds sent to that contract. The contract will hold the assets for the manager to trade with it. In no condition, the assets will be able to withdraw by anyone except the sender of the assets at any time."
              />
              <Accordion
                name=" What is the token I received in my wallet?"
                about=" On depositing assets to any of the deployed Funds you will receive an erc 721 token named IVNy which is a non-fungible token that is used to identify your ownership of the amount sent to Fund contract. This token tracks your investment term and the amount of profits/losses made."
              />
              <Accordion
                name=" Can anyone access my funds?"
                about=" The contract has no function that will allow access to funds except the sender of those assets and the contract only allows the deployer of the contract to trade using those assets, Investin in no condition would be able to control functions of the Fund contract except to close down the particular fund in a condition that the trader/manager has been inactive or not performing well in markets."
              />
              <Accordion
                name=" What is the network fee?"
                about=" We have worked tirelessly to make the investment process to be very safe in the most cost-effective way possible but being on blockchain and to take advantage of security and anonymity there is some fee that is to be paid to the miner to support the network. Hence you will be required to pay network fee to deposit assets to the contract and to end investment."
              />
              <Accordion
                name=" What are strategies?"
                about=" One click auto-compounding farms used by investin funds, these strategies can be used by anyone to provide liquidity and farm reward tokens. The auto compounding is done by investin controller address at a fixed interval. For more info checkout the docs"
              />
              <Accordion
                name=" How can I use the token I received?"
                about=" The IVNy token sent to your address acts as a confirmation receipt of your investment to a particular Fund and cannot be used in any other place except to end your investment term with Fund contract."
              />
              <Accordion
                name=" What are the Fund addresses used for?"
                about=" The Fund address are addresses of contracts that hold assets for a particular trader/manager to trade using it. You can always query on chain performance and change status of investment by interacting directly with the contract at the fund address."
              />
            </>
          )}
          {!investorFaqs && (
            <>
              <Accordion
                name="How does my fund work?"
                about="The fund consists of a set of decentralized contracts that hold assets of your investors in a non custodial way. The contracts will help you in managing assets and calculate exact performance of the funds using reserver pools of the relevant amm pools."
              />
              <Accordion
                name="How do I make money?"
                about="1. Whenever an investment is deposited in your fund you will be prompted to move the assets to your fund contract (the prompt would be located on manager dashboard in the transferable funds card) on which you will receive the initial management fee instantly in the relevant currency of investment.

                2. Manager will also collect performance fee on reaching a set percentage of minimum return on total AUM of the fund and would be able to collect when the criteria is met i.e investor withdrawing successfully with profits. "
              />
              <Accordion
                name="How is the performance fee collected?"
                about="The performance fee will be unlocked only when the manager meets the criteria of reaching the minimum returns on all the assets under management. The minimum return will be set when starting your fund using the Investin protocol and can range from 10%-40% of the profits made on total AUM."
              />
              <Accordion
                name="Why are only a limited pairs available to trade on?"
                about="An initial whitelist of tokens allowed to trade through the protocol is added in swap contracts in order to protect protocol users interest, tokens can be added/removed on community's approval of projects."
              />
              <Accordion
                name="How do I make profits if the swap fees is deducted from my wallet?"
                about="The swap fee that is deducted on each fund allocation is subsidized by giving manager an initial management fee which is 1% of total capital invested in the relevant fund. We expect the fee collected would be more than enough for manager to handle the relevant expenses."
              />
              <Accordion
                name="Can I close my fund and allow limited investors?"
                about="Yes, by changing the status of fund using the switch available on managers dashboard you can close fund and accept no further investment and work on giving the best returns to existing investors."
              />
              <Accordion
                name="Maximum drawdown limitation"
                about="Investin holds the discretionary power to dissolve any fund if the performance of any fund is very poor and investors are being rugged. This has been included to safeguard investors and promote a healthy community around the protocol."
              />
            </>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default Faq;
