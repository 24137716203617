import { Grid } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Button, Row, Col, Card, CardBody } from 'reactstrap';

function DepositMethod(props) {
  return (
    <div onClick={props.handleClose}>
      <Card className="m-10 p-0">
        <CardBody>
          <Grid container justifyContent="space-evenly">
            <Grid item lg="4">
              <img
                style={{ marginTop: '5px' }}
                src={props.src}
                height="50"
                width="50"
                alt=""
              />
            </Grid>
            <Grid item lg="8">
              <p className="mt-3">{props.title}</p>
            </Grid>
          </Grid>
        </CardBody>
      </Card>
    </div>
  );
}
// eslint-disable-next-line
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return ['Deposit Method', 'Payment Process'];
}

const DepositOptions = (props) => {
  return (
    <div className="content">
      <Grid container justifyContent="center">
        <Grid item>
          <p tag="p">
            Select one of the options below to deposit BUSD to your Investin
            account
          </p>
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: '10px' }} justifyContent="space-between">
        <Grid item sm={6} xs={12}>
          <DepositMethod
            handleClose={() => props.handleClickDepositOption()}
            title="Crypto Exchange"
            src="https://avatars1.githubusercontent.com/u/45370368?s=200&v=4"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DepositMethod
            handleClose={() => props.handleClickDepositOption()}
            title="Ehtereum wallet"
            src="https://avatars3.githubusercontent.com/u/6931243?s=200&v=4"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DepositMethod
            handleClose={() => props.handleClickDepositOption()}
            title="Credit Card"
            src="https://avatars3.githubusercontent.com/u/6931243?s=200&v=4"
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <DepositMethod
            handleClose={() => props.handleClickDepositOption()}
            title="European Bank"
            src="https://avatars3.githubusercontent.com/u/6931243?s=200&v=4"
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default function HorizontalLinearStepper() {
  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const steps = getSteps();

  // const [depositClicked, setDepositClicked] = useState(false);
  // const [qRCodeDialogOpen, setQRCodeDialogOpen] = useState(false)

  // const handleDepositClicked = () => {
  //   setDepositClicked(true);
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleQRCodeDialogOpen = () => {
  //   setDepositClicked(true);
  //   setQRCodeDialogOpen(true)
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  // eslint-disable-next-line
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleClickDepositOption = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };
  // eslint-disable-next-line
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    // setDepositClicked(false);
  };
  // eslint-disable-next-line
  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };
  // eslint-disable-next-line
  const handleReset = () => {
    setActiveStep(0);
  };

  const getStepContent = (step, props) => {
    switch (step) {
      case 0:
        return (
          <DepositOptions handleClickDepositOption={handleClickDepositOption} />
        );
      case 1:
        return (
          <div className="content">
            {' '}
            <p>What is an ad group anyways?</p>{' '}
          </div>
        );
      case 2:
        return (
          <div className="content">
            {' '}
            <p> This is the bit I really care about! </p>{' '}
          </div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div style={{ marginTop: '-20px' }}>
      <Stepper
        styles={{ text: { color: 'white' } }}
        activeStep={activeStep}
        style={{ backgroundColor: 'transparent' }}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};
          if (isStepOptional(index)) {
            labelProps.optional = <p></p>;
          }
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step key={label}>
              <StepLabel alternativeLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
      {getStepContent(activeStep, handleClickDepositOption)}
      {/* {!depositClicked && (
        <React.Fragment>
          <Grid container justifyContent="center">
            <Grid item>
              <Typography variant="h7" style={{ color: "black" }}>
                Select one of the options below to deposit USDT to your Investin
                account
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: "10px" }} justifyContent="space-between">
            <Grid item sm={6} xs={12}>
              <DepositMethod
                handleClose={handleDepositClicked}
                title="Moonpay"
                src="https://avatars1.githubusercontent.com/u/45370368?s=200&v=4"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DepositMethod
                handleClose={handleDepositClicked}
                title="Wyre"
                src="https://avatars3.githubusercontent.com/u/6931243?s=200&v=4"
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <DepositMethod
                handleClose={handleDepositClicked}
                title="QR Code"
                src="https://avatars3.githubusercontent.com/u/6931243?s=200&v=4"
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )} */}
      {/* {depositClicked && 
      
      } */}
      <Row className="d-flex justify-content-center">
        <Col className="d-flex justify-content-center">
          <Button color="link">Skip</Button>
        </Col>
      </Row>
    </div>
  );
}
