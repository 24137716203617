import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';


import {
    Button, Modal, ModalHeader, ModalBody, ModalFooter,
    // Form, CustomInput, FormGroup,
    Label,
    // Input,
    Spinner
} from 'reactstrap';
import PropTypes from 'prop-types';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Link } from 'react-router-dom';
import { setGlobalApprovedAmt ,setGlobalUSDTBalance} from '../redux/actions';
import { MaxUint256 } from '@ethersproject/constants'
import Checkbox from '@material-ui/core/Checkbox';

import {
    // InputGroup,
    InputGroupText, InputGroupAddon
} from 'reactstrap';

import { sendTrxNotify, sendAmountAndTrxNotify } from '../utils/notify';
// import TextField from '@material-ui/core/TextField';

import { FUND_FACTORY_DEFI_ADDR, PANCAKESWAP_V2_ROUTER_ADDR, TETHERTOKEN_ADDR, WBNB_ADDR } from '../utils/constants';
import TetherTokenAbi from '../contracts/TetherToken.json';
import FundAbi from '../contracts/Fund.json';
import FundDefiAbi from '../contracts/FundDefi.json';
// import RouterDefi from '../contracts/RouterDefi.json';
import ExchangeRouter from '../contracts/ExchangeRouter.json';

// import CustomButton from './button';
import { fetchInvestments, getUnusedTokenId } from '../utils/fetchInvestmentsHelper';
// import { WBNB } from 'utils/dexData/fetchTrade';
// import { xena } from 'ccxt';

// import { setGlobalFireConfetti } from '../redux/actions';

export const InvestinDepositModal = ({ web3, fund, minAmount, address, disabled, buttonStyle = '',afterInvestCallback, ...props }) => {

    const [modal, setModal] = useState(false);

    const [selectedBNB, setSelectedBNB] = useState(false);
    // const [selectedUSDT, setSelectedUSDT] = useState(true);

    const dispatch = useDispatch();
    const balanceUSDT = useSelector((state) => state.usdtBalanceReducer.balance);
    const investments = useSelector(
        (state) => state.investmentsReducer.investments
    );
    const [balanceBNB, setBalanceBNB] = useState(0)
    const [valueBNB, setValueBNB] = useState(0);
    const [valueBUSD, setValueBUSD] = useState(0);

    const [minAmountBNB, setMinAmountBNB] = useState(0)

    const [err, setErr] = useState('');
    const [allowanceRequired, setAllowanceRequired] = useState(false);
    const [loading, setLoading] = useState(false);
    const allowance = useSelector(
        (state) => state.approvedAmtReducer.allowance
    );
    const [errorTC, setErrorTC] = useState("");
    const [termsAndConditions, setTermsAndConditions] = useState(false);
    const [fundInstance, setFundInstance] = useState({})

    const tetherTokenInstance = new web3.eth.Contract(
        TetherTokenAbi,
        TETHERTOKEN_ADDR
    );

    useEffect(() => {

        if (fund) {
            let fundInstanceTemp;
            if (fund.fundFactory === FUND_FACTORY_DEFI_ADDR) {
                fundInstanceTemp = new web3.eth.Contract(FundDefiAbi, fund.address);
                 (async () => {
                    try {
                        let walletBalanceE = await web3.eth.getBalance(address);
                        walletBalanceE = web3.utils.fromWei(walletBalanceE, 'ether');
                        setBalanceBNB(parseFloat(walletBalanceE).toFixed(4));

                        const routerInstance = new web3.eth.Contract(ExchangeRouter, PANCAKESWAP_V2_ROUTER_ADDR);
                        let x = web3.utils.toWei(minAmount.toString());
                        // console.error("x:", x);
                        let a = (await routerInstance.methods.getAmountsOut(x, [TETHERTOKEN_ADDR, WBNB_ADDR]).call())[1]
                        // console.error("a:",a);
                        const BNBMinAmt = (web3.utils.fromWei(a, 'ether')).toString();
                        // console.error("BNBMinAmt:", BNBMinAmt);
                        setMinAmountBNB(parseFloat(BNBMinAmt).toFixed(5)+'9');
                    } catch (error) {
                    }
                })()
            } else {
                fundInstanceTemp = new web3.eth.Contract(FundAbi, fund.address);
            }
            setFundInstance(fundInstanceTemp);
        }

        if (fund && allowance) {
            if (allowance[fund.routerAddress] < minAmount) {
                setAllowanceRequired(true)
            }
        }
       

    }, [web3, allowance, fund, address]); // eslint-disable-line react-hooks/exhaustive-deps

    const toggle = () => setModal(!modal);


    const handleClickOpen = () => {
        getAllowanceUpdateReduxStore(); // NOTE: had to force it to check allow because even after updating store it does not rerender 
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleMakeInvestment = async (amount, isBNB = false) => {
        try {
            setLoading(true)
            console.log('--setInvestor called----');
            console.log('handleMakeInvestment-- fund.fundFactory :', fund.fundFactory);
            let investment;
            if (fund.fundFactory === FUND_FACTORY_DEFI_ADDR) {
                console.log("DEFI FUND non-bnb invest");
                const unUsedTokenId = getUnusedTokenId(investments);
                console.error("unUsedTokenId:", unUsedTokenId);
                if (isBNB) {
                    console.error("isBNB web3.utils.toWei(amount), unUsedTokenId, true:", web3.utils.toWei(amount.toString()));
                    investment = fundInstance.methods.makeInvestment(web3.utils.toWei(amount.toString()), unUsedTokenId, true);
                    await sendAmountAndTrxNotify(investment, address, "Investement done sucessfully..", web3.utils.toWei(amount.toString()));
                } else {
                    console.error("isBNBFalse web3.utils.toWei(amount), unUsedTokenId, false:", web3.utils.toWei(amount.toString()));
                    investment = fundInstance.methods.makeInvestment(web3.utils.toWei(amount.toString()), unUsedTokenId, false);
                    await sendTrxNotify(investment, address, "Investement done sucessfully..");
                }
            } else {
                console.error("old V3 web3.utils.toWei(amount), unUsedTokenId, false:", web3.utils.toWei(amount));

                investment = fundInstance.methods.makeInvestment(web3.utils.toWei(amount));
                await sendTrxNotify(investment, address, "Investement done sucessfully..");

            }
            console.log('--setInvestor Done----');
            setLoading(false);
            // dispatch(setGlobalFireConfetti(true));
            await afterInvestCallback();
            fetchInvestments(web3, dispatch, true);

            //update balances
                let balanceUSDT = await tetherTokenInstance.methods.balanceOf(address).call();
                balanceUSDT = parseFloat(web3.utils.fromWei(balanceUSDT, "ether"));  //.toFixed(4);
                balanceUSDT = (Math.trunc(balanceUSDT * 10000) / 10000).toFixed(4);
                dispatch(setGlobalUSDTBalance(balanceUSDT));

        } catch (error) {
            console.error('handleMakeInvestment setInvestor error: ', error);
        } finally {
            setLoading(false)
        }

    };

    const handleApprove = async () => {
        if (!termsAndConditions) {
            setErrorTC("You must accept the Terms & Conditions");
            return;
        }
        try {
            setLoading(true);
            console.log(`fund.routerAddress :::: `, fund.routerAddress)
            const approve = tetherTokenInstance.methods.approve(fund.routerAddress, MaxUint256);
            await sendTrxNotify(approve, address, "Approved sucessfully..");
            getAllowanceUpdateReduxStore();
            setLoading(false);
        } catch (error) { console.error(error) } finally {
            setLoading(false);
        }
    }

    const getAllowanceUpdateReduxStore = async () => {
        console.log("getAllowanceUpdateReduxStore");
        console.log(`fund.routerAddress : `, fund.routerAddress)
        let allowance = await tetherTokenInstance.methods
            .allowance(address, fund.routerAddress)
            .call();
         allowance = parseFloat(web3.utils.fromWei(allowance, "ether"));
        if (allowance.toString() < minAmount) {
            setAllowanceRequired(true)
        }
        else {
            setAllowanceRequired(false);
            dispatch(setGlobalApprovedAmt({ [fund.routerAddress]: allowance }));
            console.log("getAllowanceUpdateReduxStore new allowance:", allowance);
        }
    }

    const handleInvest = async () => {
        console.log("handleInvest with selectedBNB,valueBUSD,minAmount,valueBNB,minAmountBNB, termsAndConditions:", selectedBNB, valueBUSD, minAmount, valueBNB, minAmountBNB, termsAndConditions);
        if (!termsAndConditions) {
            setErrorTC("You must accept the Terms & Conditions");
            return;
        }

        if (!selectedBNB && (valueBUSD >= minAmount)) {
            await handleMakeInvestment(valueBUSD, false);
            handleClose();
        } else if (selectedBNB && (valueBNB >= minAmountBNB)) {
            await handleMakeInvestment(valueBNB, true);
            handleClose();
        } else {
            setErr("Amount should be more than or equal to the minimum amount")
        }
    }


    const handleBUSDValueChange = (event) => {
        if (event.target.value >= minAmount) {
            setErr("")
        } else {
            setErr("Amount should be more than or equal to the minimum amount")
        }
        if (event.target.value > balanceUSDT) {
            setErr("In-Sufficient BUSD balance")
        }
        setValueBUSD(event.target.value)
    }

     const handleBUSDValueMaxClick = (balanceUSDT) => {
        if (balanceUSDT >= minAmount) {
            setErr("")
        } else {
            setErr("Amount should be more than or equal to the minimum amount")
        }
    }


    const handleBNBValueChange = (event) => {
        if (event.target.value >= minAmountBNB) {
            setErr("")
        } else {
            setErr("Amount should be more than or equal to the minimum amount")
        }
        if (valueBNB > balanceBNB) {
            setErr("In-Sufficient BNB balance")
        }
        setValueBNB(event.target.value)
    }

    const handleBNBValueMaxClick = (balanceBNB) => {
        if (balanceBNB >= minAmountBNB) {
            setErr("")
        } else {
            setErr("Amount should be more than or equal to the minimum amount")
        }
    }

    const handleCheck = (event) => {
        setTermsAndConditions(event.target.checked);
        if (event.target.checked) {
            setErrorTC("");
        }
    }

    return (
        <div>
            <button className={`${buttonStyle} btn btn-round btn-submit `}
                disabled={disabled}
                onClick={handleClickOpen}>
                Investin
            </button>

            <Modal isOpen={modal} toggle={toggle} className="investinDepositModal">
                <ModalHeader toggle={toggle} style={{}}>
                    <b style={{ color: 'white', fontSize: '1.3rem' }}> Deposit in {fund.name}</b>
                </ModalHeader>
                <ModalBody>
                    {selectedBNB &&
                        <>
                            <p>Choose Amount and Token to deposit (Min: <b>{minAmountBNB} BNB</b>) </p>

                            <InputGroupAddon addonType="append" style={{ background: 'white', color: 'black', marginTop: '3%' }}>
                                <input type="text" className="form-control" placeholder="0.0"
                                    name="investAmountBNB"
                                    value={valueBNB} onChange={handleBNBValueChange}
                                    style={{ border: 'none', background: 'white', color: 'black' }}
                                />
                            <InputGroupText onClick={() => { setValueBNB(balanceBNB); handleBNBValueMaxClick(balanceBNB) }} style={{ color: 'black', border: 'none', cursor: "pointer" }}>
                                {balanceBNB} BNB (Max)
                            </InputGroupText>
                            </InputGroupAddon>
                        </>
                    }

                    {!selectedBNB &&
                        <>
                            <p>Choose Amount and Token to deposit (Min: <b>{minAmount} BUSD</b>) </p>

                            <InputGroupAddon addonType="append" style={{ background: 'white', color: 'black', marginTop: '3%' }}>
                                <input type="text" className="form-control" placeholder="0.0"
                                    name="investAmountBUSD"
                                    value={valueBUSD} onChange={handleBUSDValueChange}
                                    style={{ border: 'none', background: 'white', color: 'black' }}
                                    // disabled={allowanceRequired}
                                />
                            <InputGroupText onClick={() => { setValueBUSD(balanceUSDT); handleBUSDValueMaxClick(balanceUSDT) }} style={{ color: 'black', border: 'none', cursor: "pointer" }}>{balanceUSDT} BUSD (Max)</InputGroupText>
                            </InputGroupAddon>
                        </>
                    }


                    {
                        !allowanceRequired &&
                        <p style={{ color: "red" }}>{err}</p>
                    }

                    {(fund.fundFactory === FUND_FACTORY_DEFI_ADDR) &&
                        <div className="deposit-options">
                            <Button className='btn-deposit'
                                style={
                                    selectedBNB ? { background: 'transparent', border: 'solid 0.2px' }
                                        : { background: 'transparent', border: 'solid 5px' }
                                }
                                onClick={() => { setSelectedBNB(false); setErr("") }}
                            >
                                <img src="https://assets.coingecko.com/coins/images/9576/small/BUSD.png?1598003707"
                                    alt="BUSD" className="img-button"
                                    style={{ width: '25px' }}
                                /> BUSD
                            </Button>
                            <Button className='btn-deposit'
                                style={
                                    !selectedBNB ? { background: 'transparent', border: 'solid 0.2px' }
                                        : { background: 'transparent', border: 'solid 5px' }
                                }
                                onClick={() => { setSelectedBNB(true); setErr("") }}
                            >
                                <img src="https://assets.coingecko.com/coins/images/825/thumb/binance-coin-logo.png?1547034615" alt="BNB" className="img-button" style={{ width: '25px' }} /> BNB
                            </Button>
                        </div>
                    }


                    <div style={{ marginTop: '3%' }}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    // checked={termsAndConditions}
                                    onChange={handleCheck}
                                    name="checkedB"
                                    style={{ color: '#818394' }}
                                />
                            }
                            label=""
                            style={{ marginRight: '3px' }}
                        />
                        <Label style={{ color: 'white', fontSize: '1rem', fontFamily: "Roboto" }} >
                            I agree to the
                            <Link target="_blank" to={`/user/terms`}
                                style={{ color: 'rgba(111,86,253,1)', fontWeight: '500', fontSize: '1rem', fontFamily: "Roboto" }}>
                                {` Terms & Conditions`}
                            </Link>.
                        </Label>
                        <p style={{ color: "red" }}>{errorTC}</p>
                    </div>

                </ModalBody>
                <ModalFooter>
                    {
                        allowanceRequired && !selectedBNB
                            ?
                            <Button color="primary" onClick={handleApprove} disabled={errorTC!==""} className="desposit-center-button">
                                {loading && (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {/* <span>APPROVING ...</span> */}
                                    </>
                                )
                                }
                                {!loading && 'Approve'}
                            </Button>
                            :
                            <Button color="primary" onClick={handleInvest} disabled={err!=="" || errorTC!==""} className="desposit-center-button">

                                {loading && (
                                    <>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        {/* <span>Depositing ...</span> */}
                                    </>
                                )
                                }
                                {!loading && 'Deposit'}
                            </Button>
                    }

                </ModalFooter>
            </Modal>
        </div>
    );
}

InvestinDepositModal.propTypes = {
    web3: PropTypes.object,
    fund: PropTypes.exact({
        name: PropTypes.string,
        address: PropTypes.string,
        fundFactory: PropTypes.string,
        routerAddress: PropTypes.string
    }),
    minAmount: PropTypes.number,
    address: PropTypes.string,
    disabled: PropTypes.bool
}
