const SET_INVESTMENTS = 'SET_INVESTMENTS';
const ADD_INVESTMENT = 'ADD_INVESTMENT';

const investmentsReducer = (state = {}, action) => {
  const investments = action.investments;
  switch (action.type) {
    case SET_INVESTMENTS:
      return {
        // not sure why I am doing "...state"
        ...state,
        investments,
      };
    case ADD_INVESTMENT:
      return {
        ...state,
        investments: [...state.investments, action.newInvestment],
      };
    default:
      return state;
  }
};

export default investmentsReducer;
