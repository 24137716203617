const SET_WEB3 = 'SET_WEB3';
const SET_INVESTMENTS = 'SET_INVESTMENTS';
const ADD_INVESTMENT = 'ADD_INVESTMENT';
const SET_ADDRESS = 'SET_ADDRESS';
const SET_FUND_ADDRESS = 'SET_FUND_ADDRESS';
const SET_FACTORY_ADDRESS = 'SET_FACTORY_ADDRESS';
const SET_APPROVED_AMT = 'SET_APPROVED_AMT';
const SET_APPROVING = 'SET_APPROVING';
const SET_DEPOSITING = 'SET_DEPOSITING';
const SET_CHAIN_ID = 'SET_CHAIN_ID';
const SET_USDT_BALANCE = 'SET_USDT_BALANCE';
const SET_PRICES = 'SET_PRICES';
export const SET_FIRE_CONFETTI = 'SET_FIRE_CONFETTI';

export const setGlobalPrices = (prices) =>{
  return {
    type: SET_PRICES,
    prices,
  };
}

export const setGlobalWeb3 = (web3) => {
  return {
    type: SET_WEB3,
    web3,
  };
};

export const setGlobalInvestments = (investments) => {
  return {
    type: SET_INVESTMENTS,
    investments,
  };
};

export const addGlobalInvestment = (newInvestment) => {
  return {
    type: ADD_INVESTMENT,
    newInvestment,
  };
};

export const setGlobalAddress = (address) => {
  return {
    type: SET_ADDRESS,
    address,
  };
};

export const setGlobalChainId = (chainId) => {
  return {
    type: SET_CHAIN_ID,
    chainId,
  };
};

export const setGlobalFundAddress = (fundAddress) => {
  return {
    type: SET_FUND_ADDRESS,
    fundAddress,
  };
};


export const setGlobalFactoryAddress = (factoryAddress) => {
  return {
    type: SET_FACTORY_ADDRESS,
    factoryAddress,
  };
};

export const setGlobalUSDTBalance = (balance) => {
  return {
    type: SET_USDT_BALANCE,
    balance,
  };
};

export const setGlobalApprovedAmt = (allowance) => {
  return {
    type: SET_APPROVED_AMT,
    allowance,
  };
};

export const setGlobalApproving = (approving) => {
  return {
    type: SET_APPROVING,
    approving,
  };
};

export const setGlobalDespositing = (despositing) => {
  return {
    type: SET_DEPOSITING,
    despositing,
  };
};

export const setGlobalFireConfetti = (fireConfetti) => {
  return {
    type: SET_FIRE_CONFETTI,
    fireConfetti,
  };
};
