import React, { useEffect, useState } from 'react';
// import classNames from 'classnames';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  // ButtonGroup,
  CardHeader,
  CardTitle,
} from 'reactstrap';
import CustomButton from '../components/button';

import { useSelector } from 'react-redux';
import DashboardCard from './DashboardViews/DashboardCard';
import WalletProviderPopup from '../layouts/Landing/ConnectWalletModal';
import FundA from '../contracts/Fund.json';
// import InvestinMulticall2A from '../contracts/InvestinMulticall2.json';
import {  investinMulticall2Abi } from '../utils/getAbi';
import Fusion_V3_V4_MulticallAbi from '../contracts/Fusion_V3_V4_Multicall.json';


import ToggleButton from '../components/Toggle';
import Doughnut from '../variables/Pie';
import getAssetsWithBalances from '../utils/getContractAssets';
import Overlay from '../components/Overlay';
import {
  API_URL,
  FUND_FACTORY_DEFI_ADDR,
  INVESTIN_MULTI_CALL,
  ROUTER_DEFI_ADDR,
} from '../utils/constants';
import Container from 'reactstrap/lib/Container';
import { sendTrxNotify } from '../utils/notify';
// import InvestinModal from '../components/InvestinModal';
import {InvestinDepositModal} from '../components/InvestinDepositModal';

import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
import { CustomAreaChart } from 'investin-ui-components-package';

// import { useFunds } from '../hooks/useFunds';
import { useHistory } from 'react-router';
// import { getRouterAddress } from '../utils/getAbi';
import { DynamicPerformanceModal } from '../components/DynamicPerformanceModal';
import { getStrategiesWithBalances } from '../utils/strategyHelper';
// import { xbtce } from 'ccxt';

const DashboardManager = () => {

  const web3 = useSelector(
    (state) => state.web3Reducer.web3
  );

  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );

  const address = useSelector(
    (state) => state.addressReducer.address
  );

  const investments = useSelector(
    (state) => state.investmentsReducer.investments
  );

  const factoryAddress = useSelector((state) => state.factoryAddressReducer.factoryAddress);
  // const [factoryAddress, setFactoryAddress] = useState('')

  const history = useHistory();
  const [openConnectWallet, setOpenConnectWallet] = useState(false)
  const [fundInstance, setFundInstance] = useState({})
  const [performance, setPerformance] = useState(0);
  const [aum, setAum] = useState(0);
  const [numberOfActiveInvestments, setNumberOfActiveInvestments] = useState(0)
  const [managerFundStatus, setManagerFundStatus] = useState('');
  const [pieData, setPieData] = useState([])
  const [transfering, setTransfering] = useState(false);
  const [claiming, setClaiming] = useState(false);
  const [managerPerformanceFees, setManagerPerformanceFees] = useState(0);
  const [amountInRouter, setAmountInRouter] = useState(0);
  const [managerSelfInvestedAmount, setManagerSelfInvestedAmount] = useState(0);
  const [routerAddress, setRouterAddress] = useState(''); //useState(ROUTER_PANCAKESWAP_ADDR);// need to use muticall
  const [minAmount, setMinAmount] = useState(0);
  const [fundName, setFundName] = useState('');

  const [hoverValue, setHoverValue] = useState(0);
  const [chartData, setChartData] = useState(undefined);
  const [chartTimeframe, setChartTimeframe] = useState(30);

  const managerPerformanceCalulation = (routerAddress, fundAmountinWei, startPerformance, currentPerformance, minReturn) => {
    let percentageReturn = 0;
    let performanceFee = 0;
    // console.log("routerAddress, fundAmountinWei, startPerformance, currentPerformance, minReturn :: ", routerAddress, fundAmountinWei, startPerformance, currentPerformance, minReturn);
    if (parseFloat(startPerformance) === 0) {
      return 0;
    }
    if (routerAddress === ROUTER_DEFI_ADDR) {
      percentageReturn = parseFloat(currentPerformance) * (10 ** 8) / parseFloat(startPerformance);
      // console.log("percentageReturn:", percentageReturn);
      // if (percentageReturn >= minReturn) {
          performanceFee = (parseInt(fundAmountinWei)*(percentageReturn))/(10**8)
        // } else {
        //   performanceFee = 0;
        // } 
    }
    else {
      percentageReturn = parseFloat(currentPerformance) * (10 ** 4) / parseFloat(startPerformance);
      // console.log("percentageReturn else non-defi:", percentageReturn);
        // if(percentageReturn >= minReturn) { 
            performanceFee = (parseInt(fundAmountinWei)*(percentageReturn))/(10**4);
        // } else {
        //   performanceFee = 0;
        // } 
    }
    return performanceFee;
  }

  useEffect(() => {
    (async () => {
      if (web3 && address && fundAddress && fundAddress !== '' && fundAddress !== '0x0000000000000000000000000000000000000000') {
        let fundInstance;
        try {
           fundInstance = new web3.eth.Contract(
            FundA,
            fundAddress
          );
          setFundInstance(fundInstance);
            
        } catch (error) {
          console.error('fund instance error:', error)
        }
        
        try {
          const investinMulticallInstance = new web3.eth.Contract(Fusion_V3_V4_MulticallAbi, INVESTIN_MULTI_CALL);
          // console.log("hello investinMulticallInstance:", investinMulticallInstance);
        
             // const getManagerDetails = await investinMulticallInstance.methods.getManagerDetails(address).call();
            const getManagerDetails = await investinMulticallInstance.methods.getFundDetailsMulti(fundAddress).call();

            // console.log("getFundDetails : ", getManagerDetails);
            // let performanceFee = getManagerDetails.performanceFee;
            // let _FundDetails = getManagerDetails._FundDetails;

          let { amountInRouter,
            // baseTokenAddress,
            baseTokenValue, currentPerformance,
            // managerAddress,
            routerAddress,
            managerFundStatus, minAmount, minReturn, numberOfActiveInvestments,
            // performanceFeePercentage
           } = getManagerDetails;
          
              
            // console.log('minAmount : ', web3.utils.fromWei(minAmount.toString(), 'ether'));
            setMinAmount(web3.utils.fromWei(minAmount.toString(), 'ether'));

            // console.log('PF% : ', parseFloat(`${web3.utils.fromWei(performanceFeePercentage.toString(), 'ether')}`).toFixed(2));
            // setPerformanceFeesPercentage(parseFloat(`${web3.utils.fromWei(performanceFeePercentage.toString(), 'ether')}`).toFixed(2));

              setAmountInRouter(parseFloat(`${web3.utils.fromWei(amountInRouter, 'ether')}`).toFixed(2));
            

            setAum(parseFloat(`${web3.utils.fromWei(baseTokenValue.toString(), 'ether')}`).toFixed(4));
            if (routerAddress === ROUTER_DEFI_ADDR) {
              // console.error("DEFI FUND")
              setPerformance((((parseFloat(currentPerformance) / 100000000) - 1).toFixed(4) * 100).toFixed(2));
            } else {
              // console.error("NON-DEFI FUND")
              setPerformance((((parseFloat(currentPerformance) / 10000) - 1).toFixed(4) * 100).toFixed(2));
            }
            setNumberOfActiveInvestments(numberOfActiveInvestments)
            setManagerFundStatus(managerFundStatus)
            setRouterAddress(routerAddress);
            // setFactoryAddress(getFactoryAddress(routerAddress));

              try {
                const data = await getAssetsWithBalances(web3, routerAddress, true, fundAddress, true);
                // console.log("data:", data);
                setPieData(data);
                if (routerAddress === ROUTER_DEFI_ADDR) {
                  let stData = await getStrategiesWithBalances(fundAddress);
                  setPieData([...data, ...stData]);
                }
              } catch (error) {
                console.error("error getAssetsWithBalances : ",error)
             }
          
          const getInvestmentByID = await fundInstance.methods.getInvestmentByID(0).call();
          // console.log("getInvestmentByID(0)::", getInvestmentByID);
          let startPerformance = getInvestmentByID.startPerformance;
          let managerAmt = getInvestmentByID.amount;
          
          // .getInvestmentByID(0).amount;
          if (managerAmt !== "0") {
            let x = managerPerformanceCalulation(routerAddress, managerAmt, startPerformance, currentPerformance, minReturn);
            x= parseFloat(`${web3.utils.fromWei(x.toString(), 'ether')}`).toFixed(2)
            //  console.log("xx :", x);
             setManagerPerformanceFees(x);
          }
         

        } catch (error) {
          console.error('investinMulticall getDetails  error:', error)
        }

        try {
            const { fund } = await (await fetch(
              `${API_URL}/fund/${fundAddress}/decentralised`
            )).json();
            // console.log(`fund details from api : response : `, fund)
      
            if (fund) {
              setFundName(fund.name)
            } else {
              setFundName(`${fundAddress.substring(0, 3)}...${fundAddress.substr(fundAddress.length - 5)}`)
            }
        } catch (error) {
          console.error('fund details from api ERROR:', error);
        }
        
      }
    })()
  }, [web3, fundAddress, address]);


  useEffect(() => {
    if (investments && investments.length !== 0) {
      let selfInvestments = investments.filter((i) => i.fundAddress === fundAddress);
      // console.log('selfInvestments:', selfInvestments);
      if (selfInvestments && selfInvestments.length !== 0) {
        let totalamt = selfInvestments
        .map((i) => parseFloat(i.amount))
        .reduce((a, b) => a + b, 0);
        setManagerSelfInvestedAmount(parseFloat(`${totalamt}`).toFixed(2));
      }
      else {
        setManagerSelfInvestedAmount(0);
      }  
    }
  }, [investments,fundAddress]); 

  const updateOnInvestOrPerformanceRedeem = async () => {
    try {
            const investinMulticallInstance = new web3.eth.Contract(Fusion_V3_V4_MulticallAbi, INVESTIN_MULTI_CALL);
            const getManagerDetails = await investinMulticallInstance.methods.getFundDetailsMulti(fundAddress).call();

      let { amountInRouter,
        // baseTokenAddress,
        baseTokenValue, currentPerformance,
        // managerAddress,
        routerAddress,
        // managerFundStatus, minAmount,
        minReturn,
        // numberOfActiveInvestments, performanceFeePercentage
              } = getManagerDetails;
          
            // setMinAmount(web3.utils.fromWei(minAmount.toString(), 'ether'));
            // setNumberOfActiveInvestments(numberOfActiveInvestments)
            // setManagerFundStatus(managerFundStatus)
            // setRouterAddress(routerAddress);
      
            setAmountInRouter(parseFloat(`${web3.utils.fromWei(amountInRouter.toString(), 'ether')}`).toFixed(2));
            setAum(parseFloat(`${web3.utils.fromWei(baseTokenValue.toString(), 'ether')}`).toFixed(4));
            if (routerAddress === ROUTER_DEFI_ADDR) {
              setPerformance((((parseFloat(currentPerformance) / 100000000) - 1).toFixed(4) * 100).toFixed(2));
            } else {
              setPerformance((((parseFloat(currentPerformance) / 10000) - 1).toFixed(4) * 100).toFixed(2));
            }

              try {
                const data = await getAssetsWithBalances(web3, routerAddress, true, fundAddress, true );
                setPieData(data);
              } catch (error) {
                console.error("error getAssetsWithBalances : ",error)
              }
          
            const getInvestmentByID = await fundInstance.methods.getInvestmentByID(0).call();
            // console.log("getInvestmentByID(0)::", getInvestmentByID);
            let startPerformance = getInvestmentByID.startPerformance;
            let managerAmt = getInvestmentByID.amount;
            
            // .getInvestmentByID(0).amount;
            if (managerAmt !== "0") {
              let x = managerPerformanceCalulation(routerAddress, managerAmt, startPerformance, currentPerformance, minReturn);
              x = parseFloat(`${web3.utils.fromWei(x.toString(), 'ether')}`).toFixed(2);
              setManagerPerformanceFees(x);
            }
         

        } catch (error) {
          console.error('investinMulticall getDetails  error:', error)
        }
  }
  

  const handleStatusChange = async () => {
    if (address) {
      const address = (await web3.eth.getAccounts())[0];
      try {
        const updated = fundInstance.methods.updateManagerFundStatus(!managerFundStatus);
        await sendTrxNotify(updated, address, "Fund Status Changed sucessfully..");
        setManagerFundStatus(!managerFundStatus);
      } catch (error) {
        console.error("handleStatusChange err:".error);
      }
      let status = await fundInstance.methods.managerFundStatus().call();  
      setManagerFundStatus(status); 
      // console.log('status:', status);
      
    } else {
      alert("Connect Wallet")
    }
  }

  const handleClaimPerformaceFees = async () => {
    setClaiming(true);
    try {
      let trx = fundInstance.methods.claimPerformanceFee();
      await sendTrxNotify(trx, address, "Performance Fee Claimed sucessfully..");
      setClaiming(false);
    } catch (error) {
      setClaiming(false);
      console.error('handleClaimPerformaceFees: ', error);
    }
    try {
      const investinMulticallInstance = new web3.eth.Contract(investinMulticall2Abi, INVESTIN_MULTI_CALL);
      let { performanceFee } = await investinMulticallInstance.methods.getManagerDetails(address).call();
      setManagerPerformanceFees(parseFloat(`${web3.utils.fromWei(performanceFee, 'ether')}`).toFixed(2));
      await updateOnInvestOrPerformanceRedeem();
    } catch (error) {
      console.error('pfees error: ', error);
    }
  };


  const handleTransferToVault = async () => {
    setTransfering(true);
    try {
      let trx = fundInstance.methods.transferToVault();
      await sendTrxNotify(trx, address, "Transfered to Vault done sucessfully..");
      setTransfering(false);
    } catch (error) {
      setTransfering(false);
      console.error('handleTransferToVault: ', error);
    }
    //refreshing amt and assets
    try {
      let amountInRouter = await fundInstance.methods.getAmountInRouter().call();
      setAmountInRouter(web3.utils.fromWei(amountInRouter, 'ether'));
    } catch (error) {
      console.error('getAmountInRouter error: ', error);
    }
      await updateOnInvestOrPerformanceRedeem();
    
  };

  useEffect(() => {
    // console.log(`fundAddress : `, fundAddress)
    if (fundAddress && fundAddress !== '' && fundAddress!=='0x0000000000000000000000000000000000000000' ) {
      // setLoading(true);
      (async () => {
        const response = await fetch(
          `${API_URL}/fund/${fundAddress}/decentraisedPerformance/days/${chartTimeframe}`
        );
        const data = await response.json();
        console.log("chart data from API :", data)
        let days = data?.[`${chartTimeframe}_days_data`];
          let x = [];
          for (const [key, value] of Object.entries(days)) {
            x.push({ timestamp: key, value: value })
          }
          // console.log("x :", x);

          setChartData(x);
          setHoverValue(x[x.length - 1].value)
        // setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTimeframe, fundAddress]);

  return (
    <>
      <div className="content">
        {openConnectWallet &&
          <WalletProviderPopup
            open={true}
            handleClose={(value) => setOpenConnectWallet(false)}
            onWeb3Connect={() => (console.log("wallet connected"))}
          />}
        <Row>
          <DashboardCard
            heading="PERFORMANCE"
            content={`${performance} %`}
            color="blue"
            icon="icon-money-coins"
            useCol />
          <DashboardCard
            heading="ASSETS UNDER MANAGEMENT"
            content={`${aum} BUSD`}
            color="violet"
            icon="icon-bank"
            useCol />
          <DashboardCard
            heading="FUND ADDRESS"
            content={fundAddress}
            color="cerulean"
            icon="icon-wallet-43"
            allowCopy
            isAddress={true}
            useCol />
        </Row>

        <Container>
          <Row>
            <Col xs="12">
              <Card className="card-chart">
                <CardHeader>
                  <Row>
                    <Col className={`text-left ${hoverValue >= 0 ? 'white-odometer' : 'red-odometer'}`} sm="4">
                      <h5 className="card-category font-weight-bold">
                        Performance
                      </h5>
                      <CardTitle tag="h2" style={{ color: 'red !important' }}>
                        <Odometer value={hoverValue} duration={1000} /> <span>%</span>
                      </CardTitle>
                    </Col>
                    <Col className="text-right" sm="8" style={{ paddingTop: '2%' }}>
                      <Button
                        className={`btn-round ${chartTimeframe === 7 ? 'active btn-active' : 'btn-blue'
                          }`}
                          id="1W"
                        size="sm"
                        style={{ fontSize: '10px' }}
                        onClick={() => setChartTimeframe(7)}
                      >
                          1W
                      </Button>
                      <Button
                        className={`btn-round ${chartTimeframe === 30 ? 'active btn-active' : 'btn-blue'
                          }`}
                        size="sm"
                        id='1w'
                        style={{ fontSize: '10px' }}
                        onClick={() => setChartTimeframe(30)}
                      >
                          1M
                      </Button>
                      <Button
                        color="secondary"
                        id="2M"
                        size="sm"
                        style={{ fontSize: '10px' }}
                        className={`btn-round ${chartTimeframe === 60 ? 'active btn-active' : 'btn-blue'
                          }`}
                        onClick={() => setChartTimeframe(60)}
                      >
                          2M
                      </Button>
                      <Button
                        color="secondary"
                        className={`btn-round ${chartTimeframe === 90 ? 'active btn-active' : 'btn-blue'
                          }`}
                        id="3M"
                        size="sm"
                        style={{ fontSize: '10px' }}
                        onClick={() => setChartTimeframe(90)}
                      >
                          3M
                      </Button>
                </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="chart-area">
                     <Row className="justify-content-center">
                        <Col sm="11">
                          <CustomAreaChart data={chartData}/>
                        </Col>
                      </Row>
                  </div>
                </CardBody>
                {/* if no wallet connected display connect wallet; if wallet connected but new user display start investing */}

                <Overlay display={!web3}>
                  <Button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      msTransform: 'translate(-50%,-50%)',
                    }}
                    className="btn-main-theme-secondary btn-align-center"
                    onClick={() => setOpenConnectWallet(true)}
                  > Connect Wallet </Button>
                </Overlay>
                <Overlay display={web3 && ( !fundAddress || fundAddress==='' || fundAddress==='0x0000000000000000000000000000000000000000')}>
                  <Button
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      msTransform: 'translate(-50%,-50%)',
                    }}
                    className="btn-main-theme-secondary btn-align-center"
                    onClick={() => history.push('../../user/dashboard')}
                  > Create Fund </Button>
                </Overlay>
              </Card>
            </Col>
          </Row>
        </Container>

        {/* <Row>
          <Col xs="12">
            <Card className="card-chart">
              
              <CardBody>
                <div className="chart-area">
                  {
                    web3 &&
                  }
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row> */}
        <Container>
          <Row>
            <Col xs="12" md="6" lg="6">
              <Card style={{ padding: '10px' }}>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Doughnut heading="Assets" data={pieData} />
                  </Col>
                </Row>
              </Card>
              <DashboardCard heading="NUMBER OF ACTIVE INVESTMENTS" content={`${numberOfActiveInvestments}`} />
              {/* <DashboardCard heading="FUND STATUS" tooltiptext="when Fund Status is set to false, investors wont be able to invest in this fund">
                {<ToggleButton checked={!!managerFundStatus} handleChange={handleStatusChange} />}
              </DashboardCard> */}
            </Col>
            <Col xs="12" md="6" lg="6">
              <DashboardCard
                style={{ marginBottom: "0px" }}
                heading="Invest in Fund"
                content={`${managerSelfInvestedAmount} BUSD`}
                tooltiptext="Amount you have invested in the fund"
              >
                {
                  (web3 && routerAddress && fundAddress && fundAddress!=='0x0000000000000000000000000000000000000000' ) ?
                    // <InvestinModal
                    //   buttonStyle={(managerSelfInvestedAmount == 0) ? 'nav-pills-info investin-pulse-button ' : ''}
                    //   web3={web3}
                    //   fund={{ name: fundName, address: fundAddress, routerAddress }}
                    //   minAmount={parseFloat(minAmount)}
                    //   address={address}
                    //   disabled={false} //TEST
                    // />
                    <InvestinDepositModal
                        buttonStyle={(managerSelfInvestedAmount === 0) ? 'nav-pills-info investin-pulse-button ' : ''}  
                        web3={web3}
                        fund={{ name: fundName, address: fundAddress, fundFactory: factoryAddress, routerAddress }}
                        minAmount={parseFloat(minAmount)}
                        address={address}
                      disabled={minAmount < 0}
                      afterInvestCallback={async () => { await updateOnInvestOrPerformanceRedeem() }}
                            />
                    :
                    <CustomButton disabled={true} text="Investin" />
                }
              </DashboardCard>
              <DashboardCard
                style={{ marginBottom: "0px" }}
                heading="TRANSFERABLE FUNDS"
                content={`${amountInRouter} BUSD`}
                tooltiptext="Transfer the invested amount to fund vault for trading and collect the management fee. Make sure to transfer the funds to vault to start trading!"
              >
                <CustomButton
                  className={(amountInRouter > 0) ? 'nav-pills-info pulse-button ' : ''}
                  disabled={parseFloat(amountInRouter) === 0}
                  onClick={handleTransferToVault}
                  loading={transfering}
                  text="Transfer" />
              </DashboardCard>
              
              {
                 (factoryAddress === FUND_FACTORY_DEFI_ADDR)  ?
                <DashboardCard heading="DYNAMIC PERFORMANCE"
                content={`${managerPerformanceFees}+ USDT`}
                tooltiptext="As a manager you will be able to fetch dynamic performance">
                {
                  (web3 && routerAddress && fundAddress && fundAddress!=='0x0000000000000000000000000000000000000000' ) ?
                    <DynamicPerformanceModal
                      buttonStyle={(managerPerformanceFees > 0) ? 'nav-pills-info investin-pulse-button ' : ''}  
                      web3={web3}
                      fund={{ name: fundName, address: fundAddress, fundFactory: factoryAddress, routerAddress }}
                      address={address}
                      disabled={factoryAddress !== FUND_FACTORY_DEFI_ADDR}
                       managerPerformanceFees={managerPerformanceFees}
                       afterClaimCallback={async () => { await updateOnInvestOrPerformanceRedeem() }}    
                     />
                    :
                    <CustomButton disabled={true} text="Claim" />
                }
              </DashboardCard>

                :
                <DashboardCard
                  heading="CLAIMABLE FEE"
                  content={`${managerPerformanceFees} USDT`}
                  tooltiptext="Performance fee on fund contract available to be claimed. Make sure to keep the amount in BUSD before collecting it"
                >
                  <CustomButton
                    className={(managerPerformanceFees > 0) ? 'nav-pills-info pulse-button' : ''}
                    disabled={parseFloat(managerPerformanceFees) === 0}
                    onClick={handleClaimPerformaceFees}
                    loading={claiming}
                    text="Claim" />
                </DashboardCard>
              }
             
              
              <DashboardCard heading="FUND STATUS" tooltiptext="when Fund Status is set to false, investors wont be able to invest in this fund">
                {<ToggleButton checked={!!managerFundStatus} handleChange={handleStatusChange} />}
              </DashboardCard>
            </Col>

          </Row>
        </Container>
      </div>
    </>
  );
};

export default DashboardManager;
