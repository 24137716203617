/*eslint-disable*/
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { NavLink, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// javascript plugin used to create scrollbars on windows
// reactstrap components
import { Nav, NavLink as ReactstrapNavLink, Row, Col, Button as ReactstrapButton } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/NightsStay';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import FundIcon from "@material-ui/icons/EmojiObjectsOutlined";
import InvestmentIcon from '@material-ui/icons/MonetizationOnOutlined';
// import MarketIcon from "@material-ui/icons/TimelineOutlined";
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import MuseumIcon from '@material-ui/icons/Museum';
import BulbIcon from '@material-ui/icons/EmojiObjects'
import { useHistory } from 'react-router';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import { ReactComponent as TractorIcon } from '../../assets/img/sidebar-icons/tractor.svg';

import DescriptionIcon from '@material-ui/icons/Description';
import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import { ReactComponent as MediumIcon } from '../../assets/img/medium.svg'
import { ReactComponent as DiscordIcon } from '../../assets/img/discord.svg'


import StartFund from './StartFund'; 

var ps;

import { setGlobalInvestments, setGlobalWeb3 } from '../../redux/actions';

// REMOVED refs FROM THIS COMPONENT ***
// const refContainer = useRef(initialValue);
// const inputEl = useRef(null);

function Sidebar(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const web3 = useSelector((state) => state.web3Reducer.web3);
  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );

  const [displayOverlay, setDisplayOverlay] = useState(true);
  const [displayStartFundingModal, setDisplayStartFundingModal] = useState(
    false
  );
  const [classes, setClasses] = useState('');
  const [isManager, setIsManager] = useState(false);

  const { bgColor, routes, rtlActive, logo } = props;
  // let logoImg = null;
  // let logoText = null;

  const [logoImg, setLogoImg] = useState();
  const [logoText, setLogoText] = useState();

  useEffect(() => {
    if (
      !fundAddress ||
      fundAddress === '0x0000000000000000000000000000000000000000'
    ) {
      // setDisplayStartFundingModal(true);
      setIsManager(false);
    } else {
      // setDisplayStartFundingModal(false);
      setIsManager(true);
    }
  }, [fundAddress])

  useEffect(() => {
    if (navigator.platform.indexOf('Win') > -1) {
      // ps = new PerfectScrollbar(refs.sidebar, {
      //   suppressScrollX: true,
      //   suppressScrollY: false,
      // });
    }
    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        setLogoImg(
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        setLogoText(
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        setLogoImg(
          <Link
            to={logo.innerLink ? logo.innerLink : '/'}
            className="simple-text logo-mini"
            onClick={props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </Link>
        );
        setLogoText(
          <Link
            to={logo.innerLink ? logo.innerLink : '/'}
            className="simple-text logo-normal"
            onClick={props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }
  }, []);

  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  };

  const logout = () => {
    dispatch(setGlobalWeb3(undefined));
    dispatch(setGlobalInvestments(undefined));
    history.push('../../user/dashboard');
  }


  const linkOnClick = () => {
    document.documentElement.classList.remove('nav-open');
  };

  const handleClick = () => {
    if (classes === 'dropdown show-dropdown') {
      setClasses('dropdown show-dropdown show');
    } else {
      setClasses('dropdown show-dropdown');
    }
  };
  const activateMode = (mode) => {
    switch (mode) {
      case 'light':
        document.body.classList.add('white-content');
        break;
      case 'dark':
        document.body.classList.remove('white-content');
      default:
        document.body.classList.remove('white-content');
        break;
    }
  };

  const closeOverlay = () => {
    if (!web3) {
      alert('No web3 Please connect Wallet');
      // setOpenModal(false);
      history.push('../../user/dashboard');
      return;
    }
    setDisplayStartFundingModal(!displayStartFundingModal);
  };

  const getIcon = (iconImageName) => {
    switch (iconImageName) {
      case 'DashboardIcon':
        return <DashboardIcon style={{ color: '#9595A9' }} />;
        break;
      case 'FundIcon':
        return <MuseumIcon style={{ color: '#9595A9' }} />;
        break;
      case 'InvestmentIcon':
        return <InvestmentIcon style={{ color: '#9595A9' }} />;
        break;
      case 'MarketIcon':
        return <TrendingUpIcon style={{ color: '#9595A9' }} />;
        break;
      case 'Staking':
        return <TractorIcon style={{ fontSize: '2em', color: '#9595A9' }} />;
        break;
      case 'CommentRoundedIcon':
        return <CommentRoundedIcon style={{ color: '#9595A9' }} />;
        break;
        case 'BulbIcon':
          return <BulbIcon style={{ color: '#9595A9' }} />;
          break;
      case 'LiveHelpRoundedIcon':
        return <LiveHelpRoundedIcon style={{ color: '#9595A9' }} />;
        break;
      default:
        // document.body.classList.remove("white-content");
        break;
    }
  };

  return (
    <div className="sidebar" data={bgColor}>
      <div className="sidebar-wrapper">
        {logoImg !== null || logoText !== null ? (
          <div className="logo">
            {logoImg}
            {logoText}
          </div>
        ) : null}
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '15px',
          }}
        >
          {/* ============================================================= */}
          {/* <NavLink to="/manager/dashboard">  */}
          <Button
            variant="outlined"
            style={{
              background: '#4055d4',
              border: 'none',
              color: 'white',
              padding: '0px 0px 0px 16px',
              cursor: 'pointer',
              // borderColor: '#3F51B5',
              fontFamily: 'poppins',
              borderRadius: '30px',
              height: 37,
            }}
            onClick={() => closeOverlay()}
          >
            {isManager ? (
              <NavLink to="/manager/dashboard">
                <p>Manager Dashboard</p>
              </NavLink>
            ) : (
              <div>Start Fund</div>
            )}
            <i
              className="fas fa-long-arrow-alt-right"
              style={{
                background: '#2630b2',
                color: 'white',
                padding: '12px',
                borderRadius: '0px 30px 30px 25px',
                marginLeft: '5px',
              }}
            ></i>
          </Button>{' '}
          {displayStartFundingModal && !isManager &&
            <StartFund
              isOpen={displayStartFundingModal}
              toggle={() => setDisplayStartFundingModal(!displayStartFundingModal)} />}
        </div>
        <Nav>
          {routes.map((prop, key) => {
            if (prop.redirect) return null;
            if (prop.name == 'terms') return <li key='terms'></li>;
            return (
              <li
                className={
                  activeRoute(prop.path) +
                  (prop.pro ? ' active-pro' : '') +
                  (prop.style ? ` ${prop.style}` : '')
                }
                key={key}
              >
                <NavLink
                  to={prop.layout + prop.path}
                  className="nav-link"
                  activeClassName="active"
                  onClick={props.toggleSidebar}
                >
                  <i className={prop.icon} />
                  <Row>
                    <Col lg="2" xs="2">
                      {getIcon(prop.iconImageName)}
                    </Col>
                    <Col lg="1" xs="1">
                      <p style={{ fontSize: '11px' }}>
                        {rtlActive ? prop.rtlName : prop.name}
                      </p>
                    </Col>
                  </Row>
                  {/* <img src={getIcon(prop.iconImageName)} alt="" /> */}
                </NavLink>
              </li>
            );
          })}

          <li key="docs" >
          <a href="https://investin-pro.github.io/Investin_docs/investor/" target="_blank" className="nav-link">
                  <Row>
                    <Col lg="2" xs="2">
                        <DescriptionIcon style={{color:'rgb(149 149 169)'}} />
                    </Col>
                    <Col lg="1" xs="1">
                      <p style={{ fontSize: '11px' }}>DOCS
                      </p>
                    </Col>
                  </Row>
              </a>
          </li>


          <hr color="#9595A9" style={{ width: '85%' }} />
         
        
          {/* LIGHTMODE DARK MODE */}
          {/* 
          <Grid container justifyContent="space-evenly">
            <Button
              variant="outlined"
              style={{
                color: 'white',
                borderColor: '#3F51B5',
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
              onClick={() => activateMode('light')}
            >
              <LightIcon />
            </Button>
            <Button
              variant="outlined"
              style={{
                color: 'white',
                borderColor: '#3F51B5',
                paddingLeft: '30px',
                paddingRight: '30px',
              }}
              onClick={() => activateMode('dark')}
            >
              <DarkIcon />
            </Button>
          </Grid>
           */}

          {
            web3 && <Grid style={{ marginTop: '20px' }} container justifyContent="center">
            <ReactstrapButton className="btn-main-theme-secondary" onClick={logout}>Logout</ReactstrapButton>
            </Grid>
          }
          <Grid style={{ marginTop: '20px' }} container justifyContent="center" >
              <a href="https://t.me/Investin_pro1" target="_blank">
                <TelegramIcon style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }} />
              </a>
              <a href="https://twitter.com/Investin_pro?s=20" target="_blank">
                <TwitterIcon style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }} />
              </a>
              <a href="https://discord.com/invite/Yf54h9B" target="_blank">
                <DiscordIcon  style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }}/>
              </a>
              {/* <a href="https://medium.com/investin-pro" target="_blank">
                <MediumIcon  style={{
                  color: 'white',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }}/>
              </a> */}
          </Grid>
          
          <Grid style={{ marginTop: '10px' }} container justifyContent="center" alignItems="flex-end">
            <div
              style={{ color: 'white', fontSize: '10px' }}
              className="copyright"
            >
              © {new Date().getFullYear()} Investin , all rights reserved{' '}
            </div>
          </Grid>


        </Nav>
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
