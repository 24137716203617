import React from 'react'
import PropTypes from 'prop-types';

const Overlay = ({ display, children }) => {
  return (
    <>
      {display && <div
        id="overlay"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          bottom: '0',
          backgroundColor: 'rgba(0,0,0,0.5)',
          zIndex: '9',
          borderRadius: '5px',
          cursor: 'pointer',
        }}>
        {children}
      </div>}
    </>
  )
}

export default Overlay;

Overlay.propTypes = {
  display: PropTypes.bool
}
