import React from 'react'
import PropTypes from 'prop-types';
import {
    Spinner
} from 'reactstrap';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const StyledButton = withStyles({
  root: {
    //    borderRadius: 16,
    //     backgroundColor: ' #B409D4',
    //     width: 105,
    //     color: 'white'
  },
  label: {
    textTransform: 'capitalize',
    },
    disabled: {
        opacity: "0.65",
      pointerEvents: 'all !important',
        cursor: 'not-allowed',
  }
})(Button);

const CustomButton = ({ text, loading, size = "sm", disabled=false, ...props }) => {
    return (
        <>
                <StyledButton
                variant="contained"
                color="primary"
                disabled={disabled || loading}
                style={
                (disabled || loading) ?
                    {
                        borderRadius: 16,
                        backgroundColor: ' #B409D4',
                        width: 105,
                        color: 'white',
                        cursor: 'not-allowed',
                    }
                        :
                    {
                        borderRadius: 16,
                        backgroundColor: ' #B409D4',
                        width: 105,
                        color: 'white',
                        }
                }
                {...props}
            >
             {!loading && text }
            { loading && <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                        /> }
            
                </StyledButton>      
        </>
    )
}

export default CustomButton;


CustomButton.propTypes = {
    text: PropTypes.string,
    loading: PropTypes.bool,
    size: PropTypes.oneOf(["sm", "md", "lg"])
}
