import React, {
  // useEffect,
  useMemo, useState
} from 'react';
import {
  Row, Col,
  // Pagination, PaginationItem, PaginationLink
} from 'reactstrap';
import {
  // useSelector,
  // useDispatch
} from 'react-redux';
// import FirstPageIcon from '@material-ui/icons/FirstPage';
// import LastPageIcon from '@material-ui/icons/LastPage';
// import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
// import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import StrategyListCardManager from './StrategyListCardManager';
import StrategyListCardUser from './StrategyListCardUser';
import 'react-toastify/dist/ReactToastify.css';
// import { fetchPrices } from '../utils/strategyHelper';

const StrategyListView = (props) => {

    // const dispatch = useDispatch();
  // const dataLimit = 12;
  // const pageLimit = 3;
  const { strategies: data, searchText, selectedFilter, selectedProtocol, disableStrategies, isManagerSide, onlyShowDesposited } = props;

  const [stData, setStData] = useState([]);

  // const [refresh, setRefresh] = useState(false)

  // const [pages, setPages] = useState(Math.round(data?.length / dataLimit) ?? 0);
  // const [currentPage, setCurrentPage] = useState(1);

  // useEffect(() => {
  //   if (data) {
  //     setPages(Math.round(data.filter((f) => parseFloat(f.totalAmount) > 0).length / dataLimit) ?? 0)
  //   }
  // }, [data])

  // function goToNextPage() {
  //   setCurrentPage((page) => page + 1);
  // }

  // function goToPreviousPage() {
  //   setCurrentPage((page) => page - 1);
  // }


  // useEffect(() => {
  //   (async()=>{
  //     console.log("calling fetchprices");
  //     await fetchPrices(dispatch);
  //   })()
  //   // eslint-disable-next-line
  // }, []) 

  const setStrategyData = (strategyAddress, APY, AUM) => {
    // console.log('setStrategyData  :', strategyAddress, APY, AUM);
    // console.log(stData);
    // setStData([...stData,  { strategyAddress, APY, AUM }]  )

    let ST = stData.findIndex((st) => st.strategyAddress === strategyAddress);
    if (ST >= 0) {
      if (stData[ST].APY === APY && stData[ST].AUM === AUM) {
        return;
      }
      stData[ST] = { strategyAddress, APY, AUM };
    } else {
      stData.push({ strategyAddress, APY, AUM });
    }

    setStData(stData);
  }

  const filterFunds = (items) => {

    // console.log('items before :', items);
    items = items.map((i) => {
      let ST = stData.find((st) => st.strategyAddress === i.strategyAddress);
      let r = i;
      r.APY = ST?.APY;
      r.AUM = ST?.AUM;
      return r;
    });
    // console.log('items After :', items);
    console.log("selectedFilter ::", selectedFilter);


    switch (selectedFilter) {
      case "Highest APY":
        return items.sort((a, b) => parseFloat(b.APY) - parseFloat(a.APY))
      case "Lowest APY":
        return items.sort((a, b) => parseFloat(a.APY) - parseFloat(b.APY))
      case "Highest AUM":
        return items.sort((a, b) => parseFloat(b.AUM) - parseFloat(a.AUM))
      case "Lowest AUM":
        return items.sort((a, b) => parseFloat(a.AUM) - parseFloat(b.AUM))
      default:
        return data
    }
  }

  const filterProtocol = (items) => {
    if (selectedProtocol !== '')
      return items.filter((st) => st.protocol === selectedProtocol);
    else
      return items;
  }

  const fundsFiltered = useMemo(() => {
    if (!data) return []
 
    // if (searchText != "" && selectedFilter != '') {
    //   return filterFunds(data.filter((strategy) => strategy.name.toLowerCase().includes(searchText.toLowerCase())));
    // } else if (searchText == "" && selectedFilter != '') {
    //   return filterFunds(data)
    // }
    let StData =data;
    if (searchText !== "" && selectedProtocol !== '') {
      StData =  filterProtocol(data.filter((strategy) => strategy.name.toLowerCase().includes(searchText.toLowerCase())));
    } else if (searchText === "" && selectedProtocol !== '') {
      StData =  filterProtocol(data);
    } else if (searchText !== "") {
      StData = data.filter((strategy) => strategy.name.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      StData = data;
    }
    if (selectedFilter !== '') {
      StData = filterFunds(StData);
    }
    return StData;
  }, [selectedFilter, selectedProtocol, data, searchText]) // eslint-disable-line react-hooks/exhaustive-deps

  // function changePage(event) {
  //   const pageNumber = Number(event.target.textContent);
  //   setCurrentPage(pageNumber);
  // }

  // const getPaginatedData = () => {
  //   if (fundsFiltered) {
  //     const startIndex = currentPage * dataLimit - dataLimit;
  //     const endIndex = startIndex + dataLimit;

  //     return fundsFiltered.slice(startIndex, endIndex);
  //   }
  // };

  // const getPaginationGroup = () => {
  //   let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
  //   return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  // };

  return (
    <div>
      <Row className="strategies-container">
        {fundsFiltered &&
          // getPaginatedData().map((strategy, i) => {
          fundsFiltered.map((strategy, i) => {
            
            return (
              <Col lg="12" key={strategy.strategyAddress}>
              {
                  isManagerSide ?
                    <StrategyListCardManager strategy={strategy} disableStrategies={disableStrategies}
                      onlyShowDesposited={onlyShowDesposited} setStrategyData={setStrategyData}/>
                    :
                    <StrategyListCardUser strategy={strategy} disableStrategies={disableStrategies}
                      onlyShowDesposited={onlyShowDesposited}  setStrategyData={setStrategyData}/>
              }
              </Col>
            );
          })}
      </Row>
      {/* {data &&
        <>
          <Row className="justify-content-center mt-5 ml-0 mr-0">
            <nav aria-label="Page navigation example">

              <Pagination
                className="pagination pagination-success"
                listClassName="pagination-warning"
              >

                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === 1 ? 'violet' : 'theme-primary'
                      }`}
                    onClick={() => setCurrentPage(1)}
                  >
                    <span aria-hidden={true}>
                      <FirstPageIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === 1 ? 'violet' : 'theme-primary'
                      }`}
                    onClick={goToPreviousPage}
                  >
                    <span aria-hidden={true}>
                      <NavigateBeforeIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                {getPaginationGroup().map((item, index) => (
                  <PaginationItem key={index} active={currentPage === item}>
                    <PaginationLink onClick={changePage}>
                      {item}
                    </PaginationLink>
                  </PaginationItem>
                ))}


                <PaginationItem disabled={currentPage === pages}>
                  <PaginationLink
                    aria-label="Previous"
                    className={`${currentPage === pages ? 'violet' : 'theme-primary'
                      }`}
                    onClick={goToNextPage}
                  >
                    <span aria-hidden={true}>
                      <NavigateNextIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === pages}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === pages ? 'violet' : 'theme-primary'
                      }`}
                    onClick={() => setCurrentPage(pages)}
                  >
                    <span aria-hidden={true}>
                      <LastPageIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Row>
        </>
      } */}
    </div>
  );
};

export default StrategyListView;
