module.exports = {

  PANCAKESWAP_V2_FACTORY_ADDR: process.env.REACT_APP_PANCAKESWAP_V2_FACTORY_ADDRESS,
  PANCAKESWAP_V2_ROUTER_ADDR: process.env.REACT_APP_PANCAKESWAP_V2_ROUTER_ADDRESS,
  PANCAKESWAP_V2_INIT_HASH: process.env.REACT_APP_PANCAKESWAP_V2_INIT_HASH,
  WAULTSWAP_V2_ROUTER_ADDR: process.env.REACT_APP_WAULTSWAP_V2_ROUTER_ADDRESS,
  WAULTSWAP_V2_FACTORY_ADDR: process.env.REACT_APP_WAULTSWAP_V2_FACTORY_ADDRESS,
  WAULTSWAP_V2_INIT_HASH: process.env.REACT_APP_WAULTSWAP_V2_INIT_HASH,

  JSON_RPC_PROVIDER_URL: process.env.REACT_APP_JSON_RPC_PROVIDER_URL,
  API_URL: process.env.REACT_APP_API_URL,
  CHAIN_ID: process.env.REACT_APP_CHAIN_ID,
  TRX_URL: process.env.REACT_APP_TRX_URL,
  TETHERTOKEN_ADDR: process.env.REACT_APP_TETHER_TOKEN_CONTRACT_ADDRESS,
  WBNB_ADDR :  process.env.REACT_APP_WBNB_CONTARCT_ADDRESS,
  IVNTOKEN_ADDR: process.env.REACT_APP_IVN_TOKEN_CONTRACT_ADDRESS,

  //1) pancake swap type fund
  FUND_FACTORY_PANCAKESWAP_ADDR: process.env.REACT_APP_FUND_FACTORY_PANCAKESWAP_ADDRESS, 
  ROUTER_PANCAKESWAP_ADDR: process.env.REACT_APP_ROUTER_PANCAKESWAP_ADDRESS,

  //2) wault swap type fund
  FUND_FACTORY_WAULTSWAP_ADDR: process.env.REACT_APP_FUND_FACTORY_WAULTSWAP_ADDRESS,
  ROUTER_WAULTSWAP_ADDR: process.env.REACT_APP_ROUTER_WAULTSWAP_ADDRESS,

  //3) wault swap type fund with defi strategies
  FUND_FACTORY_DEFI_ADDR:  process.env.REACT_APP_FUND_FACTORY_DEFI_ADDRESS, //same as router
  ROUTER_DEFI_ADDR : process.env.REACT_APP_ROUTER_DEFI_ADDRESS,

  FARMING_ADDRESS: process.env.REACT_APP_FARMING_ADDRESS,
  FARMING_USERS_ADDRESS: process.env.REACT_APP_FARMING_USERS_ADDRESS,
 
  INVESTIN_MULTI_CALL: process.env.REACT_APP_INVESTIN_MULTI_CALL_ADDRESS,
  INVESTIN_BALANCE_CHECKER: process.env.REACT_APP_INVESTIN_BALANCE_CHECKER_ADDRESS

};

// API_URL: 'http://localhost:8000'
// // IVNToken - 0xD3953702293b4Ec16a3Bc539732be3C245dE5a4B
// FundFactory - 0xDFe6ffA239cE3BadAb5435BAC1345C5215a8eDC8
// Router - 0x6140ce05d8731738133DE6b5891D8eC1E1Ea3232
// Fund - 0x9dD05D54964796B0B53486ABc97f57563BdcffA1
