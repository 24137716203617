const SET_CHAIN_ID = 'SET_CHAIN_ID';

const chainIdReducer = (state = {}, action) => {
  switch (action.type) {
    case SET_CHAIN_ID:
      return {
        chainId: action.chainId,
      };
    default:
      return state;
  }
};

export default chainIdReducer;
