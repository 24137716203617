import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { withStyles } from '@material-ui/core/styles';
import MaterialTable from 'material-table';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
  Button, Card,
  CardBody, CardHeader,
  CardTitle,
  Col,
  Input, Row,
  Spinner, Table
} from 'reactstrap';
// import BigNumber from 'bignumber.js';
import DashboardCard from './DashboardViews/DashboardCard';
import CustomButton from '../components/button';

import { ReactComponent as CrossIcon } from '../assets/img/cross.svg';
import { ReactComponent as TickIcon } from '../assets/img/tick.svg';
import FundContract from '../contracts/Fund.json';
import TetherTokenA from '../contracts/TetherToken.json';
import { FUND_FACTORY_DEFI_ADDR } from '../utils/constants';
import { getDexData } from '../utils/fetchDexData';
import getAssetsWithBalances, { getFundTokenWhitelist, getFundUnapproveTokenList } from '../utils/getContractAssets';
import { roundDownTo6Decimals } from '../utils/helperFunctions';
import { sendTrxNotify } from '../utils/notify';
import AssetDropdown from './TradeViews/AssetDropdown';
import PercentButtons from './TradeViews/PercentButtons';
import { routerToDexMapping } from '../utils/routerToDexRouterMapping';
import { getRouterAddress } from '../utils/getAbi';

const GreenCheckbox = withStyles({
  root: {
    color: "#b441d4",
    '&$checked': {
      color: "#b441d4",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const Dashboard = () => {
  const history = useHistory();

  const web3 = useSelector((state) => state.web3Reducer.web3);
  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );
  const address = useSelector((state) => state.addressReducer.address);
  const factoryAddress = useSelector((state) => state.factoryAddressReducer.factoryAddress);
  const routerAddress = getRouterAddress(factoryAddress);

  const [fundInstance, setFundInstance] = useState({});
  const [assets, setAssets] = useState([])
  const [selectedFirstToken, setSelectedFirstToken] = useState('BUSD')
  const [firstTokenBuyAmount, setFirstTokenBuyAmount] = useState(0);
  const [selectedSecondToken, setSelectedSecondToken] = useState('')
  const [secondTokenBuyAmount, setSecondTokenBuyAmount] = useState(0);
  const [path, setPath] = useState([])
  const [amountOutMin, setAmountOutMin] = useState(0);
  const [performance, setPerformance] = useState(0);
  const [approve, setApprove] = useState(false);
  const [approveToken2, setApproveToken2] = useState(false);
  const [swapLoading, setSwapLoading] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [unApprovedTokens, setUnApprovedTokens] = useState([])
  const [approvedTokens, setApprovedTokens] = useState([]);
  const [loading, setLoading] = useState(false)
  const [aum, setAum] = useState(0);
  const [amountInRouter, setAmountInRouter] = useState(0);
  const [transfering, setTransfering] = useState(false);
   const [priceImpact, setPriceImpact] = useState(0);

  useEffect(() => {

    if (!web3) {
      alert('No web3 Please connect Wallet');
      history.push('../../manager/dashboard');
      return;
    }
    if (fundAddress && fundAddress === '0x0000000000000000000000000000000000000000') {
      alert('please create a fund to start trading');
      history.push('../../user/dashboard');
      return;
    }
    (async () => {
      setLoading(true);
      if (fundAddress && factoryAddress) {
        const fundContract = new web3.eth.Contract(
          FundContract,
          `${fundAddress}`
        );

        setFundInstance(fundContract);
        let fundDetails = await fundContract.methods.getFundDetails().call();
        //  console.log(`fundDetails :::: `, fundDetails);
        setPerformance((((parseFloat(fundDetails[5]) / 10000) - 1).toFixed(4) * 100).toFixed(2));

        if (factoryAddress === FUND_FACTORY_DEFI_ADDR) {
             setPerformance((((parseFloat(fundDetails[5]) / 100000000) - 1).toFixed(4) * 100).toFixed(2));
            } else {
              setPerformance((((parseFloat(fundDetails[5]) / 10000) - 1).toFixed(4) * 100).toFixed(2));
        }
        
        setAum(parseFloat(`${web3.utils.fromWei(fundDetails[4], 'ether')}`).toFixed(4));
        let amountInRouterV = await fundContract.methods.getAmountInRouter().call();
        amountInRouterV = parseFloat(`${web3.utils.fromWei(amountInRouterV, 'ether')}`).toFixed(2);
        setAmountInRouter(amountInRouterV);

        
        let allTokens = await getAssetsWithBalances(web3, routerAddress, true, fundAddress, false, []);
        // console.log(`allTokens :::: `, allTokens)
        // let approvedTokens = await getAssetsWithBalances(web3, true, fundAddress, false, true);

        await updateUnApprovedTokenList(routerAddress);
        setAssets(allTokens);
        setLoading(false);
      }
    })();
  }, [web3,address, fundAddress,factoryAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  const updateUnApprovedTokenList = async (routerAddress) => {
    let unApprovedTokensList = await getFundUnapproveTokenList(web3, fundAddress, routerAddress);// updated after v4
    let approvedTokensList = await getFundTokenWhitelist(web3, fundAddress,routerAddress);
    approvedTokensList = await getAssetsWithBalances(web3,routerAddress, true, fundAddress, true, approvedTokensList);
    setApprovedTokens(approvedTokensList)
    if (unApprovedTokensList.length !== 0) {
      unApprovedTokensList = await getAssetsWithBalances(web3,routerAddress, false, fundAddress, false, unApprovedTokensList);
      // console.log(`approvedTokensList : `, approvedTokensList)
      unApprovedTokensList = await unApprovedTokensList.map(token => ({ ...token, isChecked: false }))
      setUnApprovedTokens(unApprovedTokensList)
    } else {
      setUnApprovedTokens([])
    }
  }


  const updateBalancesafterswap = async (token1Address, token2Address) => {

    let assetsTemp = assets;
    const token1Instance = new web3.eth.Contract(
      TetherTokenA,
      `${token1Address}`
    );
    let balance1 = 0;
    let symbol1 = 'BTC'

    try {
      balance1 = await token1Instance.methods.balanceOf(fundAddress).call();
      symbol1 = await token1Instance.methods.symbol().call();
    } catch (error) {
      console.error("updateBalancesafterswap1 error:", error);
      return;
    }
    let obj1 = { tokenAddress: token1Address, symbol: symbol1, balance: web3.utils.fromWei(`${balance1}`, 'ether') };
    // console.log("obj1:", obj1);
    // replace the new object 
    assetsTemp = assetsTemp.map(obj => {
      if (obj1.tokenAddress === obj.tokenAddress) {
        return obj1;
      }
      return obj;
    });

    const token2Instance = new web3.eth.Contract(
      TetherTokenA,
      `${token2Address}`
    );
    try {

      const balance2 = await token2Instance.methods.balanceOf(fundAddress).call();
      const symbol2 = await token2Instance.methods.symbol().call();
      let obj2 = { tokenAddress: token2Address, symbol: symbol2, balance: web3.utils.fromWei(`${balance2}`, 'ether') };
      // replace new object
      assetsTemp = assetsTemp.map(obj => {
        if (obj2.tokenAddress === obj.tokenAddress) {
          return obj2;
        }
        return obj;
      });
      setAssets(assetsTemp);

    } catch (error) {
      console.error("updateBalancesafterswap2 error:", error);
    }

  }

  const [fetchingAmount, setFetchingAmount] = useState(false);

  useEffect(() => {
    const token1 = assets.find((a) => a.symbol === selectedFirstToken)
    const token2 = assets.find((a) => a.symbol === selectedSecondToken)
    if (token1 && token2 && firstTokenBuyAmount && firstTokenBuyAmount !== (0 || '')) {
      setFetchingAmount(true);
      (async () => {
        try {
          const obj = await getDexData(web3, token1?.tokenAddress, `${firstTokenBuyAmount}`, token2?.tokenAddress, routerAddress);
          const amount = obj.amount;
          // console.log("amount : ", amount)
          setPath(obj.pathAddresses);
          // console.log("obj.pathAddresses : ", obj.pathAddresses);
          setSecondTokenBuyAmount((amount));
          setAmountOutMin(web3.utils.toWei((amount))); ///IMP
          setPriceImpact(parseFloat(obj.priceImpact).toFixed(2));
        } catch (error) {
          console.error("firstTokenBuyAmount hook error:", error);
        } finally {
          setFetchingAmount(false)
        }
      })()
    }
  }, [firstTokenBuyAmount]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (web3 && selectedFirstToken && assets.length !== 0) {
        const asset = assets.find((a) => a.symbol === selectedFirstToken);
        console.log("selected token asset : ", asset)
        if (!asset) return
        const tokenInstance = new web3.eth.Contract(
          TetherTokenA,
          `${asset?.tokenAddress}`
        );
        // console.log("selectedFirstToken routerToDexMapping(routerAddress) :",routerToDexMapping(routerAddress))
        try {
          let allowance = await tokenInstance.methods.allowance(fundAddress, routerToDexMapping(routerAddress)).call(); //fund.address
          // console.log("allowance :", allowance)

          if (allowance && allowance !== '0') {
            //already allowance added
            setApprove(false)
          } else {
            setApprove(true)
          }
        } catch (error) {
          console.error("check allowance error:", error);
        }

      }
    })()
  }, [selectedFirstToken, assets,fundAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    (async () => {
      if (web3 && selectedSecondToken && assets.length !== 0) {
        const asset = assets.find((a) => a.symbol === selectedSecondToken);
        // console.log("asset : ", asset)
        if (!asset) return
        const tokenInstance = new web3.eth.Contract(
          TetherTokenA,
          `${asset?.tokenAddress}`
        );
        // console.log("selectedSecondToken routerToDexMapping(routerAddress) :",routerToDexMapping(routerAddress))

        try {
          let allowance = await tokenInstance.methods.allowance(fundAddress, routerToDexMapping(routerAddress)).call(); //fund.address
          // console.log("allowance :", allowance)

          if (allowance && allowance !== '0') {
            //already allowance added
            setApproveToken2(false)
          } else {
            setApproveToken2(true)
          }
        } catch (error) {
          console.error("check allowance error:", error);
        }

      }
    })()
  }, [selectedSecondToken, assets,fundAddress]) // eslint-disable-line react-hooks/exhaustive-deps

  const approveTokens = async (tokenAddressList) => {

    try {
      const result = fundInstance.methods.addToken(tokenAddressList);
      await sendTrxNotify(result, address, `${tokenAddressList.length === 1 ? 'Approved token' : 'Approved tokens'}`);
    } catch (error) {
      console.error("error in approve :", error);
      return;
    }
    updateUnApprovedTokenList(routerAddress);
  }

  const handleApprove = async () => {
    let assetList = [];

    if (approve) {
      const asset = assets.find((a) => a.symbol === selectedFirstToken);
      assetList.push(asset?.tokenAddress)
    }

    if (approveToken2) {
      const asset = assets.find((a) => a.symbol === selectedSecondToken);
      assetList.push(asset?.tokenAddress)
    }
    setApproveLoading(true);
    await approveTokens(assetList);
    setApprove(false);
    setApproveToken2(false);
    setApproveLoading(false);
  }


  const handleSwap = async () => {
    setSwapLoading(true);
    // console.log(`selectedFirstToken`, selectedFirstToken)
    // console.log(`firstTokenBuyAmount`, firstTokenBuyAmount)
    // console.log(`selectedSecondToken`, selectedSecondToken)
    // console.log(`secondTokenBuyAmount`, secondTokenBuyAmount)

    const deadline = Math.floor(((Date.now() / 1000) + 60 * 20))

    // console.log(`fundContract : `, fundInstance)
    const token1Address = assets.find((a) => a.symbol === selectedFirstToken)?.tokenAddress;
    const token2Address = assets.find((a) => a.symbol === selectedSecondToken)?.tokenAddress;

    try {

      // console.log("path of addresses[]:", path);
      
      // let string_bal = assets.find((a) => a.symbol == selectedFirstToken)?.balance;
      // console.log("string_bal:",string_bal)
      console.log("calling swap with:",
         web3.utils.toWei((`${firstTokenBuyAmount}`)),
          amountOutMin,
          path,
          deadline
      );

      const result = fundInstance.methods
        .swap(
          web3.utils.toWei((`${firstTokenBuyAmount}`)),
          amountOutMin,
          path,
          deadline
        );
      await sendTrxNotify(result, address, `${selectedFirstToken}->${selectedSecondToken} Swap successfull`);
    } catch (error) {
      console.error("error in swap :", error);
      setSwapLoading(false);
      return;
    }
    setSwapLoading(false);
    // update the balances
    updateBalancesafterswap(token1Address, token2Address)
    let approvedTokensList = await getFundTokenWhitelist(web3, fundAddress,routerAddress);
    approvedTokensList = await getAssetsWithBalances(web3,routerAddress, true, fundAddress, true, approvedTokensList);
    setApprovedTokens(approvedTokensList)
  }


  const handleCheckToken = (event) => {
    // console.log(`event.target.value : `, event.target.value)
    unApprovedTokens.forEach(token => {
      if (token.symbol === event.target.value)
        token.isChecked = event.target.checked
    })
    // console.log(`handleCheckToken unApprovedTokens : `, unApprovedTokens)
    setUnApprovedTokens(unApprovedTokens);
  }

  const handleApproveMultiTokens = async ({ allTokens }) => {
    const approveTokenList =
      allTokens ? unApprovedTokens.map((t) => t.tokenAddress)
        : unApprovedTokens.filter((token) => token.isChecked === true).map((t) => t.tokenAddress);
    // console.log(`approveTokenList : `, approveTokenList)
    await approveTokens(approveTokenList);
    // console.log("unn : ", unApprovedTokens);
  }

  const handleTransferToVault = async () => {
    setTransfering(true);
    try {
      let trx = fundInstance.methods.transferToVault();
      await sendTrxNotify(trx, address, "Transfered to Vault done sucessfully..");
      setTransfering(false);
    } catch (error) {
      setTransfering(false);
      console.error('handleTransferToVault: ', error);
    }
    //refreshing amt and assets
    try {
      let amountInRouterV = await fundInstance.methods.getAmountInRouter().call();
      setAmountInRouter(web3.utils.fromWei(amountInRouterV, 'ether'));
    } catch (error) {
      console.error('getAmountInRouter error: ', error);
    }
    try {
      let approvedTokensList = await getFundTokenWhitelist(web3, fundAddress,routerAddress);
      approvedTokensList = await getAssetsWithBalances(web3,routerAddress, true, fundAddress, true, approvedTokensList);
      setApprovedTokens(approvedTokensList)
    } catch (error) {
      console.error('getAmountInRouter approvedTokensList error: ', error);
    }
  };

  return (
    <>
      <div className="content">
        {loading && (
          <div
            id="overlay"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              bottom: '0',
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: '9',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            <Spinner
              style={{
                position: 'absolute',
                top: '45%',
                left: '40%',
                color: 'aliceblue'
              }}
            ></Spinner>
          </div>
        )}
        <Row>
          <Col lg="4">
            <DashboardCard
              heading="ASSETS UNDER MANAGEMENT"
              content={`${aum} BUSD`}
            ></DashboardCard>
          </Col>
          <Col lg="4" >
            <DashboardCard
              style={{ marginBottom: "0px" }}
              heading="TRANSFERABLE FUNDS"
              content={`${amountInRouter} BUSD`}
              tooltiptext="Transfer the invested amount to fund vault for trading and collect the management fee. Make sure to transfer the funds to vault to start trading!"
            >
              <CustomButton
                className={(amountInRouter > 0) ? 'nav-pills-info pulse-button ' : ''}
                disabled={amountInRouter === 0}
                onClick={handleTransferToVault}
                loading={transfering}
                text="Transfer" />
            </DashboardCard>
          </Col>
          <Col lg="4">
            <DashboardCard heading="PERFORMANCE" content={`${performance} %`}></DashboardCard>
          </Col>
        </Row>
        <Row>
          <Col lg="7" md="12">
            <Card style={{ padding: '10px' }}>
              <Row style={{ marginTop: '10px', textAlign: "center" }}>
                <Col lg="6" md="12">
                  <AssetDropdown
                    key={`${assets.length} ${selectedFirstToken}`}
                    items={assets.map((a) => a.symbol)}
                    initialValue={selectedFirstToken}
                    callback={(value) => { setSelectedFirstToken(value); setFirstTokenBuyAmount(0); setSecondTokenBuyAmount(0); }} />
                  <Input
                    type="number"
                    style={{ textAlign: 'center', border: '1px solid grey' }}
                    placeholder="Enter an Amount"
                    value={firstTokenBuyAmount ? firstTokenBuyAmount : ''}
                    onChange={(e) => setFirstTokenBuyAmount(e.target.value)} />
                  <PercentButtons
                    // **IMP need to remove ParseFloat and change to BigNumber
                    // but PercentageNumber internally accepts only Number
                    // current solution : not parsing to number only returning back same string on 100% 
                    value={(assets.find((a) => a.symbol === selectedFirstToken)?.balance)}
                    callback={setFirstTokenBuyAmount}
                    trade />
                  <p style={{ marginTop: '10px', textAlign: "center" }}>
                    {/* add spinner */}
                    Amount Available : {roundDownTo6Decimals(assets.find((a) => a.symbol === selectedFirstToken)?.balance)} {selectedFirstToken}
                  </p>
                </Col>
                <Col lg="6" md="12">
                  <AssetDropdown
                    key={assets.length}
                    items={assets.map((a) => a.symbol)}
                    initialValue={'Select a coin'}
                    callback={(value) => { setSelectedSecondToken(value); setSecondTokenBuyAmount(0); setFirstTokenBuyAmount(0); }} />
                  <Input
                    disabled
                    type="number"
                    style={{ textAlign: 'center', border: '1px solid grey', color: 'white' }}
                    placeholder="Enter an Amount"
                    value={secondTokenBuyAmount ? secondTokenBuyAmount : ''}
                    onChange={(e) => setSecondTokenBuyAmount(e.target.value)}
                  />
                  {/* <PercentButtons
                    value={assets.find((a) => a.symbol == selectedSecondToken)?.balance}
                    callback={setsecondTokenBuyAmount} /> */}
                  <p style={{ marginTop: '10px', textAlign: "center" }}>
                    Amount Available : {roundDownTo6Decimals(assets.find((a) => a.symbol === selectedSecondToken)?.balance)} {selectedSecondToken}
                  </p>
                  {
                    selectedSecondToken && amountOutMin && firstTokenBuyAmount &&
                    <p style={{ marginTop: '10px', textAlign: "center" ,  color : (priceImpact>3) ? 'red' : 'white'}}>
                      Price Impact : {priceImpact<0.01 ? "<0.01" : priceImpact}%
                    </p>
                  }
                </Col>
              </Row>

              <Row className="d-flex justify-content-center">
                {
                  (approve || approveToken2) ?
                    <button className="btn btn-round btn-submit" onClick={handleApprove} disabled={approveLoading}>

                      {
                        approveLoading &&
                        <>Approving ...<Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /></>
                      }
                      {
                        !approveLoading &&
                        <>
                          {
                            (approve && approveToken2)
                              ?
                              `APPROVE BOTH ${selectedFirstToken} &  ${selectedSecondToken}`
                              :
                              <>
                                {
                                  approve && <>APPROVE {selectedFirstToken}</>
                                }
                                {approveToken2 && <>APPROVE {selectedSecondToken}</>}
                              </>
                          }
                        </>

                      }

                    </button>
                    :
                   <button className="btn btn-round btn-submit" onClick={handleSwap} disabled={swapLoading || fetchingAmount || priceImpact>10}>
                      {swapLoading &&
                        <>Swapping...<Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        </>}
                      {
                        !fetchingAmount && !swapLoading && priceImpact<=10 && `SWAP ${selectedFirstToken} > ${selectedSecondToken}`
                      }
                      {
                        !fetchingAmount && !swapLoading && priceImpact>10 && `PRICE IMPACT TOO HIGH (${priceImpact}%) `
                      }
                      {fetchingAmount && (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      )}
                    </button>
                }

              </Row>
            </Card>
            <Card>
              <Row>
                <Col lg="12" md="12">
                  <Card>
                    <Row>
                      <Col lg="12" md="12">
                        <Card>
                          <CardBody className="trade-table">
                            {
                              approvedTokens && approvedTokens.length > 0 &&
                              <MaterialTable
                                key="token_balance_updates"
                                columns={[
                                  { title: 'Token', field: 'symbol' },
                                  { title: 'Quantity', field: 'inusd' },
                                  {
                                    title: 'Amount', field: 'balance', defaultSort: 'desc'
                                  },
                                  { title: 'Action', field: 'action' },
                                ]}
                                data={approvedTokens.length > 0 ? approvedTokens.map(function (el) {
                                  var o = Object.assign({}, el);
                                  o.action = <Button className="btn-main-theme-secondary trade-theme" onClick={() => { setSelectedFirstToken(el.symbol) }}>select</Button>;
                                  o.inusd = parseFloat((parseFloat(el.balance) / parseFloat(el.price)).toFixed(6));
                                  o.balance = parseFloat(parseFloat(el.balance).toFixed(6));
                                  return o;
                                }) :
                                  []
                                }
                                title="Assets"
                                options={{
                                  rowStyle: {
                                    backgroundColor: '#151839',
                                  },
                                  sorting: true
                                }}
                              />
                            }
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Card>

                </Col>
              </Row>
            </Card>
          </Col>

          <Col lg="5" md="12">

            <Card>
              <CardHeader>
                <CardTitle tag="h4">Token Whitelist</CardTitle>
              </CardHeader>
              <CardBody id="style-7" style={{ maxHeight: "65vh", overflow: "auto" }}>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Asset</th>
                      <th>Approved</th>
                    </tr>
                  </thead>
                  <tbody>

                    {unApprovedTokens &&
                      unApprovedTokens.map((unApprovedToken, i) => {
                        return (
                          <>


                            <tr key={unApprovedToken.symbol}>
                              <td>
                                <FormControlLabel
                                  key={i}
                                  style={{ display: "block" }}
                                  control={<GreenCheckbox value={unApprovedToken.symbol} name="checkedG" />}
                                  label={unApprovedToken.symbol}
                                  value={unApprovedToken.isChecked}
                                  onClick={handleCheckToken}
                                />

                              </td>
                              <td><CrossIcon /></td>
                            </tr>
                          </>
                        )
                      })
                    }
                    {approvedTokens &&
                      approvedTokens.map((asset) => {
                        return (
                          <tr key={asset.symbol}>
                            <td>
                              {asset.symbol}

                            </td>
                            <td><TickIcon /></td>

                          </tr>
                        );
                      })}
                  </tbody>
                </Table>

              </CardBody>
              <Row style={{ marginTop: '5px' }} className="justify-content-center">
                <button className="btn btn-round btn-submit approve-button"
                  onClick={() => handleApproveMultiTokens({ allTokens: false })}
                >
                  Approve selected
                      </button>
                <button className="btn btn-round btn-submit approve-button"
                  onClick={() => handleApproveMultiTokens({ allTokens: true })}
                >
                  Approve all
                      </button>
              </Row>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Dashboard;
