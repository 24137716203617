const SET_PRICES = 'SET_PRICES';

const pricesReducer = (state = false, action) => {
  const prices = action.prices;
  switch (action.type) {
    case SET_PRICES:
      return {
        prices,
      };
    default:
      return state;
  }
};

export default pricesReducer;