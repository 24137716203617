import React, { useEffect, useState } from 'react';

// reactstrap components
import {
  Card,
  Button,
  CardBody,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
  Row,
  Col,
} from 'reactstrap';

import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';

const Market = (props) => {



  const [stateData, setStateData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [displayUsers, setDisplayUsers] = useState();

  useEffect(() => {
    const handleChange = (change) => {
      if (change === -1) {
        setCurrentPage(currentPage - 1);
      } else if (change === 1) {
        setCurrentPage(currentPage + 1);
      }
    };

    handleChange(0);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    displayData();
  }, [stateData]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
    fetchData();
  }, [currentPage]); // eslint-disable-line react-hooks/exhaustive-deps


  const handleChange = (change) => {
    if (change === -1) {
      setCurrentPage(currentPage - 1);
    } else if (change === 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const fetchData = async () => {
    try {
      let pageCount = 10;
      const response = await fetch(
        `https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&order=market_cap_desc&per_page=${pageCount}&page=${currentPage}&sparkline=false&price_change_percentage=1h%2C24h%2C7d`
      );
      if (!response.ok) {
        throw Error(response.statusText);
      }
      const json = await response.json();
      setStateData(json);
    } catch (error) {
      console.error("Market page data fetch error:",error);
    }
  };

  const displayData = () => {
    let displayUsers = null;
    if (stateData && stateData.length) {
      displayUsers = stateData.map((coin) => (
        <tr key={coin && coin.name}>
          <td className="text-right">
            {/* <div className="photo">
              <img alt="" height="30" width="30" src={coin.image} />
            </div> */}
          </td>

          <td className="text-center">
            <div>
              <div>
                <div className="photo">
                  <img alt="" height="30" width="30" src={coin.image} />
                </div>
                <div className="mt-1">{coin && coin.name}</div>
              </div>
            </div>
          </td>
          <td className="text-center">${coin.current_price.toFixed(4)}</td>
          <td className="text-center">{coin.total_volume}</td>
          <td className="text-center">
            <Button
              className={
                coin.price_change_percentage_1h_in_currency.toFixed(2) > 0
                  ? 'btn-dark-cyan'
                  : 'btn-dark-cyan-danger'
              }
              size="sm"
            >
              {coin.price_change_percentage_1h_in_currency.toFixed(2)}%
            </Button>
          </td>
          <td className="text-center">
            <Button
              className={
                coin.price_change_percentage_24h_in_currency.toFixed(2) > 0
                  ? 'btn-dark-cyan'
                  : 'btn-dark-cyan-danger'
              }
              size="sm"
            >
              {coin.price_change_percentage_24h_in_currency.toFixed(2)}%
            </Button>
          </td>
          <td className="text-center">
            <Button
              className={
                coin.price_change_percentage_7d_in_currency.toFixed(2) > 0
                  ? 'btn-dark-cyan'
                  : 'btn-dark-cyan-danger'
              }
              size="sm"
            >
              {coin.price_change_percentage_7d_in_currency.toFixed(2)}%
            </Button>
          </td>
        </tr>
      ));
    } else {
      displayUsers = null;
    }

    setDisplayUsers(displayUsers);
  };

  return (
    <>
      <div className="content">
        <div className="justify-content-left">
          <h1 className="ml-2 shadow-text mt-4">Market </h1>
        </div>

        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  {!displayUsers && (
                    <p
                      style={{
                        fontSize: '22px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        color: 'red',
                      }}
                    >
                      {' '}
                      No data Available{' '}
                    </p>
                  )}

                  {displayUsers && (
                    <Table className="tablesorter" responsive size="md">
                      <thead className="text-primary">
                        <tr>
                          <th className="text-center"> </th>
                          <th className="text-center"> Assets</th>
                          <th className="text-center"> Price </th>
                          <th className="text-center"> 24H Volume </th>
                          <th className="text-center"> 24H Change</th>
                          <th className="text-center"> 7D Change</th>
                          <th className="text-center"> 1M Change</th>
                        </tr>
                      </thead>
                      <tbody>{displayUsers}</tbody>
                    </Table>
                  )}
                </Row>
                <Row className="justify-content-center mt-5 ml-0 mr-0">
                  <nav aria-label="Page navigation example">
                    <Pagination
                      className="pagination pagination-success"
                      listClassName="pagination-warning"
                    >
                      <PaginationItem>
                        <PaginationLink
                          aria-label="Previous"
                          className={`${
                            currentPage === 1 ? 'violet' : 'theme-primary'
                          }`}
                          onClick={() => handleChange(-1)}
                          disabled={!displayUsers || currentPage < 2}
                        >
                          <span aria-hidden={true}>
                            <SkipPreviousRoundedIcon className="tim-icons icon-double-left" />
                            {/* <i
                              aria-hidden={true}
                              className="tim-icons icon-double-left"
                            /> */}
                          </span>
                        </PaginationLink>
                      </PaginationItem>

                      <PaginationItem>
                        <PaginationLink
                          aria-label="Next"
                          className={`${
                            currentPage > 1 && currentPage < 10
                              ? 'violet'
                              : 'theme-primary'
                          }`}
                          onClick={() => handleChange(1)}
                          disabled={!displayUsers || currentPage > 10}
                        >
                          <span aria-hidden={true}>
                            <SkipNextRoundedIcon className="tim-icons icon-double-right" />
                            {/* <i
                              aria-hidden={true}
                              className="tim-icons icon-double-right"
                            /> */}
                          </span>
                        </PaginationLink>
                      </PaginationItem>
                    </Pagination>
                  </nav>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Market;
