import React from 'react';
import Switch from '@material-ui/core/Switch';
import PropTypes from 'prop-types';

export default function ToggleButton({checked, handleChange}) {
  return (
    <div>
      <Switch
        checked={checked}
        onChange={handleChange}
        color="primary"
        inputProps={{ 'aria-label': 'primary checkbox' }}
      />
    </div>
  );
}

ToggleButton.propTypes = {
  checked: PropTypes.bool,
  handleChange: PropTypes.func
}

