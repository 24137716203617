const SET_DEPOSITING = 'SET_DEPOSITING';

const despositingReducer = (state = {}, action) => {
  const despositing = action.despositing;
  switch (action.type) {
    case SET_DEPOSITING:
      return {
        despositing,
      };
    default:
      return state;
  }
};

export default despositingReducer;
