import { Grid, IconButton } from '@material-ui/core';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import FundAbi from '../contracts/Fund.json';
import FundDefiAbi from '../contracts/FundDefi.json';
import { sendTrxNotify } from '../utils/notify';
import HelpIcon from '@material-ui/icons/Help';
import { Link } from 'react-router-dom';

import {ROUTER_DEFI_ADDR, ROUTER_PANCAKESWAP_ADDR, ROUTER_WAULTSWAP_ADDR} from './../utils/constants'

import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  Table,
  Tooltip,
  Button
} from 'reactstrap';
import { fetchInvestments } from '../utils/fetchInvestmentsHelper';
import WithdrawInvestmentModal from '../components/modals/WithdrawInvestmentModal';
import getAssetsWithBalances from '../utils/getContractAssets';
// import { getRouterAddress } from '../utils/getAbi';


const Investments = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const web3 = useSelector((state) => state.web3Reducer.web3);

  const investments = useSelector(
    (state) => state.investmentsReducer.investments
  );


  const balanceUSDT = useSelector((state) => state.usdtBalanceReducer.balance);

  const address = useSelector((state) => state.addressReducer.address);

  // const factoryAddress = useSelector((state) => state.factoryAddressReducer.factoryAddress);

  // const [investments, setInvestments] = useState([])

  const [assets, setAssets] = useState([]);
  const [defiAssets, setDefiAssets] = useState([]);


  const [investedamounttooltipOpen, setInvestedamounttooltipOpen] = useState(false);
  const [currentvaluetooltipOpen, setCurrentvalueTooltipOpen] = useState(false);
  const [roitooltipOpen, setRoiTooltipOpen] = useState(false);
  const [tokenidtooltipOpen, setTokenidTooltipOpen] = useState(false);
  const [statustooltipOpen, setStatusTooltipOpen] = useState(false);


  // eslint-disable-next-line
  const toggleInvestedAmountToolTip = () => setInvestedamounttooltipOpen(!investedamounttooltipOpen);
  const toggleCurrentValueToolTip = () => setCurrentvalueTooltipOpen(!currentvaluetooltipOpen);
  const toggleRoiToolTip = () => setRoiTooltipOpen(!roitooltipOpen);
  const toggleTokenidToolTip = () => setTokenidTooltipOpen(!tokenidtooltipOpen);
  const toggleStatusToolTip = () => setStatusTooltipOpen(!statustooltipOpen);

  useEffect(() => {
    if (!web3) {
      console.log('No web3');
      alert('Please connect Wallet First to View Investments');
      history.push('../../user/dashboard');
    } else {
      fetchInvestments(web3, dispatch);
      (async () => {
        // get whitelisted tokens and address
      let assets = await getAssetsWithBalances(web3,ROUTER_WAULTSWAP_ADDR, false);
        // console.log("assests investements:", assets);
        // <option key={asset.tokenAddress} value={asset.symbol}>{asset.symbol}</option>
        setAssets(assets);
        let DefiAssetsNew = await getAssetsWithBalances(web3, ROUTER_DEFI_ADDR, false);
        // DefiAssetsNew.unshift({ tokenAddress: WBNB_ADDR, symbol: 'WBNB' });
        // DefiAssetsNew.unshift({ tokenAddress: TETHERTOKEN_ADDR, symbol: 'BUSD' });
        setDefiAssets(DefiAssetsNew);
      })()

    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps



  const percentageReturns = (amount, currentAmount, status) => {
    if (status !== 'inActive') amount = (amount * 0.98).toFixed(2);
    return parseFloat(((currentAmount - amount) / (amount)) * 100).toFixed(2);
  }

  const handleEndInvestement = async (fundAddress, tokenId, withdrawableAmount, enteredAmount, selectedTokenAddress,routerAddress) => {
    let FundInstance
    if (routerAddress === ROUTER_DEFI_ADDR) {
        FundInstance  = new web3.eth.Contract(FundDefiAbi, fundAddress);
    } else {
        FundInstance  = new web3.eth.Contract(FundAbi, fundAddress);
      }
    console.log("calling Withdraw with :tokenId, enteredAmount :selectedTokenAddress:",tokenId, enteredAmount, selectedTokenAddress);

    let trs;
    try {
        console.log("withdrawableAmount, enteredAmount: ", withdrawableAmount, enteredAmount);
      if (enteredAmount >= withdrawableAmount) {
        trs = FundInstance.methods.withdraw(tokenId, web3.utils.toWei(`${enteredAmount * 10}`), selectedTokenAddress);
      } else {
        trs = FundInstance.methods.withdraw(tokenId, web3.utils.toWei(`${enteredAmount}`), selectedTokenAddress);
      }
      await sendTrxNotify(trs, address, `Investment with tokenid ${tokenId} withdrawn succesfully `);
    } catch (error) {
      console.error("handleEndInvestement:", error.message);
      return;
    }
    fetchInvestments(web3, dispatch);
  }

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <Grid container justifyContent="space-between">
                <Grid item>
                  <CardTitle tag="h3">Active Investments</CardTitle>
                </Grid>
                <Grid item>
                  <IconButton
                    size="small"
                    onClick={() => fetchInvestments(web3, dispatch)}
                  >
                    <AutorenewSharpIcon
                      color="primary"
                      style={{ margin: '5px' }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </CardHeader>
            <CardBody>
              <Table
                className="tablesorter"
                responsive
                width="100%"
                style={{ overflow: 'hidden !important', textAlign: 'center' }}
              >
                {/* <col style={{ width: "25%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "15%" }} /> */}

                <thead className="text-primary">
                  <tr>
                    <th style={{ width: "15%" }}>Fund</th>
                    <th style={{ width: "10%" }}>Version</th>
                    <th style={{ width: "15%" }}>
                      Invested Amount
                      <span>
                        <HelpIcon
                          className="ml-2"
                          id={`Tooltip-${1}`}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={investedamounttooltipOpen}
                          target={`Tooltip-${1}`}
                          toggle={toggleInvestedAmountToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          Amount Invested in Fund
                        </Tooltip>
                      </span>
                    </th>
                    <th style={{ width: "15%" }}>
                      Current Value
                      <span>
                        <HelpIcon
                          className="ml-2"
                          id={`Tooltip-${2}`}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={currentvaluetooltipOpen}
                          target={`Tooltip-${2}`}
                          toggle={toggleCurrentValueToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          The current valuation of invested amount in the fund. The amount will be less than invested amount due to management fee deductions. 
                        </Tooltip>
                      </span>
                    </th>
                    <th style={{ width: "12%" }}>
                      ROI %
                      <span>
                        <HelpIcon
                          className="ml-2"
                          id={`Tooltip-${3}`}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={roitooltipOpen}
                          target={`Tooltip-${3}`}
                          toggle={toggleRoiToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          Return on Investment %
                        </Tooltip>
                      </span>
                    </th>
                    <th style={{ width: "12%" }}>
                      Token id
                      <span>
                        <HelpIcon
                          className="ml-2"
                          id={`Tooltip-${4}`}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={tokenidtooltipOpen}
                          target={`Tooltip-${4}`}
                          toggle={toggleTokenidToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          The unique token Identifier of the investment. This is derived from the NFT token in your wallet that Investin uses to check details about the investment.
                          Beware loosing access to the wallet holding this token will result in loss of investment.
                        </Tooltip>
                      </span>
                    </th>
                    <th style={{ width: "15%" }}>
                      Status
                      <span>
                        <HelpIcon
                          className="ml-2"
                          id={`Tooltip-${5}`}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={statustooltipOpen}
                          target={`Tooltip-${5}`}
                          toggle={toggleStatusToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          The status indicates if the manager has accepted your investment in their fund or not.
                          If you see the status false for long time please reach out to the manager using details on their fund page.
                        </Tooltip>
                      </span>
                    </th>
                    <th style={{ width: "15%" }}>
                      </th>
                  </tr>
                </thead>
                <tbody>
                  {(investments && investments.length) &&
                    investments.map((i) => {
                      if (i.fundAddress==='' || i.fundAddress === "0x0000000000000000000000000000000000000000")
                      {
                        return <tr key={i.tokenId}></tr>; 
                        // Warning: Each child in a list should have a unique "key" prop
                        // return <></>;
                      }
                      return (
                        <tr key={i.tokenId}>
                          <td>
                          <Link className="link-funds" to={`/${window.location.href.includes('user') ? 'user' : 'manager'}/details/${i.fundAddress}`}>
                              {i.fundName}
                              {/* {`${i.fundAddress.substring(0, 10)}...${(i.fundAddress).substr((i.fundAddress).length - 6)}`} */}
                            </Link>
                          </td>
                          <td>
                            {
                              (i.routerAddress === ROUTER_DEFI_ADDR) ?
                                <b>PS-V2</b>
                                : ((i.routerAddress === ROUTER_PANCAKESWAP_ADDR) ?
                                  <b>PS-V1</b>
                                  :
                                  <b>WS-V1</b>)
                            }
                          </td>
                          <td>{i.amount}</td>
                          <td>{i.currentReturns} </td>
                          <td><Button
                            className={
                              (percentageReturns(i.amount, i.currentReturns)) >= 0
                                ? 'btn-dark-cyan'
                                : 'btn-dark-cyan-danger'
                            }
                            size="sm"
                          >{percentageReturns(i.amount, i.currentReturns, i.status)}</Button></td>
                          <td>{i.tokenId}</td>
                          <td>{(i.status)}</td>
                          <td>
                            { i.routerAddress === ROUTER_DEFI_ADDR
                              ?
                              < WithdrawInvestmentModal
                                  usdtBalance={parseFloat(balanceUSDT)}
                                  amount={parseFloat(i.currentReturns)}
                                  assets={defiAssets}
                                  routerAddress = {i.routerAddress}
                                  inActive={i.status === 'inActive'}
                                  withdrawCallback={async (enteredValue, selectedTokenAddress,routerAddress) => await handleEndInvestement(i.fundAddress, i.tokenId, i.currentReturns, enteredValue, selectedTokenAddress,routerAddress)}
                              />
                               :
                               < WithdrawInvestmentModal
                                  usdtBalance={parseFloat(balanceUSDT)}
                                  amount={parseFloat(i.currentReturns)}
                                  assets={assets}
                                  routerAddress = {i.routerAddress}
                                  inActive={i.status === 'inActive'}
                                  withdrawCallback={async (enteredValue, selectedTokenAddress,routerAddress) => await handleEndInvestement(i.fundAddress, i.tokenId, i.currentReturns, enteredValue, selectedTokenAddress,routerAddress)}
                              />
                            } 
                          </td>
                        </tr>
                      );
                    })
                  }
                </tbody>
              </Table>
              {
                !(investments && investments.length) &&
                <img
                  src={require('../assets/img/no-investments.svg')}
                  alt="No investments"
                  style={{
                    height: "80vh",
                    width: "80vw"
                  }}
                />
              }
            </CardBody>
          </Card>
        </Col>
      </Row>
      {/* <Slideone /> */}
    </div>
  );
};

export default Investments;
