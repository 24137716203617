import React from 'react';
import { Button } from 'reactstrap';

import DialogTitle from '@material-ui/core/DialogTitle';
import Stepper from '../components/Stepper';
import { makeStyles } from "@material-ui/core/styles";

import { Modal, ModalBody } from 'reactstrap';
export default function ResponsiveDialog() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      filter: "blur(3px)"
    },
    textOverlay: {
      position: "fixed",
      top: "50%",
      left: "50%",
      zIndex: +2,
      transform: "translate(-50%, -50%)",
      textAlign: "center",
      color: "white",
      filter: "brightness(2px)"
    },
    notSelectable: {
      userSelect: "none"
    }
  }));

   const classes = useStyles();

  return (
    <div>
      <Button
        size="md"
        block
        onClick={handleClickOpen}
        className="btn-main-theme-secondary"
      >
        Deposit
      </Button>
      <Modal isOpen={open} toggle={handleClose}>
       
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          <h1 className={classes.textOverlay}>Fiat Onboarding Coming Soon... </h1>
        </DialogTitle>
        <ModalBody style={{ marginTop: '-20px' }}
          className={classes.notSelectable && classes.root}
          // className={classes.root}
        >
          <div className="text-center">
            <h5>You need BUSD to start using Investin</h5>{' '}
          </div>

          <Stepper />
        </ModalBody>
      </Modal>
    </div>
  ); 

} // eslint-disable-line react-hooks/exhaustive-deps

