const SET_USDT_BALANCE = 'SET_USDT_BALANCE';

const usdtBalanceReducer = (state = {}, action) => {
  const balance = action.balance;
  switch (action.type) {
    case SET_USDT_BALANCE:
      return {
        balance,
      };
    default:
      return state;
  }
};

export default usdtBalanceReducer;
