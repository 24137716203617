import Web3 from 'web3';
import Torus from '@toruslabs/torus-embed';

export const getWeb3 = () =>
  new Promise(async (resolve, reject) => {
    // Wait for loading completion to avoid race conditions with web3 injection timing.
    // window.addEventListener("load", async () => {
    // Modern dapp browsers...
    if (window.ethereum) {
      const web3 = new Web3(window.ethereum);
      if((await web3.eth.getAccounts()).length === 0 ) {
        return new Error("No web3 provider detected")
      }
      try {
        // Request account access if needed
        await window.ethereum.enable();
        // Acccounts now exposed
        resolve(web3);
      } catch (error) {
        reject(error);
      }
    }
    // Legacy dapp browsers...
    else if (window.web3) {
      // Use Mist/MetaMask's provider.
      const web3 = window.web3;
      console.log('Injected web3 detected.');
      resolve(web3);
    }
    // Fallback to localhost; use dev console port by default...
    else {
      throw Error("No web3 provider detected")
    }
    // });
  });

// export const getWeb3WithProvider = (provider) => {
//   const selectedProvider = getWeb3ProviderConfig(provider);
//   const web3 = new Web3(window.ethereum);
// }

export const getWeb3ProviderConfig = async (provider) => {
  let web3;

  async function createWeb3Obj() {
    switch (provider) {
      case 'MetaMask':
        web3 = new Web3(window.ethereum);
        await window.ethereum.enable();
        console.log('web3 from switch', web3);
        return web3;
      case 'Torus': {
        const torus = new Torus();
        await torus.init({
          buildEnv: 'production',
          network: { host: 'mainnet' },
        });
        await torus.login();

        web3 = new Web3(torus.provider);
        return web3;
      }

      default:
        console.log('something else happened');
        break;
    }
  }
  return await createWeb3Obj();
};
