import React from 'react';
// import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Button } from 'reactstrap';

// import { Grid } from '@material-ui/core';
// import DialogTitle from '@material-ui/core/DialogTitle';
// import { makeStyles } from '@material-ui/core/styles';
// import { Input } from 'reactstrap';
// import WithdrawStepper from './WithdrawStepper';
// import FundA from '../contracts/Fund.json';
// import IVNToken from '../contracts/IVNToken.json';
// import { IVNTOKEN_ADDR } from '../utils/constants';
// import { fetchInvestments } from '../utils/fetchInvestmentsHelper';
// import { Modal, ModalBody } from 'reactstrap';

// const useStyles = makeStyles((theme) => ({
//   button: {
//     display: 'block',
//     marginTop: theme.spacing(2),
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//   },
//   slectStyling: {
//     lineHeight: '1.3',
//     color: '#4055D4',
//     borderColor: '#4055D4',
//     borderRadius: 30,
//     width: '115%',
//   },
//   optionStyling: {
//     borderRadius: 30,
//     fontSize: 18,
//     cursor: 'pointer',
//   },
// }));

export default function ResponsiveDialog(props) {
  // const dispatch = useDispatch();
  const history = useHistory();

  // const web3 = useSelector((state) => state.web3Reducer.web3);
  // const investments = useSelector(
  //   (state) => state.investmentsReducer.investments
  // );
  // const [IVNTokenInstance, setIVNTokenInstance] = useState({});
  // const [FundInstance, setFundInstance] = useState({});
  // const [address, setAddress] = useState('');

  // const [options, setOptions] = useState([]);

  // useEffect(() => {
  //   if (investments) {
  //     let op = investments.map((i) => i.tokenId);
  //     setOptions(op);
  //   }
  // }, [investments]);

  // const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    // setOpen(true);
    history.push('../../user/investments');
  };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  // const classes = useStyles();
  // const [tokenIndex, setTokenIndex] = React.useState('');
  // const [selectedInvestment, setSelectedInvestment] = useState({});
  // /* eslint-disable no-unused-vars */
  // const [openS, setOpenS] = React.useState(false);

  // const handleChangeS = (event) => {
  //   let t = event.target.value;
  //   setTokenIndex(t);
  //   setSelectedInvestment(investments[t]);
  //   (async () => {
  //     const IVNTokenInstance = new web3.eth.Contract(IVNToken, IVNTOKEN_ADDR);
  //     setIVNTokenInstance(IVNTokenInstance);
  //     const instance = new web3.eth.Contract(
  //       FundA,
  //       selectedInvestment.fundAddress
  //     );
  //     console.log('Fund instance : ', instance);
  //     setFundInstance(instance);
  //   })();
  // };

  // const handleCloseS = () => {
  //   setOpenS(false);
  // };

  // const handleOpenS = () => {
  //   setOpenS(true);
  // };

  // useEffect(() => {
  //   if (web3) {
  //     (async () => {
  //       const accounts = await web3.eth.getAccounts();
  //       setAddress(accounts[0]);
  //       const IVNTokenInstance = new web3.eth.Contract(IVNToken, IVNTOKEN_ADDR);
  //       setIVNTokenInstance(IVNTokenInstance);
  //       const instance = new web3.eth.Contract(
  //         FundA,
  //         selectedInvestment.fundAddress
  //       );
  //       console.log('Fund instance : ', instance);
  //       setFundInstance(instance);
  //     })();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [web3]);

  // const handleSendIVNToken = async () => {
  //   console.log('handleSendIVNToken called ');
  //   try {
  //     // alert("only you can send token only to the same startegy which minted");
  //     console.log(
  //       'handleSendIVNToken =  address:' +
  //       address +
  //       ' FundAddress:' +
  //       selectedInvestment.fundAddress +
  //       ' tokenId:' +
  //       selectedInvestment.tokenId
  //     );
  //     let trs = await IVNTokenInstance.methods
  //       .safeTransferFrom(
  //         address,
  //         selectedInvestment.fundAddress,
  //         selectedInvestment.tokenId
  //       )
  //       .send({ from: address });
  //     console.log('IVNToken trs = ', trs);
  //     setOpen(false);
  //     props.notify('Investment withdrawing...');
  //     fetchInvestments(web3, dispatch);
  //     return true;
  //   } catch (error) {
  //     console.error('handleSendIVNToken error: ', error);
  //     setOpen(false);
  //     return false;
  //   }
  // };

  // const handleEndInvestment = async () => {
  //   console.log('handleEndInvestment called ');
  //   try {
  //     // alert("only you can send token only to the same startegy which minted");

  //     const instance = new web3.eth.Contract(
  //       FundA,
  //       selectedInvestment.fundAddress
  //     );
  //     console.log(
  //       'handleEndInvestment  address:' +
  //       address +
  //       ' FundAddress:' +
  //       selectedInvestment.fundAddress +
  //       ' tokenId:' +
  //       selectedInvestment.tokenId
  //     );
  //     let trs = await instance.methods
  //       .endInvestment(selectedInvestment.tokenId)
  //       .send({ from: address });
  //     console.log('endInvestment trs = ', trs);
  //     // setOpen(false);
  //     props.notify('Quiting Investment...');
  //     fetchInvestments(web3, dispatch);
  //     return true;
  //   } catch (error) {
  //     console.error('handleEndInvestment error: ', error);
  //     return false;
  //     // setOpen(false);
  //   }
  // };

  // function statusString(st) {
  //   switch (st) {
  //     case '2':
  //     case 'active':
  //       return 'initiate Withdraw';
  //     case '3':
  //     case 'withdrawing':
  //       return 'Withdrawing process..';
  //     case '4':
  //     case 'withdrawn':
  //       return 'Transfer to Wallet';
  //     default:
  //       return 'Exit';
  //   }
  // }

  return (
    <div>
      <Button
        size="md"
        block
        onClick={handleClickOpen}
        className="btn-main-theme-secondary"
      >
        Withdraw
      </Button>

      {/* <Modal className="modal-black" isOpen={open} toggle={handleClose}>
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <ModalBody className="modal-black text-center">
          
          <Grid container justifyContent="center">
            <Grid item sm={4}></Grid>
            <Grid item sm={4}>
              
              <h3
                style={{
                  fontWeight: 'bold',
                  marginLeft: '8px',
                  textAlign: 'center',
                }}
              >
                {' '}
                Withdraw
              </h3>
            </Grid>
            <Grid item sm={4}></Grid>
          </Grid>

          <Grid container justifyContent="center">
            <Grid item sm={4}></Grid>

            <Grid item sm={4}>
              {!web3 && (
                <p
                  style={{
                    color: 'red',
                    fontWeight: 'bold',
                    marginLeft: '8px',
                    textAlign: 'center',
                  }}
                >
                  {' '}
                  Please Connect Wallet
                </p>
              )}

              <Input
                type="select"
                bsSize="md"
                className={classes.slectStyling}
                onClose={handleCloseS}
                onOpen={handleOpenS}
                onChange={handleChangeS}
                options={options}
                value={tokenIndex}
              >
                <option className={classes.optionStyling} hidden>
                  Select token
                </option>
                {investments &&
                  investments.map((i, key) => {
                    return (
                      <option
                        className={classes.optionStyling}
                        value={key}
                        key={i}
                      >
                        {' '}
                        {i.tokenId}{' '}
                      </option>
                    );
                  })}
              </Input>

            </Grid>

            <Grid item sm={4}></Grid>
          </Grid>

          <br />

          {web3 && FundInstance && (
            <Grid container justifyContent="space-between">
              <Grid item sm={2}></Grid>

              <Grid item sm={4}>
                <p
                  style={{
                    fontWeight: 'bold',
                    marginLeft: '5px',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  Amount Invested
                </p>

                <p
                  style={{
                    marginLeft: '5px',
                    fontSize: '15px',
                    justifyContent: 'center',
                    alignContent: 'center',
                  }}
                >
                  {investments && <b>{selectedInvestment.amount}</b>}
                </p>
              </Grid>
              <Grid item sm={2}></Grid>
              <Grid item sm={4}>
                <p
                  style={{
                    fontWeight: 'bold',
                    marginLeft: '5px',
                    justifyContent: 'right',
                    alignContent: 'center',
                  }}
                >
                  ROI
                </p>

                <p
                  style={{
                    marginLeft: '5px',
                    fontSize: '15px',
                    justifyContent: 'right',
                    alignContent: 'center',
                  }}
                >
                  {investments && <b>{selectedInvestment.investmentReturns}</b>}
                </p>
              </Grid>
            </Grid>
          )}

          {web3 && FundInstance && (
            <Grid container justifyContent="center" mt={4}>
              <Grid item sm={4}></Grid>
              <Grid item sm={4}>
                <h6
                  style={{
                    fontWeight: 'bold',
                    marginLeft: '5px',
                    fontSize: '15px',
                    marginTop: 25,
                  }}
                >
                  STATUS :{' '}
                  {selectedInvestment.status
                    ? selectedInvestment.status
                    : 'No found'}
                </h6>
              </Grid>
              <Grid item sm={4}></Grid>
            </Grid>
          )}

          {web3 && selectedInvestment.tokenId && (
            <div>
              <Grid container justifyContent="center">
                <Grid item sm={4}></Grid>
                <Grid item sm={4}>
                  {selectedInvestment.status === 'inactive' ? (
                    <Button
                      onClick={handleSendIVNToken}
                      size="sm"
                      style={{ color: 'white' }}
                    >
                      {statusString(selectedInvestment.status)}
                    </Button>
                  ) : (
                    <WithdrawStepper
                      handleSendIVNToken={handleSendIVNToken}
                      handleEndInvestment={handleEndInvestment}
                      status={selectedInvestment.status}
                    />
                  )}
                </Grid>
                <Grid item sm={4}></Grid>
              </Grid>
            </div>
          )}
        </ModalBody>
      </Modal> */}
    </div>
  );
}
