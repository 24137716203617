import React, {
  useState,
  // useEffect
} from 'react';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Spinner,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from 'reactstrap';
import classnames from 'classnames';

import FundGridView from '../components/FundGridView';
import SearchBox from '../components/SearchBox';
import {
  IconButton,
  Grid,
  // FormControl,
  // InputLabel,
  // MenuItem,
  // Select,
} from '@material-ui/core';
import AutorenewSharpIcon from '@material-ui/icons/AutorenewSharp';

import { useFunds } from '../hooks/useFunds';

const Funds = () => { 
  const funds = useFunds(); // will dynamically fetch multiple times
  // console.log("useFunds direct :", funds); 
 
  // const [loading, setLoading] = useState(false);

  const [activeTab, setActiveTab] = useState('4');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [searchText, setSearchText] = useState("");

  const onSearchChange = (event) => {
    setSearchText(event.target.value)
  };
  const [showFilter, setShowFilter] = useState(false);

  const [selectedFilter, setSelectedFilter] = React.useState('Highest Performance');
  // const [openFilter, setOpenFilter] = React.useState(false);

  // const handleCloseFilter = () => {
  //   setOpenFilter(false);
  // };

  // const handleOpenFilter = () => {
  //   setOpenFilter(true);
  // };

   


  return (
    <>
      <div className="content">
        <div>
          <Card>
            <CardHeader></CardHeader>
            <CardBody>
              <Row>
                <Col lg="3">
                  <CardTitle tag="h4">Available Crypto Funds</CardTitle>
                  <p>
                    Choose From {funds?.pancakeSwapFunds.length + funds?.waultSwapFunds.length +funds?.StrategyFunds.length}+ Crypto Funds Created By Experts
                  </p>
                </Col>
                <Col lg="5">
                  <SearchBox searchChange={onSearchChange} />
                </Col>
                <Col lg="2">
                  <IconButton size="small" onClick={() => console.log("refresh data somehow?")}>
                    <AutorenewSharpIcon
                      color="primary"
                      style={{ margin: '5px' }}
                    />
                  </IconButton>
                </Col>

                 <Col lg="2" sm="6" className="text-right"> 
                      <div className="app-content-actions-wrapper">
                      <div className="filter-button-wrapper">
                          <button className="action-button filter jsFilter" onClick={() => setShowFilter(!showFilter)}>
                            <div style={{textAlign:'center'}}>
                               <span style={{ padding: '15px', fontSize: '1.1rem', fontWeight: '550'}} >Filter</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none"
                                  stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                                  className="feather feather-filter">
                                  <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                                </svg>
                            </div>
                          </button>
                      {
                        showFilter &&
                        <div className={`filter-menu active `} >
                               
                                <select value={selectedFilter}
                                  onChange={(event) => setSelectedFilter(event.target.value)}
                                    style={{marginBottom :'0px'}} >
                                  {/* <option value="">Hot</option> */}
                                  <option value={"Highest Performance"}>Highest Performance</option>
                                  <option value={"Lowest Performance"}>Lowest Performance</option>
                                  <option value={"Highest AUM"}>Highest AUM</option>
                                  <option value={"Lowest AUM"}>Lowest AUM</option>
                                </select>
                              </div>
                              
                          }
                        </div>
                        </div>
                </Col>
                
                {/* <Col lg="1"  >
                  <FormControl fullWidth>
                    <InputLabel
                      id="demo-simple-select-label"
                      style={{ color: 'white' }}
                    >
                      Filters
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      open={openFilter}
                      onClose={handleCloseFilter}
                      onOpen={handleOpenFilter}
                      value={selectedFilter}
                      style={{ color: 'white' }}
                      onChange={(event) => setSelectedFilter(event.target.value)}
                    >

                      <MenuItem value="" style={{}}
                      >
                        None
                      </MenuItem>
                      <MenuItem value={"Highest Performance"}>Highest Performance</MenuItem>
                      <MenuItem value={"Lowest Performance"}>Lowest Performance</MenuItem>
                      <MenuItem value={"Highest AUM"}>Highest AUM</MenuItem>
                      <MenuItem value={"Lowest AUM"}>Lowest AUM</MenuItem>
                    </Select>
                  </FormControl>
                </Col> */}



              </Row>
              <Grid container className="justify-space-between">
                <Grid item></Grid>
                <Grid item></Grid>
              </Grid>
            </CardBody>
          </Card>
        </div>
        <Nav className="nav-pills-info  justify-content-center" pills>
          <Row className="background-main btn-round pr-5 pl-5">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '1' })}
                onClick={() => {
                  toggle('1');
                }}
              >
                PANCAKESWAP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '2' })}
                onClick={() => {
                  toggle('2');
                }}
              >
                WAULTSWAP
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '3' })}
                onClick={() => {
                  toggle('3');
                }}
              >
                DEFI-FUNDS
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '4' })}
                onClick={() => {
                  toggle('4');
                }}
              >
                All
              </NavLink>
            </NavItem>
          </Row>
        </Nav>
        <TabContent className="tab-space" activeTab={activeTab}>
          {
            !(funds?.pancakeSwapFunds?.length)
            && (
            <div
              id="overlay"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: '3.5%',
                bottom: '0',
                backgroundColor: 'rgba(0,0,0,0.5)',
                zIndex: '9',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
            >
              <Spinner
                style={{
                  position: 'absolute',
                  top: '55%',
                  left: '40%',
                  color: 'aliceblue'
                }}
              ></Spinner>
            </div>
          )}
          {
            (funds?.pancakeSwapFunds?.length) && (
            <>
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FundGridView key="decentralizedFunds" funds={funds?.pancakeSwapFunds} searchText={searchText} selectedFilter={selectedFilter}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FundGridView key="centralizedFunds" funds={funds?.waultSwapFunds} searchText={searchText} selectedFilter={selectedFilter}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
               <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FundGridView key="centralizedFunds" funds={funds?.StrategyFunds} searchText={searchText} selectedFilter={selectedFilter}/>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="4">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col md="12">
                        <FundGridView funds={[...funds?.pancakeSwapFunds, ...funds?.waultSwapFunds, ...funds?.StrategyFunds]} searchText={searchText} selectedFilter={selectedFilter} />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </>
          )}
        </TabContent>
      </div>
    </>
  );
};

export default Funds;
