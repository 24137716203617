import {
    ROUTER_DEFI_ADDR,
    ROUTER_PANCAKESWAP_ADDR,
    ROUTER_WAULTSWAP_ADDR,
    PANCAKESWAP_V2_FACTORY_ADDR,
    PANCAKESWAP_V2_INIT_HASH,
    WAULTSWAP_V2_FACTORY_ADDR,
    WAULTSWAP_V2_INIT_HASH
} from "./constants"
// cannot use constants as json keys so have to add new Router Address everytime here
export const routerToDexDataMapping = {
   [ROUTER_PANCAKESWAP_ADDR]: {
        factoryAddress: PANCAKESWAP_V2_FACTORY_ADDR,
        factoryInitCode: PANCAKESWAP_V2_INIT_HASH
    },
   [ROUTER_WAULTSWAP_ADDR]: {
        factoryAddress: WAULTSWAP_V2_FACTORY_ADDR,
        factoryInitCode: WAULTSWAP_V2_INIT_HASH
    },
    [ROUTER_DEFI_ADDR]:  {
        factoryAddress: PANCAKESWAP_V2_FACTORY_ADDR,
        factoryInitCode: PANCAKESWAP_V2_INIT_HASH
    },
}