import React ,{  useState } from 'react'
import PropTypes from 'prop-types';
import Col from 'reactstrap/lib/Col';
import Card from 'reactstrap/lib/Card';
// import CardHeader from 'reactstrap/lib/CardHeader';
import copy from 'copy-to-clipboard';
import notify from '../../utils/notify';
import CardTitle from 'reactstrap/lib/CardTitle';
import Row from 'reactstrap/lib/Row';
import CardBody from 'reactstrap/lib/CardBody';
import Identicon from 'identicon.js'
import HelpIcon from '@material-ui/icons/Help';
import  {Tooltip} from 'reactstrap';


const InternalCard = ({ heading, content, tooltiptext='', isAddress = false, allowCopy, color, icon, children }) => {
  
  const [cardTooltipOpen, setCardTooltipOpen] = useState(false);
  const toggleCardToolTip = () => setCardTooltipOpen(!cardTooltipOpen);

  const copyOnClick = (event) => {
    if (allowCopy) {
      copy(content || "");
      notify(`Address copied...`);
    }
  };
  const addrIcon = () => {
    // var options = {
    //   foreground: [255, 255, 255, 255],
    //   background: [0, 0, 0, 255],
    //   margin: 0.2,
    //   size: 128,
    //   format: 'svg'
    // };
    var data = new Identicon(content).toString();
    return data;
  }
  return (
    <Card style={!children ? { height: '90px' } : {}} className="card-chart" onClick={copyOnClick}>
      <div className={`${color} card-stats`}>

        <CardBody>
          <Row>
            {
              icon ? <Col xs="2">
                <div className="info-icon text-center icon-primary">
                  <i className={`tim-icons ${icon}`} />
                </div>
              </Col> : <Col xs="1"></Col>
            }
            <Col xs={`${children ? "5" : "10"}`}>
              <h5 className="card-category">
                {heading}
               { tooltiptext && <span>
                        <HelpIcon
                          className="ml-2"
                          id={heading.replace(/ /g,'')}
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={cardTooltipOpen}
                          target={heading.replace(/ /g,'')}
                          autohide={true}
                          toggle={toggleCardToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          {tooltiptext}
                    </Tooltip>
                      </span>}
              </h5>
              <CardTitle tag="h4">
                {(isAddress && content && content.length > 5) && <img width={25} height={25} alt="dashboard card" src={`data:image/png;base64,${addrIcon()}`} />}
                {(isAddress && content && content.length > 5) && `     ${content.substring(0, 6)}...${content.substr(content.length - 5)}`}
                {!isAddress && content}
              </CardTitle>
            </Col>
            {children &&
              <Col xs="5" className="d-flex align-items-center" style={{ textAlign: 'right' }}>
                {children}
              </Col>
            }
          </Row>
        </CardBody>
      </div>
    </Card>
  )
}


const DashboardCard = (props) => {

  const { useCol } = props

  const main = () => {
    if (useCol) {
      return (
        <Col lg="4">
          <InternalCard {...props} />
        </Col>
      )
    } else {
      return <InternalCard {...props} />
    }
  }

  return (main())
}

export default DashboardCard;

DashboardCard.propTypes = {
  heading: PropTypes.string,
  content: PropTypes.string,
  allowCopy: PropTypes.bool,
  useCol: PropTypes.bool,
  color: PropTypes.oneOf(['violet', 'cerulean', 'blue']),
  icone: PropTypes.string
}
