import { Button } from 'reactstrap'
import React from 'react'
// import { roundDownTo6Decimals } from '../../utils/helperFunctions';

const PercentButton = (props) => {

    const { value, percentage, callback,
        // trade
    } = props;
    

    return (
        <div>
            <Button
                onClick={() => callback(percentage === 100 ? value : (percentage / 100) * parseFloat(value))}
                style={{ fontSize: '10px' }}
                size="sm"
            >
                {percentage} %
            </Button>
        </div>
    )
}

export default PercentButton
