const SET_WEB3 = 'SET_WEB3';

const web3Reducer = (state = {}, action) => {
  const web3 = action.web3;
  switch (action.type) {
    case SET_WEB3:
      return {
        ...state,
        web3,
      };
    default:
      return state;
  }
};

export default web3Reducer;
