const SET_APPROVING = 'SET_APPROVING';

const approvingReducer = (state = {}, action) => {
  const approving = action.approving;
  switch (action.type) {
    case SET_APPROVING:
      return {
        approving,
      };
    default:
      return state;
  }
};

export default approvingReducer;
