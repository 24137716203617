import React from "react"
import PropTypes from 'prop-types';
import ContentLoader from "react-content-loader";

export const SkeletonLoader = ({ width = 80, height = 13 }) => {
    return (
        <ContentLoader
            speed={1}
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            backgroundColor="#4155D4"
            foregroundColor="#B409D4"
        >
            <rect rx="3" ry="3" width={`${width}`} height={`${height}`} />
        </ContentLoader>
    )
}


SkeletonLoader.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number
}
