import React, {
    useEffect,
    useState
} from 'react';
// import {
//     useDispatch,
//     useSelector
// } from 'react-redux';
// import { ethers } from 'ethers';

import {
    // Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    // Form,
    // CustomInput,
    // FormGroup,
    // Label,
    // Input
} from 'reactstrap';
import PropTypes from 'prop-types';

import {
    // FUND_FACTORY_DEFI_ADDR,
    INVESTIN_MULTI_CALL,
    // PANCAKESWAP_V2_ROUTER_ADDR,
    // ROUTER_DEFI_ADDR,
    TETHERTOKEN_ADDR,
    // WBNB_ADDR
} from '../utils/constants';
import TetherTokenAbi from '../contracts/TetherToken.json';
// import FundAbi from '../contracts/Fund.json';
import FundDefiAbi from '../contracts/FundDefi.json';
// import RouterDefi from '../contracts/RouterDefi.json';
// import ExchangeRouter from '../contracts/ExchangeRouter.json';
// import investinMulticall2Abi from '../contracts/InvestinMulticall2.json'; 
import Fusion_V3_V4_MulticallAbi from '../contracts/Fusion_V3_V4_Multicall.json'; 
import { sendTrxNotify } from '../utils/notify';
import CustomButton from './button';




export const  DynamicPerformanceModal = ({ web3, fund, minAmount, address, managerPerformanceFees,  disabled, buttonStyle='', afterClaimCallback, ...props }) => {

//   const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [dynamicFeeToBeCollected, setDynamicFeeToBeCollected] = useState('')
  const [profitableTokenids, setProfitableTokenids] = useState([]);
  const [loading, setLoading] = useState(false);
    const [loadingRedeem, setLoadingRedeem] = useState(false);
    
    const [fundBUSDBalance, setFundBUSDBalance] = useState('');
    const [err, setErr] = useState("")

    useEffect(() => {
        (async () => {
            const tetherTokenInstance = new web3.eth.Contract(TetherTokenAbi, TETHERTOKEN_ADDR);
            let balanceUSDT = await tetherTokenInstance.methods.balanceOf(fund.address).call();
            balanceUSDT = parseFloat(web3.utils.fromWei(balanceUSDT, "ether"))  //.toFixed(4);
            //truncate to 4 decimal without rounding
            balanceUSDT = (Math.trunc(balanceUSDT * 10000) / 10000).toFixed(4);
            
            setFundBUSDBalance(balanceUSDT);
        })()
        
    }, [web3,fund])
    

    const toggle = () => setModal(!modal);
    
    const handleClickOpen = () => {
        setModal(true);
    };

    const handleClose = () => {
        setModal(false);
    };

    const handleCalculate = async () => {
        console.log("---handleCalculate----");
        try {
               setLoading(true);
        
            const fundInstance = new web3.eth.Contract(FundDefiAbi, fund.address);
            
            const tokenIds = await fundInstance.methods.getTokenIds().call();
            // (await instance.methods.getFundDetails().call()
            console.log("all fund tokenIds:", tokenIds);
                let minReturn = await fundInstance.methods.minReturn().call();
                const InvestinMulticallInstance = new web3.eth.Contract(Fusion_V3_V4_MulticallAbi, INVESTIN_MULTI_CALL);
                let performanceTokensIds = [];
                let dymanicPerformanceFee = parseFloat(0);
            for (let i = 0; i < tokenIds.length; i++) {

                    console.log("check tokenId :", tokenIds[i]);
                    const investments_multi = await InvestinMulticallInstance.methods.getInvestmentDetailsByTokenID(tokenIds[i]).call();
                    console.log('getInvestmentDetailsByTokenID investments_multi: ', investments_multi);

                let {
                    // investorAddress,
                    fundAddress,
                    // baseToken,
                    investedAmount, startPerformance,
                    currentPerformance,
                    // currentReturns,
                    perfomanceFee } = investments_multi;
                    // console.log("investments struct from  multi:", investments_multi);
                    console.log("perfomanceFee , currentPerformance,minReturn :",perfomanceFee ,currentPerformance,minReturn)
                    
                    if (fundAddress === '0x0000000000000000000000000000000000000000'|| investedAmount === '0' || startPerformance === '0' ) {
                        continue;
                    }
                    if (parseFloat(currentPerformance) < parseFloat(minReturn)) {
                        continue;
                    }
                    perfomanceFee=  parseFloat(`${web3.utils.fromWei(perfomanceFee, 'ether')}`).toFixed(4)
                
                    if (perfomanceFee < 0) {
                        continue;
                    }
                    
                dymanicPerformanceFee = parseFloat(parseFloat(dymanicPerformanceFee) + parseFloat(perfomanceFee * 0.9)).toFixed(4);
                // 0.9 as 10% is taken by platform 
                console.log("perfomanceFee, dymanicPerformanceFee : ", perfomanceFee, dymanicPerformanceFee);
                    performanceTokensIds.push(tokenIds[i]);
                }
            setDynamicFeeToBeCollected(parseFloat(dymanicPerformanceFee));
            console.log(`performanceTokensIds:`, performanceTokensIds)
            setProfitableTokenids(performanceTokensIds);
            setLoading(false);

            if (parseFloat(fundBUSDBalance) < parseFloat(dymanicPerformanceFee))
            {
                setErr("you need to have required BUSD balance on fund to claim dymanic performanceFee");
            }

        } catch (error) { console.error(error) } finally {
            setLoading(false);
        }
        
    }
    
    const handleRedeem = async () => {
        try {
            setLoadingRedeem(true);
            const fundInstance = new web3.eth.Contract(FundDefiAbi, fund.address);
            console.log(`profitableTokenids :::: `, profitableTokenids)
            const  trx = fundInstance.methods.redeemDynamicPerformanceFee([0,...profitableTokenids]);
            await sendTrxNotify(trx, address, "Dynamic Performance Redeemed sucessfully..");
            setLoadingRedeem(false);
            handleClose();
            await afterClaimCallback();
        } catch (error) { console.error(error) } finally {
            setLoadingRedeem(false);
             handleClose();
        }
    }
    

  return (
    <div>
     <button className={`${buttonStyle} btn btn-round btn-submit `}
                disabled={disabled}
              onClick={handleClickOpen}>
              {
                  managerPerformanceFees>0 ? "Claim" : "Calculate"
              }
                
          </button>
          
      <Modal isOpen={modal} toggle={toggle} className="investinDepositModal">
              <ModalHeader toggle={toggle} style={{}}>
                  <b style={{ color: 'white', fontSize :'1rem' }}> Redeem Dynamic performance of fund {fund.name}</b>
              </ModalHeader>
              <ModalBody>
                  <div style={{textAlign:'center'}}>
                      <br />
                       <p>base token balance of fund : { fundBUSDBalance ? fundBUSDBalance : 0  } BUSD</p>
                      <br />
                       <p style={{ color: "red" }}>{err}</p>
                      
                      
                    <p style={{fontSize:'0.9rem'}}>Click Calculate button to Know your dynamic Perfomance fee </p>
                    
                    <CustomButton onClick={handleCalculate} loading={loading} text="Calculate" color="primary" />
                    
                        <br/>
                    {
                        loading && <><br/><p>Calculating ....</p></>
                    }
                        <br/>
                    <p>Total no of profitable investments : { profitableTokenids.length }</p>
                    <br/>
                    <p>General Performance Fee : {managerPerformanceFees}</p>
                      <br />
                    <p>Dymanic Performance Fee : {dynamicFeeToBeCollected}</p>
                      <br/>
                      <CustomButton onClick={handleRedeem}
                          loading={loadingRedeem}
                          disabled={((parseFloat(managerPerformanceFees) + parseFloat(dynamicFeeToBeCollected)) <= 0) || err}
                          text="Collect" color="primary" />
                   </div>
              </ModalBody>
              <ModalFooter>
                 
        
              </ModalFooter>
      </Modal>
    </div>
  );
}

DynamicPerformanceModal.propTypes = {
    web3: PropTypes.object,
    fund: PropTypes.exact({
        name: PropTypes.string,
        address: PropTypes.string,
        fundFactory: PropTypes.string,
        routerAddress: PropTypes.string
    }),
    address: PropTypes.string,
    disabled : PropTypes.bool
}
