import React from 'react';
import { Typography } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function Tables(props) {
  return (
    <div className="content">
      <Accordion style={{ marginTop: '15px' }} color="primary">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography color="primary" variant="subtitle2">
            {props.name}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="primary">{props.about}</Typography>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default Tables;
