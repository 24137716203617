import fund from '../contracts/Fund.json';
import fundFactoryDaiAbi from '../contracts/FundFactoryDai.json';
import fundFactoryWethAbi from '../contracts/FundFactoryWeth.json';
import fundFactoryStrategyAbi from '../contracts/fundFactoryStrategy.json';

import routerDaiAbi from '../contracts/RouterDai.json';
import routerWethAbi from '../contracts/RouterWeth.json';
import routerDefiAbi from '../contracts/RouterDefi.json';

// import ivnAbi from '../contracts/IVNToken.json';
// import tetherTokenAbi from '../contracts/TetherToken.json'
import investinMulticall from '../contracts/InvestinMulticall.json'
import investinMulticall2 from '../contracts/InvestinMulticall2.json';

import {
  ROUTER_PANCAKESWAP_ADDR,
  ROUTER_WAULTSWAP_ADDR,
  ROUTER_DEFI_ADDR,
  FUND_FACTORY_PANCAKESWAP_ADDR,
  FUND_FACTORY_WAULTSWAP_ADDR,
  FUND_FACTORY_DEFI_ADDR,
} from './constants';

export const fundAbi = fund;
export const investinMulticallAbi = investinMulticall;
export const investinMulticall2Abi = investinMulticall2;

const getFundFactoryAbi = (type) => {

    if (type===1) {
        return fundFactoryDaiAbi ;
    } else if (type===2){
        return fundFactoryWethAbi;
    }else if (type===3){
        return fundFactoryStrategyAbi;
    } else {
        console.error('INVALID FACTORY TYPE SPECIFIED type:', type);
    }
}

const getRouterAbi = (type) => {

    if (type===1) {
        return routerDaiAbi;
    } else if (type===2){
        return routerWethAbi;
    } else if (type===3){
        return routerDefiAbi;
    } else {
        console.error('INVALID ROUTER TYPE SPECIFIED type:', type);
    }
}

const getRouterAddressHelper = (fundFactoryAddress) => {
  switch (fundFactoryAddress) {
    case FUND_FACTORY_PANCAKESWAP_ADDR:
      return ROUTER_PANCAKESWAP_ADDR;
    case FUND_FACTORY_WAULTSWAP_ADDR:
      return ROUTER_WAULTSWAP_ADDR;
    case FUND_FACTORY_DEFI_ADDR:
      return ROUTER_DEFI_ADDR;
    default:
      break;
  }
}

const getFactoryAddressHelper = (routerAddress) => {
  switch (routerAddress) {
    case ROUTER_PANCAKESWAP_ADDR:
      return FUND_FACTORY_PANCAKESWAP_ADDR;
    case ROUTER_WAULTSWAP_ADDR:
      return FUND_FACTORY_WAULTSWAP_ADDR;
    case ROUTER_DEFI_ADDR:
      return FUND_FACTORY_DEFI_ADDR;
    default:
      break;
  }
}

export const fundFactoryAbi = getFundFactoryAbi;
export const routerAbi = getRouterAbi;
export const getRouterAddress = getRouterAddressHelper;
export const getFactoryAddress = getFactoryAddressHelper;
