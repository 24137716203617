import { blue } from '@material-ui/core/colors';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import { Grid, Typography } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';
import { fundFactoryAbi } from '../../utils/getAbi';
import { getWeb3ProviderConfig } from '../../utils/getWeb3';
import metamaskLogo from '../../assets/img/metamask.svg';
import coinbaseLogo from '../../assets/img/coinbase.svg';
import walletconnectLogo from '../../assets/img/walletconnect.svg';
// import fortmaticLogo from '../../assets/img/fortmatic.svg';
import torusLogo from '../../assets/img/tor.svg';
import TetherTokenA from '../../contracts/TetherToken.json';


import {
  setGlobalAddress,
  setGlobalFundAddress,
  setGlobalUSDTBalance,
  setGlobalFactoryAddress,
  setGlobalWeb3,
  setGlobalApprovedAmt,
} from '../../redux/actions';
import {
  TETHERTOKEN_ADDR,
  ROUTER_PANCAKESWAP_ADDR,
  ROUTER_WAULTSWAP_ADDR,
  ROUTER_DEFI_ADDR,
  FUND_FACTORY_PANCAKESWAP_ADDR,
  FUND_FACTORY_WAULTSWAP_ADDR,
  FUND_FACTORY_DEFI_ADDR,
} from '../../utils/constants';

import { fetchInvestments } from '../../utils/fetchInvestmentsHelper';
import { Modal } from 'reactstrap';

const providers = [
  {
    name: 'MetaMask',
    imageUrl: metamaskLogo,
  },
  {
    name: 'Coinbase Wallet',
    disabled: true,
    imageUrl: coinbaseLogo
  },
  {
    name: 'WalletConnect',
    disabled: true,
    imageUrl: walletconnectLogo,
  },
  {
    name: 'Torus',
    disabled: true,
    imageUrl: torusLogo,
  },

];

const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
  buttonHover: {
    marginTop: 20,
    border: '1px solid #B409D4',
    width: '210px !important',
    height: '55px !important',
    '&:hover': {
      background: 'grey',
    },
  },

});



function WalletModal(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open } = props;
  const dispatch = useDispatch();

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    (async () => {
      const web3 = await getWeb3ProviderConfig(value);
      dispatch(setGlobalWeb3(web3));

      onClose(value);

      const accounts = await web3.eth.getAccounts();
      const address = accounts[0];
      dispatch(setGlobalAddress(address));

      const chainId = await web3.eth.getChainId();
      // dispatch(setGlobalChainId(chainId));
      console.log('chainId : ', chainId);


      const tetherTokenInstance = new web3.eth.Contract(
        TetherTokenA,
        TETHERTOKEN_ADDR
      );
      let balanceUSDT = await tetherTokenInstance.methods
        .balanceOf(address)
        .call();
      // setBalanceUSDT(balanceUSDT);
      dispatch(setGlobalUSDTBalance(parseFloat(web3.utils.fromWei(balanceUSDT, "ether")).toFixed(4)));

      let pancakeSwapAllowance = await tetherTokenInstance.methods
        .allowance(address, ROUTER_PANCAKESWAP_ADDR)
        .call();

      let waultSwapAllowance = await tetherTokenInstance.methods
        .allowance(address, ROUTER_WAULTSWAP_ADDR)
        .call();
      
      let StrategyFundAllowance = await tetherTokenInstance.methods
        .allowance(accounts[0], ROUTER_DEFI_ADDR)
        .call();

      dispatch(setGlobalApprovedAmt(
        {
          [ROUTER_PANCAKESWAP_ADDR]: pancakeSwapAllowance,
          [ROUTER_WAULTSWAP_ADDR]: waultSwapAllowance,
          [ROUTER_DEFI_ADDR] : StrategyFundAllowance,
        }
      ));

      const pancakeFundFactoryInstance = new web3.eth.Contract(
        fundFactoryAbi(1),
        FUND_FACTORY_PANCAKESWAP_ADDR
      );
      let pancakeFundAddress = await pancakeFundFactoryInstance.methods
        .getManagerToFund(accounts[0])
        .call();
      let pancakeFundStatus = false;
      if (pancakeFundAddress) {
        pancakeFundStatus = await pancakeFundFactoryInstance.methods.getFundMapping(pancakeFundAddress).call();
      }

      const waultFundFactoryInstance = new web3.eth.Contract(
        fundFactoryAbi(2),
        FUND_FACTORY_WAULTSWAP_ADDR
      );
      let waultFundAddress = await waultFundFactoryInstance.methods
        .getManagerToFund(accounts[0])
        .call();
      let waultFundStatus = false;
      if (waultFundAddress) {
        waultFundStatus = await waultFundFactoryInstance.methods.getFundMapping(waultFundAddress).call();
      }

      const StrategytFundFactoryInstance = new web3.eth.Contract(
        fundFactoryAbi(3),
        FUND_FACTORY_DEFI_ADDR
      );
      let StrategyFundAddress = await StrategytFundFactoryInstance.methods
        .getManagerToFund(accounts[0])
        .call();
      let StrategyFundStatus = false;
      if (StrategyFundAddress) {
        StrategyFundStatus = await StrategytFundFactoryInstance.methods.getFundMapping(StrategyFundAddress).call();
      }
      
      // getNumberOfUnsedVaults
      // let getNumberOfUnsedVaults = await fundFactoryInstance.methods
      //   .getNumberOfUnsedVaults()
      //   .call({ from: address });
      // console.log('**getNumberOfUnsedVaults:', getNumberOfUnsedVaults);

       console.log(`pancakeFundAddress & status ::: `, pancakeFundAddress, pancakeFundStatus);
      console.log(`waultFundAddress & status ::: `, waultFundAddress, waultFundStatus);
      console.log(`StrategyFundAddress & status ::: `, StrategyFundAddress, StrategyFundStatus);

      const addressValid = (address) => {
        return address !== '' && address !== '0x0000000000000000000000000000000000000000';
      }
      
      if (addressValid(waultFundAddress) && waultFundStatus) {
        console.log('**waultswap fund');
        dispatch(setGlobalFundAddress(waultFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_WAULTSWAP_ADDR));
      }
      else if (addressValid(pancakeFundAddress) && pancakeFundStatus) {
        console.log('**pancakeswap fund');
        dispatch(setGlobalFundAddress(pancakeFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_PANCAKESWAP_ADDR));
      }
      else if (addressValid(StrategyFundAddress) && StrategyFundStatus) {
        console.log('**Strategy fund');
        dispatch(setGlobalFundAddress(StrategyFundAddress));
        dispatch(setGlobalFactoryAddress(FUND_FACTORY_DEFI_ADDR));
      }
      else {
        console.log('Not a manager any of 3 types of funds');
      }
     
       // console.log('owner of fundAddr:', fundAddress);
      // const t2 = fundAddress === '0x0000000000000000000000000000000000000000';

      await fetchInvestments(web3, dispatch);
      props.onWeb3Connect(web3);
    })();
  };

  return (
    <Modal isOpen={open} toggle={handleClose}>
      <DialogTitle id="alert-dialog-title" style={{ textAlign: 'center' }}>
        Connect to a wallet
      </DialogTitle>

      <List style={{ color: 'white' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {providers.map((provider) => (
            <Grid item key={provider.name} >
              <ListItem
                button

                disabled={provider.disabled}
                className={classes.buttonHover}
                onClick={() => handleListItemClick(provider.name)}
                key={provider.name}
              >
                <ListItemAvatar>
                  <img
                    src={provider.imageUrl}
                    alt="Logo"
                    style={{ width: '38px', objectFit: 'contain' }}
                  />
                </ListItemAvatar>
                <ListItemText>{provider.name}</ListItemText>
              </ListItem>
            </Grid>
          ))}
        </Grid>
      </List>
      <List style={{ color: 'white' }}>
        <Grid
          container
          direction="row"
          justifyContent="space-around"
          alignItems="center"
        >
          {/* {[
                {
                  name: 'Mobile Wallet',
                  imageUrl:
                    'https://www.paymentscardsandmobile.com/wp-content/uploads/2017/03/Mobile-Wallet-Market-1.png',
                },
                {
                  name: 'Coinbase Wallet',
                  imageUrl:
                    'https://apprecs.org/ios/images/app-icons/256/5f/1278383455.jpg',
                },
              ].map((i, provider) => (
                <Grid item key={provider}>
                  <ListItem
                    button
                    className={classes.buttonHover}
                    onClick={() => handleListItemClick(provider.name)}
                    key={i}
                  >
                    <ListItemAvatar>
                      <img
                        src={provider.imageUrl}
                        alt="Logo"
                        style={{ width: '38px', objectFit: 'contain' }}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={provider.name} />
                  </ListItem>
                </Grid>
              ))} */}
        </Grid>
      </List>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Typography variant="caption" align="center" style={{ color: "#D1D1D7" }} display="block" gutterBottom>

            Non-Custodial & Secure
             <br />
            You control your funds and private keys. No cross-associating of wallet addresses.
          </Typography>
        </DialogContentText>
      </DialogContent>
    </Modal>
  );
}

WalletModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};

export default function WalletProviderPopup(props) {
  // const [selectedValue, setSelectedValue] = React.useState("");
  let selectedValue = '';

  return (
    <div>
      <WalletModal
        selectedValue={selectedValue}
        open={props.open}
        onClose={props.handleClose}
        onWeb3Connect={props.onWeb3Connect}
      />
    </div>
  );
}
