
import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
// import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom'

import {
  // Button,
  Card,
  // CardBody,
  CardHeader,
  CardTitle,
  Col,
  Row,
  // Spinner,
  TabContent,
  TabPane,
   Nav,
  NavItem,
  NavLink,
  Alert
} from 'reactstrap';
import classnames from 'classnames';

import DashboardCard from './DashboardViews/DashboardCard';
import CustomButton from '../components/button';

import {FUND_FACTORY_DEFI_ADDR } from '../utils/constants';
// import RouterDefi from '../contracts/RouterDefi.json';
import StrategyListView from './StrategyListView';
import strategyConfiguration from '../utils/strategyConfiguration.json';
import { fetchFundData , handleTransferToVaultHelper, amountInRouterHelper, fetchPrices} from '../utils/strategyHelper';

import SearchBox from '../components/SearchBox';


const Strategies = () => {

  // const history = useHistory();
    const dispatch = useDispatch();
  
  const web3 = useSelector((state) => state.web3Reducer.web3);
  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );
  const fundFactoryAddress = useSelector(
    (state) => state.factoryAddressReducer.factoryAddress
  );

  const location = useLocation();
  const isManagerSide = (location.pathname === '/manager/strategies') ? true : false;
  // console.error("location.pathname:",location.pathname);
  // console.error("isManagerSide:",isManagerSide);

  
  const [fundInstance, setFundInstance] = useState({});
  const address = useSelector((state) => state.addressReducer.address);
  // const [loading, setLoading] = useState(false);
  const [aum, setAum] = useState(0);
  const [performance, setPerformance] = useState(0);
  const [amountInRouter, setAmountInRouter] = useState(0);
  const [transfering, setTransfering] = useState(false);
  const strategies = strategyConfiguration;
  const StakingStrategies =strategyConfiguration.filter((i) => i.type === "LP")
  const LendingStrategies = strategyConfiguration.filter((i) => i.type === "Lending") 
  const BorrowStrategies = strategyConfiguration.filter((i) => i.type === "Borrow")
   
  const [onlyShowDesposited, setOnlyShowDesposited] = useState(false)

  const [disableStrategies, setDisableStrategies] = useState(false);


  useEffect(() => {

    // console.log("Main startegies calling useEffect for fundAddress, fundFactoryAddress :",fundAddress, fundFactoryAddress);

    if (!fundAddress || fundAddress === '' || fundAddress === '0x0000000000000000000000000000000000000000') {
      // alert('please create a ALPHA fund to start yeild farming');
      setDisableStrategies(true);
    }

    if (fundFactoryAddress !== FUND_FACTORY_DEFI_ADDR) {
      setDisableStrategies(true);
    } else {
      setDisableStrategies(false);
    }
    
    (async () => {
      // LOADING IS CAUSING THE CARDS RERENDER 
      // setLoading(true); 
     
      if (!(!fundAddress || fundAddress === '' || fundAddress === '0x0000000000000000000000000000000000000000')) {
        const obj = await fetchFundData(fundAddress,fundFactoryAddress);

        setFundInstance(obj.fundInstance);
        setPerformance(parseFloat(obj.performance));
        setAum(parseFloat(obj.aum).toFixed(4));
        setAmountInRouter(parseFloat(obj.amountInRouter).toFixed(2));
      }
      
      // setLoading(false);
      
    })();
  }, [ fundAddress, fundFactoryAddress]); // eslint-disable-next-line


 useEffect(() => {
    (async()=>{
      // console.log("Main startegies calling fetchprices");
      await fetchPrices(dispatch);
    })()
  }, []) // eslint-disable-next-line 
  

  const handleTransferToVault = async () => {
    setTransfering(true);
      await handleTransferToVaultHelper(web3,fundAddress,address)
    setTransfering(false);
 
    //refreshing amt
    await amountInRouterHelper(fundInstance);
  };


  const [searchText, setSearchText] = useState('');

  const onSearchChange = (event) => {
    setSearchText(event.target.value);
  };

  const [showFilter, setShowFilter] = useState(false);
  
  const [selectedFilter, setSelectedFilter] = React.useState('');
  const [selectedProtocol, setSelectedProtocol] = React.useState('');


  const [activeTab, setActiveTab] = useState('1');

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };


  return (
    <>
      <div className="content user-strategies">
        {/* {loading && (
          <div
            id="overlay"
            style={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              top: '0',
              bottom: '0',
              backgroundColor: 'rgba(0,0,0,0.5)',
              zIndex: '9',
              borderRadius: '5px',
              cursor: 'pointer',
            }}
          >
            <Spinner
              style={{
                position: 'absolute',
                top: '45%',
                left: '40%',
                color: 'aliceblue',
              }}
            ></Spinner>
          </div>
        )} */}
        {
          isManagerSide &&
          <Row>
          <Col lg="4">
            <DashboardCard
              heading="ASSETS UNDER MANAGEMENT"
              content={ web3 ? `${aum} BUSD` : ''}
            ></DashboardCard>
          </Col>
          <Col lg="4">
            <DashboardCard
              style={{ marginBottom: '0px' }}
              heading="TRANSFERABLE FUNDS"
              content={ web3 ? `${amountInRouter} BUSD` : ''}
              tooltiptext="Transfer the invested amount to fund vault for trading and collect the management fee. Make sure to transfer the funds to vault to start trading!"
            >
              <CustomButton
                className={
                  amountInRouter > 0 ? 'nav-pills-info pulse-button ' : ''
                }
                disabled={!web3 || amountInRouter === 0}
                onClick={handleTransferToVault}
                loading={transfering}
                text="Transfer"
              />
            </DashboardCard>
          </Col>
          <Col lg="4">
            <DashboardCard heading="PERFORMANCE" content={ web3 ? `${performance} %` : ''}></DashboardCard>
          </Col>
          </Row>
        }

        <Row>
          <Col lg="12">
            {/* {loading && (
              <div
                id="overlay"
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: '9',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                <Spinner
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '43%',
                  }}
                ></Spinner>
              </div>
            )} */}

            { (disableStrategies && isManagerSide)  && 
               <Alert color="danger" style={{width: "70%", textAlign: "center" , margin: "25px auto"}}>
              <b>{`PLEASE CREATE A DEFI FUND TO USE THESE STRATEGIES`}</b>
              </Alert>
            }

            { true && (
              <Card style={{ backgroundColor: 'transparent' }}>
                <CardHeader>
                  <CardTitle tag="h2" style={{ textAlign: 'center', marginBottom :'2%' }}>
                    <b style={{
                        fontSize: '2rem',
                        fontWeight: '600',
                        marginBottom: '2px',
                        color: 'white',
                      }}
                    >
                      {
                        isManagerSide ?
                          "STRATEGIES FOR FUNDS"
                          :
                          "STRATEGIES FOR USERS"
                      }
                      
                    </b>
                  </CardTitle>

                  <Row style={{ paddingBottom: '20px' }} >
                     <Col lg="12" sm="12">
                      <Nav className="nav-pills-info  justify-content-center" pills>
                          <Row className="background-main btn-round pr-5 pl-5">
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '1' })}
                                onClick={() => {
                                  toggle('1');
                                }}
                              >
                                ALL
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '2' })}
                                onClick={() => {
                                  toggle('2');
                                }}
                              >
                                STAKING
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '3' })}
                                onClick={() => {
                                  toggle('3');
                                }}
                              >
                                LENDING
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: activeTab === '4' })}
                                onClick={() => {
                                  toggle('4');
                                }}
                              >
                                BORROW
                              </NavLink>
                            </NavItem>
                          </Row>
                        </Nav>
                    </Col>
                  </Row>

                  <Row className="search-and-filter align-items-center justify-content-between">
                    <div className="d-flex toggle">
                      <input type="checkbox" className="toggle-staked mr-2" checked={!!onlyShowDesposited} onChange={() => setOnlyShowDesposited(!onlyShowDesposited)} />
                      <p>STAKED ONLY </p>
                    </div>
                    <div className="search">
                      <SearchBox searchChange={onSearchChange} />
                    </div>
                    <div className="d-flex">
                      <div className="app-content-actions-wrapper">
                        <div className="filter-button-wrapper">
                            <button className="action-button filter jsFilter" onClick={() => setShowFilter(!showFilter)}>
                              <div style={{textAlign:'center'}}>
                                <span style={{ padding: '15px', fontSize: '1.1rem', fontWeight: '550'}} >Filter</span>
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-filter">
                                    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                                  </svg>
                              </div>
                            </button>
                            {showFilter &&
                            <div className={`filter-menu active `} >
                              <label>Protocol</label>
                              <select
                                  value={selectedProtocol}
                                  onChange={(event) =>
                                    setSelectedProtocol(event.target.value)
                                  }
                              >
                                <option value="">All Protocols</option>
                                <option value={'PancakeSwap'}>Pancake Swap</option>
                                <option value={'WaultSwap'}>Wault Swap</option>
                              {/* <option  value={'Merlin'}>Merlin</option> */}
                              <option value={'Belt'}>Belt Finance</option>
                                <option value={'Alpaca'}>Alpaca Finance</option>
                              </select>
                                <label>Sort By</label>
                              <select
                                  value={selectedFilter}
                                  onChange={(event) =>
                                      setSelectedFilter(event.target.value)
                                  }
                              >
                                <option value="">Hot</option>
                                <option value={"Highest APY"}>Highest APY</option>
                                <option value={"Lowest APY"}>Lowest APY</option>
                                <option value={"Highest AUM"}>Highest AUM</option>
                                <option value={"Lowest AUM"}>Lowest AUM</option>
                              </select>
                              <div className="filter-menu-buttons">
                                <button className="filter-button reset" onClick={() => { setSelectedProtocol('');setSelectedFilter('')}}>
                                  Reset
                                </button>
                                <button className="filter-button apply">
                                  Apply
                                </button>
                              </div>
                            </div>
                            }
                          </div>
                      </div>
                    </div>
                  </Row>

                  {/* <Row style={{ paddingTop: '10px' }}>
                    
                     <Col lg="3" sm="3" className="text-center" style={{paddingTop :'10px'}}>
                      <Row>
                        <Col lg="1" sm="3" className=""></Col>
                        <Col lg="2" sm="3" className="text-right" >
                          <input type="checkbox" className="toggle-staked" checked={!!onlyShowDesposited} onChange={()=> setOnlyShowDesposited(!onlyShowDesposited)}/>
                        </Col>
                        <Col lg="9" sm="3" className="text-left">
                          <p style={{  fontSize: '1.09rem', fontWeight: '600', color : 'white'}} >STAKED ONLY </p>
                         </Col>
                      </Row>
                    </Col>

                     <Col lg="6" sm="6" className=""></Col>

                    <Col lg="1" sm="6" className="text-right">
                      <div className="app-content-actions-wrapper">
                      <div className="filter-button-wrapper">
                          <button className="action-button filter jsFilter" onClick={() => setShowFilter(!showFilter)}>
                            <div style={{textAlign:'center'}}>
                               <span style={{ padding: '15px', fontSize: '1.1rem', fontWeight: '550'}} >Filter</span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-filter">
                                  <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3" />
                                </svg>
                            </div>
                              </button>
                              {showFilter && <div className={`filter-menu active `} >
                                <label>Protocol</label>
                            <select
                                    value={selectedProtocol}
                                onChange={(event) =>
                                  setSelectedProtocol(event.target.value)
                                } >
                                  <option value="">All Protocols</option>
                                  <option value={'PanCake Swap'}>PanCake Swap</option>
                                  <option value={'Wault Swap'}>Wault Swap</option>
                                  <option  value={'Merlin'}>Merlin</option>
                                  <option value={'Alpaca'}>Alpaca</option>
                                </select>
                                <label>Sort By</label>
                                <select value={selectedFilter}
                                  onChange={(event) =>
                                    setSelectedFilter(event.target.value)
                                  }  >
                                  <option value="">Hot</option>
                                  <option value={"Highest APY"}>Highest APY</option>
                                  <option value={"Lowest APY"}>Lowest APY</option>
                                  <option value={"Highest AUM"}>Highest AUM</option>
                                  <option value={"Lowest AUM"}>Lowest AUM</option>
                                </select>
                                <div className="filter-menu-buttons">
                              <button className="filter-button reset" onClick={() => { setSelectedProtocol('');setSelectedFilter('')}}>
                                    Reset
                                  </button>
                                  <button className="filter-button apply">
                                    Apply
                                  </button>
                                </div>
                              </div>
                              }
                        </div>
                        </div>
                    </Col>
                  
                    <Col lg="2" sm="5" className="text-left">
                      <Row>
                        <Col lg="10" sm="0" className="">
                           <SearchBox searchChange={onSearchChange} />
                        </Col>
                        
                         <Col lg="2" sm="0" className=""></Col>

                      </Row>
                    </Col>
                   
               </Row> */}

                  

                  <div style={{ marginTop: '20px' }}>
                    <TabContent className="tab-space" activeTab={activeTab}>
                    <TabPane tabId="1">
                      <Row>
                        <Col md="12">
                          <StrategyListView
                            key="ALL"
                            strategies={strategies}
                            isManagerSide ={isManagerSide}
                            disableStrategies={disableStrategies}
                            searchText={searchText}
                            selectedFilter={selectedFilter}
                            selectedProtocol={selectedProtocol}
                            onlyShowDesposited={onlyShowDesposited}
                          />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="2">
                      <Row>
                        <Col md="12">
                          <StrategyListView
                              key="PanCake"
                              strategies={StakingStrategies}
                              isManagerSide ={isManagerSide}
                              disableStrategies={disableStrategies}
                              searchText={searchText}
                              selectedFilter={selectedFilter}
                              selectedProtocol={selectedProtocol}
                              onlyShowDesposited={onlyShowDesposited}
                          />
                        </Col>
                      </Row>
                      </TabPane>
                      
                       <TabPane tabId="3">
                      <Row>
                        <Col md="12">
                          <StrategyListView
                              key="Wault"
                              strategies={LendingStrategies}
                              isManagerSide ={isManagerSide}
                              disableStrategies={disableStrategies}
                              searchText={searchText}
                              selectedFilter={selectedFilter}
                              selectedProtocol={selectedProtocol}
                              onlyShowDesposited={onlyShowDesposited}
                          />
                        </Col>
                      </Row>
                    </TabPane>

                    <TabPane tabId="4">
                      <Row>
                        <Col md="12">
                          <StrategyListView
                              key="Merlin"
                              strategies={BorrowStrategies}
                              isManagerSide ={isManagerSide}
                              disableStrategies={disableStrategies}
                              searchText={searchText}
                              selectedFilter={selectedFilter}
                              selectedProtocol={selectedProtocol}
                              onlyShowDesposited={onlyShowDesposited}
                          />
                        </Col>
                      </Row>
                      </TabPane>
                      </TabContent>
                    
                  </div>
                </CardHeader>
              </Card>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Strategies;
