import React, { useEffect, useRef, useState } from 'react';
// reactstrap components
import {
  Card,
  Button,
  CardHeader,
  Badge,
  CardBody,
  CardText,
  CardFooter,
  Row,
  Col,
  Tooltip
} from 'reactstrap';
import Editable from '../../components/Editable';
import notify from '../../utils/notify';
import copy from 'copy-to-clipboard';
import HelpIcon from '@material-ui/icons/Help';
import Doughnut from '../../variables/Pie';



const DetailCards = (props) => {

  const {
    fundAbout,
    // managerName,
    isManager,
    fAddress,
    managerAddress,
    handleFundDetailsChange,
    performanceFee,
    fundStatus,
    minReturn,
    pieData
  } = props;
  const [updatedFundAbout, setUpdatedFundAbout] = useState('');
  // const [updatedManagerName, setUpdatedManagerName] = useState('');

  const [performanceFeeTooltipOpen, setPerformanceFeeTooltipOpen] = useState(false);
  const [managementFeeTooltipOpen, setManagementFeeTooltipOpen] = useState(false);
  const [minReturnTooltipOpen, setMinReturnTooltipOpen] = useState(false);

  // eslint-disable-next-line
  const togglePerformanceFeeToolTip = () => setPerformanceFeeTooltipOpen(!performanceFeeTooltipOpen);
  const toggleManagementFeeToolTip = () => setManagementFeeTooltipOpen(!managementFeeTooltipOpen);
  const toggleMinReturnToolTip = () => setMinReturnTooltipOpen(!minReturnTooltipOpen);

  const copyOnClick = () => {
    copy(managerAddress);
    notify('Address copied...');
  };

  const textareaRef = useRef();

  useEffect(() => {
    setUpdatedFundAbout(fundAbout);
    // setUpdatedManagerName(managerName);
  }, [fundAbout]);

  return (
    <React.Fragment>
      <Col lg="7">
        <Card className="card-tasks" style={{'height':'50%'}}>
          <CardHeader>
            <Row>
              <div className="justify-content-start ml-2">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <img
                    height="70"
                    width="70"
                    src={`https://robohash.org/${fAddress}`}
                    alt=""
                  />
                </a>
              </div>
              <div className="justify-content-start ml-2 " style={{ lineHeight: "1" }}>
                <h3 className="title" style={{ marginBottom: "0px", fontSize:"1.4rem" }}>Fund Manager</h3>
                {/* <p className="description" style={{ marginBottom: "2px", paddingLeft:"20px" }}>
                  Name : Anonymous
                </p> */}
                <p onClick={copyOnClick} title="Click to Copy Address" className="description" style={{ paddingTop:"10px", fontSize: "0.9rem"}}>
                  Address : {managerAddress && `${managerAddress.substring(0, 6)}...${managerAddress.substr(managerAddress.length - 5)}`}
                </p>
              </div>
            </Row>
          </CardHeader>
          <CardBody>
            {/* 
            <div>
              <div className="block block-one">
                <p>Last Active about hours ago</p>
              </div>
              <div className="block block-two mb-2">
                <Button size="sm" color="secondary" className="btn-round  ">
                  Risk 2/5
                </Button>
              </div>
              <div className="block block-three">
                <p>Holds 6% of pool</p>
              </div>
              <div className="block block-four">
                <h5>Fee </h5>{' '}
              </div>
            </div> 
            */}
            <div className="card-description ">
              <Row className="justify-content-center mt-2">
                <Col md="3" className="justify-content-center">
                  <Col md="12" >
                    <p className="title" style={{ textAlign: "center" }} >
                      Performance Fee
                    <span>
                        <HelpIcon
                          className="ml-2"
                          id="performanceFeeTooltip"
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={performanceFeeTooltipOpen}
                          target="performanceFeeTooltip"
                          autohide={true}
                          toggle={togglePerformanceFeeToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                         This is the performance fee percentage the manager will collecting on the profits made for investors.
                    </Tooltip>
                      </span>
                    </p>
                  </Col>
                  <Col md="12">
                    <p style={{ textAlign: "center" }}> {performanceFee}%</p>
                  </Col>
                </Col>
                <Col md="3">
                  <Col md="12">
                    <p style={{ textAlign: "center" }} className="title"> Management Fee
                    <span>
                        <HelpIcon
                          className="ml-2"
                          id="managementFeeTooltip"
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={managementFeeTooltipOpen}
                          target="managementFeeTooltip"
                          autohide={true}
                          toggle={toggleManagementFeeToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          Management fee percentage of which 1% will go to fund manager and 1% to Investin protocol on the investment made by investors.
                    </Tooltip>
                      </span>
                    </p>
                  </Col>
                  <Col md="12">
                    <p style={{ textAlign: "center" }} > 2%</p>
                  </Col>
                </Col>
                <Col md="3">
                  <Col md="12">
                    <p style={{ textAlign: "center" }} className="title"> Minimum Return
                    <span>
                        <HelpIcon
                          className="ml-2"
                          id="minReturnTooltip"
                          fontSize="small"
                          style={{ color: '#7639a7' }}
                        />
                        <Tooltip
                          placement="right"
                          isOpen={minReturnTooltipOpen}
                          target="minReturnTooltip"
                          autohide={true}
                          toggle={toggleMinReturnToolTip}
                          style={{ background: '#141634', color: 'white' }}
                        >
                          Minimum return percentage at which the manager will be able to collect the performance fee.
                    </Tooltip>
                      </span>
                    </p>
                  </Col>
                  <Col md="12">
                    <p style={{ textAlign: "center" }}> {minReturn}%</p>
                  </Col>
                </Col>
                <Col md="3">
                  <Col md="12">
                    <p style={{ textAlign: "center" }} className="title"> Status </p>
                  </Col>
                  <Col md="12" style={{ textAlign: "center" }}>
                    <Button style={{ textAlign: "center" }}
                      className={
                        fundStatus
                          ? 'btn-dark-cyan'
                          : 'btn-dark-cyan-danger'
                      }
                      size="sm"
                    >
                      {fundStatus ? 'Active' : 'InActive'}
                    </Button>
                  </Col>
                </Col>
              </Row>
            </div>
            <CardText />
          </CardBody>
          <CardFooter>
            {/* <CardText className="ml-1.5">
              Your Investment is locked for 24 hours after investments
            </CardText> */}
          </CardFooter>
        </Card>

        <Card className="card-tasks" style={{'height':'35%'}}>
          <CardHeader>
            <div className="timeline-heading">
              <Badge pill></Badge>
              <h6>About</h6>
            </div>
          </CardHeader>
          <CardBody>
            <Row>
              <Col xs="12">
                {isManager ?
                  <Editable
                    text={updatedFundAbout}
                    placeholder="Click here to add an about section for your fund"
                    childRef={textareaRef}
                    type="textarea"
                    style={{ color: 'white' }}
                  >
                    <textarea
                      ref={textareaRef}
                      name="description"
                      className=""
                      placeholder="Click here to add an about section for your fund"
                      rows="5"
                      cols="50"
                      value={updatedFundAbout}
                      onChange={e => setUpdatedFundAbout(e.target.value)}
                      onBlur={() => handleFundDetailsChange({ about: updatedFundAbout })}
                    />
                  </Editable>
                  :
                  <p>
                    {fundAbout}
                  </p>
                }
              </Col>
            </Row>
          </CardBody>
        </Card>

      </Col>

      <Col lg="5">
        <Card style={{ padding: '10px' }}>
                <Row className="justify-content-center">
                  <Col lg="12">
                    <Doughnut heading="Assets" data={pieData} />
                  </Col>
                </Row>
          </Card>
      </Col>
    </React.Fragment>
  );
};

export default DetailCards;