import { toast } from 'react-toastify';
import React from 'react';
import {TRX_URL} from '../utils/constants';

// options : success, warn, info
const notify = (text, type="success", autoClose=5000) =>
    toast[type](text, {
        position: 'top-right',
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
});

const TrxView = (props) => {
    console.log("url: ", `${TRX_URL}${props.trxhash}`);
    return <div>
        Processing transction ...{`  `}
         <a target="_blank" rel="noopener noreferrer" href={`${TRX_URL}${props.trxhash}`}> View on BSCScan</a> 
    </div>
}    
const notifyTrx = (trxhash,type="info", autoClose=4000) => {
    toast.info(<TrxView trxhash={trxhash}/>, {
        position: "top-right",
        autoClose: autoClose,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
}
export const sendTrxNotify = async (trxFunction, address, text) => {
    await trxFunction.send({ from: address }, (err, txhash) => {
        if (err) {
            return notify(err.message, "warn");
        }
        notifyTrx(txhash);
    });
    notify(text);
}

export const sendAmountAndTrxNotify = async (trxFunction, address, text,amountInWei) => {
    await trxFunction.send({ from: address, value : amountInWei }, (err, txhash) => {
        if (err) {
            return notify(err.message, "warn");
        }
        notifyTrx(txhash);
    });
    notify(text);
}
 

export default notify;