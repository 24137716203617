/*!

=========================================================
* Black Dashboard React v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/black-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from 'react';
import { NavLink, Link } from 'react-router-dom';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
import { Divider } from '@material-ui/core';

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from 'perfect-scrollbar';

// reactstrap components
import { Nav, NavLink as ReactstrapNavLink, Row, Col, Button as ReactstrapButton } from 'reactstrap';
import { Grid, Button } from '@material-ui/core';
import LightIcon from '@material-ui/icons/WbSunny';
import DarkIcon from '@material-ui/icons/NightsStay';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
// import FundIcon from "@material-ui/icons/EmojiObjectsOutlined";
// import TradeIcon from "@material-ui/icons/PeopleOutlined";
// import MarketIcon from "@material-ui/icons/TimelineOutlined";
import InvestmentIcon from '@material-ui/icons/MonetizationOnOutlined';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
// import WhatshotIcon from '@material-ui/icons/Whatshot';
import BulbIcon from '@material-ui/icons/EmojiObjects'
import MuseumIcon from '@material-ui/icons/Museum';
import LiveHelpIcon from '@material-ui/icons/LiveHelp';
import InfoIcon from '@material-ui/icons/Info';
import CommentRoundedIcon from '@material-ui/icons/CommentRounded';
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';

import TelegramIcon from '@material-ui/icons/Telegram';
import TwitterIcon from '@material-ui/icons/Twitter';
import DescriptionIcon from '@material-ui/icons/Description';
// import { ReactComponent as MediumIcon } from '../../assets/img/medium.svg'
import { ReactComponent as DiscordIcon } from '../../assets/img/discord.svg'
import { ReactComponent as TractorIcon } from '../../assets/img/sidebar-icons/tractor.svg';

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.activeRoute.bind(this);
  }
  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    return this.props.location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }
  // logout = () => {
  //   dispatch(setGlobalWeb3(undefined));
  //   dispatch(setGlobalInvestments(undefined));
  //   history.push('../../user/dashboard');
  // }

  componentDidMount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf('Win') > -1) {
      ps.destroy();
    }
  }
  linkOnClick = () => {
    document.documentElement.classList.remove('nav-open');
  };
  handleClick = () => {
    if (this.state.classes === 'dropdown show-dropdown') {
      this.setState({ classes: 'dropdown show-dropdown show' });
    } else {
      this.setState({ classes: 'dropdown show-dropdown' });
    }
  };
  activateMode = (mode) => {
    switch (mode) {
      case 'light':
        document.body.classList.add('white-content');
        break;
      default:
        document.body.classList.remove('white-content');
        break;
    }
  };

  getIcon = (iconImageName) => {
    switch (iconImageName) {
      case 'DashboardIcon':
        return <DashboardIcon style={{ color: '#9595A9' }} />;
        break;
      case 'FundIcon':
        return <MuseumIcon style={{ color: '#9595A9' }} />;
        break;
      case 'TradeIcon':
        return <InvestmentIcon style={{ color: '#9595A9' }} />;
        break;
        case 'BulbIcon':
          return <BulbIcon style={{ color: '#9595A9' }} />;
          break;
      case 'MarketIcon':
        return <TrendingUpIcon style={{ color: '#9595A9' }} />;
        break;
      case 'DetailsIcon':
        return <InfoIcon style={{ color: '#9595A9' }} />;
        break;
      case 'Staking':
        return <TractorIcon style={{ fontSize: '2em', color: '#9595A9' }} />;
        break;
      case 'FaqIcon':
        return <LiveHelpIcon style={{ color: '#9595A9' }} />;
        break;
      case 'CommentRoundedIcon':
        return <CommentRoundedIcon style={{ color: '#9595A9' }} />;
        break;
      case 'LiveHelpRoundedIcon':
        return <LiveHelpRoundedIcon style={{ color: '#9595A9' }} />;
        break;
      default:
        // document.body.classList.remove("white-content");
        break;
    }
  };

  render() {
    const { bgColor, routes, rtlActive, logo } = this.props;
    let logoImg = null;
    let logoText = null;

    if (logo !== undefined) {
      if (logo.outterLink !== undefined) {
        logoImg = (
          <a
            href={logo.outterLink}
            className="simple-text logo-mini"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </a>
        );
        logoText = (
          <a
            href={logo.outterLink}
            className="simple-text logo-normal"
            target="_blank"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </a>
        );
      } else {
        logoImg = (
          <Link
            to={logo.innerLink ? logo.innerLink : '/'}
            className="simple-text logo-mini"
            onClick={this.props.toggleSidebar}
          >
            <div className="logo-img">
              <img src={logo.imgSrc} alt="react-logo" />
            </div>
          </Link>
        );
        logoText = (
          <Link
            to={logo.innerLink ? logo.innerLink : '/'}
            className="simple-text logo-normal"
            onClick={this.props.toggleSidebar}
          >
            {logo.text}
          </Link>
        );
      }
    }
    return (
      <div className="sidebar" data={bgColor}>
        <div className="sidebar-wrapper" ref="sidebar">
          {logoImg !== null || logoText !== null ? (
            <div className="logo">
              {logoImg}
              {logoText}
            </div>
          ) : null}

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
              background: '#4055d4',
              border: 'none',
              color: 'white',
              padding: '0px 0px 0px 16px',
              cursor: 'pointer',
              // borderColor: '#3F51B5',
              fontFamily: 'poppins',
              borderRadius: '30px',
              height: 37,
            }}
          >
            <NavLink to="/user/dashboard">
              <Button
                variant="outlined"
                style={{
                  color: 'white',
                  borderColor: '#3F51B5',
                  fontFamily: 'poppins',
                }}
              >
                Start Investing
              </Button>{' '}
            </NavLink>
          </div>

          <Nav>
            {routes.map((prop, key) => {
              if (prop.redirect) return null;
              return (
                <li
                  className={
                    this.activeRoute(prop.path) +
                    (prop.pro ? ' active-pro' : '') +
                    (prop.style ? ` ${prop.style}` : '')
                  }
                  key={key}
                >
                  <NavLink
                    to={prop.layout + prop.path}
                    className="nav-link"
                    activeClassName="active"
                    onClick={this.props.toggleSidebar}
                  >
                    <i className={prop.icon} />
                    <Row>
                      <Col lg="2" xs="2">
                        {this.getIcon(prop.iconImageName)}
                      </Col>
                      <Col lg="1" xs="1">
                        <p style={{ fontSize: '11px' }}>
                          {rtlActive ? prop.rtlName : prop.name}
                        </p>
                      </Col>
                    </Row>
                  </NavLink>
                </li>
              );
            })}

              <li key="docs" >
              <a href="https://investin-pro.github.io/Investin_docs/manager/" target="_blank" className="nav-link">
                      <Row>
                        <Col lg="2" xs="2">
                                <DescriptionIcon style={{color:'rgb(149 149 169)'}} />
                        </Col>
                        <Col lg="1" xs="1">
                          <p style={{ fontSize: '11px' }}>DOCS
                          </p>
                    </Col>
                    
                      </Row>
                  </a>
                </li>
            
            <hr color="#9595A9" style={{ width: '85%' }} />
           

          {/* LIGHTMODE DARK MODE */}
            {/* <Grid container justifyContent="space-evenly">
              <Button
                variant="outlined"
                style={{
                  color: 'white',
                  borderColor: '#3F51B5',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => this.activateMode('light')}
              >
                <LightIcon />
              </Button>
              <Button
                variant="outlined"
                style={{
                  color: 'white',
                  borderColor: '#3F51B5',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                }}
                onClick={() => this.activateMode('dark')}
              >
                <DarkIcon />
              </Button>
            </Grid> */}

              {/* WILL FRIST NEED TO CHANGE TO FUNCTION BASED COMPONENT TO USE LOGOUT
                 AS IT NEED REDUX STORE
                */}
             {/* {
              web3 && <Grid style={{ marginTop: '20px' }} container justifyContent="center">
              <ReactstrapButton className="btn-main-theme-secondary" onClick={logout}>Logout</ReactstrapButton>
              </Grid>
            } */}

            <Grid style={{ marginTop: '30px' }} container justifyContent="center" >
              <a href="https://t.me/Investin_pro1" target="_blank">
                <TelegramIcon style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }} />
              </a>
              <a href="https://twitter.com/Investin_pro?s=20" target="_blank">
                <TwitterIcon style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }} />
              </a>
              <a href="https://discord.com/invite/Yf54h9B" target="_blank">
                <DiscordIcon  style={{
                  color: '#7092ff',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }}/>
              </a>
              {/* <a href="https://medium.com/investin-pro" target="_blank">
                <MediumIcon  style={{
                  color: 'white',
                  margin : "5px",
                  width: "25px",
                  height: "25px"
                }}/>
              </a> */}
            </Grid>

            <Grid style={{ marginTop: '30px' }} container justifyContent="center">
              <div
                style={{ color: 'white', fontSize: '10px' }}
                className="copyright"
              >
                © {new Date().getFullYear()} Investin , all rights reserved{' '}
              </div>
            </Grid>
          </Nav>
        </div>
      </div>
    );
  }
}

Sidebar.defaultProps = {
  rtlActive: false,
  bgColor: 'primary',
  routes: [{}],
};

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  bgColor: PropTypes.oneOf(['primary', 'blue', 'green']),
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
