const SET_FACTORY_ADDRESS = 'SET_FACTORY_ADDRESS';

const factoryAddressReducer = (state = '', action) => {
  const factoryAddress = action.factoryAddress;
  switch (action.type) {
    case SET_FACTORY_ADDRESS:
      return {
        factoryAddress,
      };
    default:
      return state;
  }
};

export default factoryAddressReducer;
