import { Token, WETH, Percent, TokenAmount } from "@overage69/pancake-sdk-v2";
// import { ChainId, Token, WETH, Route, Fetcher, TradeType, Trade, Percent, TokenAmount } from "@uniswap/sdk";


import {CHAIN_ID} from '../utils/constants';
import { getTradeExactIn } from "./dexData/fetchTrade";


export const getDexData = async (web3, token1Address, token1Amount, token2Address, routerAddress) => {

    if (!web3) return;

    const chainId = parseInt(CHAIN_ID);
    const decimals = 18;

    if (token1Address === '0x') token1Address = WETH[chainId].address;
    if (token2Address === '0x') token2Address = WETH[chainId].address;

    const token2 = new Token(chainId, token2Address, decimals)
    const token1 = new Token(chainId, token1Address, decimals)
    
    let nowAmt = 0, trade, pathAddresses, priceImpact;
   
    try {
        // await getAllCommonPairs(token1, token2, chainId)
        trade = await getTradeExactIn(token1, token2,  new TokenAmount(token1, web3.utils.toWei(token1Amount)),chainId, routerAddress)
        console.log(`finalResult :::: `, trade);
        const slip = new Percent('1', '100');
        nowAmt = trade.minimumAmountOut(slip).toSignificant(18);
        console.log("nowAmt : ", nowAmt);
        pathAddresses = trade.route.path.map(i => i.address);
        console.log("pathAddresses : ", pathAddresses);
        priceImpact = trade.priceImpact.toFixed(2) - 0.2; // LPfees of 0.2%
        console.log('priceImpact : ', priceImpact);
       
    } catch (error) {
        console.error("error in allCommonPairs : ", error)
    }
    return { amount: nowAmt, pathAddresses: pathAddresses , priceImpact };
 }
