import { ROUTER_PANCAKESWAP_ADDR, ROUTER_WAULTSWAP_ADDR ,ROUTER_DEFI_ADDR} from "../../utils/constants";

const SET_APPROVED_AMT = 'SET_APPROVED_AMT';

const defaultState = {
  [ROUTER_PANCAKESWAP_ADDR]: 0,
  [ROUTER_WAULTSWAP_ADDR]: 0,
  [ROUTER_DEFI_ADDR] : 0,
}

const approvedAmtReducer = (state = defaultState, action) => {
  const allowance = action.allowance;
  switch (action.type) {
    case SET_APPROVED_AMT:
      return {
        allowance: {
          ...state,
          ...allowance
        }
      };
    default:
      return state;
  }
};

export default approvedAmtReducer;
