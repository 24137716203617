import { ethers } from 'ethers';
import flatMap from 'lodash.flatmap'
import { routerToDexDataMapping } from "../../utils/routerToDexDataMapping";


// **** DYAMIC as per BSC or Kovan 
import {
  ChainId, Token,
  // TokenAmount, Pair, Currency,
  WETH, ETHER, Fetcher, Trade
} from "@overage69/pancake-sdk-v2";
import { JSON_RPC_PROVIDER_URL } from '../../utils/constants';
// import { Pair as V2Pair } from "@overage69/pancake-sdk-v2";
// import { ChainId, Token, TokenAmount, Pair, Currency, WETH, ETHER, Fetcher, Trade } from "@uniswap/sdk";

// const provider = new ethers.providers.JsonRpcProvider('https://bsc-dataseed1.binance.org:443')
// const provider = new ethers.providers.JsonRpcProvider('https://eth-kovan.alchemyapi.io/v2/x1AtN9q0LONYV7GbAHsUbAoQCd8ZvXGo')
const provider = new ethers.providers.JsonRpcProvider(JSON_RPC_PROVIDER_URL);



export const CAKE = new Token(ChainId.MAINNET, '0x0E09FaBB73Bd3Ade0a17ECC321fD13a19e81cE82', 18, 'CAKE', 'PancakeSwap Token')
export const WBNB = new Token(ChainId.MAINNET, '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c', 18, 'WBNB', 'Wrapped BNB')
export const DAI = new Token(ChainId.MAINNET, '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', 18, 'DAI', 'Dai Stablecoin')
export const BUSD = new Token(ChainId.MAINNET, '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', 18, 'BUSD', 'Binance USD')
export const BTCB = new Token(ChainId.MAINNET, '0x7130d2A12B9BCbFAe4f2634d864A1Ee1Ce3Ead9c', 18, 'BTCB', 'Binance BTC')
export const USDT = new Token(ChainId.MAINNET, '0x55d398326f99059fF775485246999027B3197955', 18, 'USDT', 'Tether USD')
export const UST = new Token(
  ChainId.MAINNET,
  '0x23396cF899Ca06c4472205fC903bDB4de249D6fC',
  18,
  'UST',
  'Wrapped UST Token'
)
export const ETH = new Token(
  ChainId.MAINNET,
  '0x2170Ed0880ac9A755fd29B2688956BD959F933F8',
  18,
  'ETH',
  'Binance-Peg Ethereum Token'
)

const KovanToken = new Token(42, '0xd0A1E359811322d97991E03f863a0C30C2cF029C', 18, 'WETH', 'Wrapped ETH');

const WETH_ONLY = {
  [ChainId.MAINNET]: [WETH[ChainId.MAINNET]],
  [ChainId.BSCTESTNET]: [WETH[ChainId.BSCTESTNET]],
  42: [KovanToken]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, BUSD, BTCB, USDT, UST, ETH],
}

export function wrappedCurrency(currency, chainId) {
  // eslint-disable-next-line no-nested-ternary
  return chainId && currency === ETHER ? WETH[chainId] : currency instanceof Token ? currency : undefined
}

export const CUSTOM_BASES = {
  [ChainId.MAINNET]: {},
}


export async function getPairs(currencies, routerAddress) {
  const chainId = 56;
  // console.log(`currencies ::: `, currencies)
  const tokens = currencies.map(([currencyA, currencyB]) => [
    wrappedCurrency(currencyA, chainId),
    wrappedCurrency(currencyB, chainId)
  ]);

  // console.log("tokens : ", tokens)

  let pairs = [];

  const promises = [];

  for (const [tokenA, tokenB] of tokens) {
    try {
      if (tokenA && tokenB && !tokenA.equals(tokenB)) {
        promises.push(Fetcher.fetchPairData(tokenA, tokenB, provider, routerToDexDataMapping[routerAddress].factoryAddress, routerToDexDataMapping[routerAddress].factoryInitCode));
      }
    } catch (error) {
      console.error("creating pairs error : ", error)
    }
  }
  try {
    const results = await Promise.allSettled(promises);
    pairs = results.filter((results) => results.status === "fulfilled").map((r) => r.value);
    // console.log("results : ", results)
    // console.log(`pairs : `, pairs)
  } catch (error) {
    console.log(`creating pairs 2 :::`, error);
  }
  return pairs;
}


export async function getAllCommonPairs(currencyA, currencyB, chainId, routerAddress) {

  // Base tokens for building intermediary trading routes
  const bases = (chainId ? BASES_TO_CHECK_TRADES_AGAINST[chainId] : [])

  // All pairs from base tokens
  const basePairs = flatMap(bases, (base) => bases.map((otherBase) => [base, otherBase])).filter(
    ([t0, t1]) => t0.address !== t1.address
  )

  const [tokenA, tokenB] = chainId
    ? [wrappedCurrency(currencyA, chainId), wrappedCurrency(currencyB, chainId)]
    : [undefined, undefined]

  // 2d  [ ] 
  const allPairCombinations = () => {
    if (tokenA && tokenB) {

      return [
        // the direct pair
        [tokenA, tokenB],
        // token A against all bases
        ...bases.map((base) => [tokenA, base]),
        // token B against all bases
        ...bases.map((base) => [tokenB, base]),
        // each base against all bases
        ...basePairs,
      ]
        .filter((tokens) => Boolean(tokens[0] && tokens[1]))
        .filter(([t0, t1]) => t0.address !== t1.address)
        // This filter will remove all the pairs that are not supported by the CUSTOM_BASES settings
        // This option is currently not used on Pancake swap
        .filter(([t0, t1]) => {
          if (!chainId) return true
          const customBases = CUSTOM_BASES[chainId]
          if (!customBases) return true

          const customBasesA = customBases[t0.address]
          const customBasesB = customBases[t1.address]

          if (!customBasesA && !customBasesB) return true
          if (customBasesA && !customBasesA.find((base) => t1.equals(base))) return false
          if (customBasesB && !customBasesB.find((base) => t0.equals(base))) return false

          return true
        })

    } else {
      return [];
    }
  }
  
  // console.log("allPairCombinations combis : ", allPairCombinations())
  const allPairs = await getPairs(allPairCombinations(), routerAddress)
  const newPairs = allPairs.filter((thing, index, self) => index === self.findIndex((t)=> (t.liquidityToken.address === thing.liquidityToken.address)))
  return newPairs;
  // only pass along valid pairs, non-duplicated pairs

}

export async function getTradeExactIn(token1, token2, currencyAmountIn, chainId, routerAddress) {
  const allowedPairs = await getAllCommonPairs(token1, token2, chainId, routerAddress);

  if (currencyAmountIn && token2 && allowedPairs.length > 0) {
    return (Trade.bestTradeExactIn(allowedPairs, currencyAmountIn, token2, { maxHops: 3, maxNumResults: 1 })[0] ?? null);
  }
  return null
}
