import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import React, {
  // useEffect,
   useState
} from 'react';
// redux web3 state
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
// import { addGlobalInvestment } from "redux/actions";
// import constants from "../utils/constants.js";

import { Card, Col, Row } from 'reactstrap';
import '../assets/css/GridView.css';
import LineGraph from '../components/customLine';
// import InvestinModal from './InvestinModal'; 
import {InvestinDepositModal} from './InvestinDepositModal';

import WalletProviderPopup from '../layouts/Landing/ConnectWalletModal';

const FundGridCard = (props) => {

  const { fund } = props;

  // { address: fundAddress, name: fundName, minAmount, minReturn, performanceFeePercentage, currentPerformance: '10', totalAmount: baseTokenValue, baseTokenAddress }
  const {
    fundAddress,
    fundName,
    minAmount,
    currentPerformance: fundPerformance,
    totalAmount: fundAUM,
    labels,
    data,
    fundFactory,
    routerAddress  
  } = fund;

  const web3 = useSelector((state) => state.web3Reducer.web3);
  const address = useSelector((state) => state.addressReducer.address);
  const [openModal, setOpenModal] = useState(false);

  const displayConnectWalletPopup = (value) => {
    setOpenModal(!openModal);
  };


  return (
    <div className="Fund">
      {openModal && (
        <WalletProviderPopup
          open={openModal}
          handleClose={displayConnectWalletPopup}
          onWeb3Connect={() => console.log("connected")}
        />
      )}
      <Card style={{ padding: '10px' }} className="justify-content-center">
        <Row style={{ marginTop: '5px' }} className="justify-content-between">
          <Col lg="3" xs="3">
            {fundAddress && <img
              height="35"
              width="35"
              style={{ marginTop: '-4px' }}
              src={`https://robohash.org/${fundAddress}`}
              alt=""
            />}
          </Col>

          <Col lg="6" xs="6">
            <Row className="justify-content-between">
              <Col lg="12" xs="12">
                <p
                  style={{
                    fontSize: '1.4em',
                    fontFamily: 'Heebo, Gadget, sans-serif',
                  }}
                >
                  <Link className="link-funds" to={`/${window.location.href.includes('user') ? 'user' : 'manager'}/details/${fundAddress}`}>
                    {fundName &&
                      (fundName.length > 21) ?
                      `${fundName.substring(0, 21)}..  `
                      :
                      fundName
                    }
                    </Link>
                </p>
              </Col>
            </Row>
          </Col>

          <Col lg="2" xs="2">
            <Link to={`/${window.location.href.includes('user') ? 'user' : 'manager'}/details/${fundAddress}`}>
              <MoreHorizIcon style={{ color: 'rgba(255, 255, 255, 0.6)' }} />
            </Link>
          </Col>
        </Row>
        <Row style={{ marginTop: '30px' }} className="justify-content-center">
          <Col lg="10">
            <Row className="justify-content-center">
              <Col lg="7">
                <p>Performance</p>
              </Col>
              <Col lg="5">
                <p className="text-right">{`${parseFloat(fundPerformance).toFixed(4)}`} %</p>
                {/* <p>{Fund.performance}%</p> */}
              </Col>
            </Row>
          </Col>
        </Row>

        <Row style={{ marginTop: '10px' }} className="justify-content-center">
          <Col lg="10">
            <Row className="justify-content-around">
              <Col lg="7">
                <p>AUM</p>
              </Col>
              <Col lg="5">
                <p className="text-right">
                  {'$'}
                  {fundAUM &&
                    parseFloat(fundAUM).toFixed(2)}{' '}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '10px' }} className="justify-content-center">
          <Col lg="10">
            <Row className="justify-content-around align-items-center">
              <Col lg="7">
                <p>Last 7 Days</p>
              </Col>
              <Col lg="5">
                <div>
                  <LineGraph data={data} labels={labels} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }} className="justify-content-center">
          {
            !web3 && <button className="btn btn-round btn-submit" onClick={displayConnectWalletPopup}>
              Investin
            </button>
          }
          {
            web3 &&
            // <InvestinModal
            //   web3={web3}
            //   fund={{ name: fundName, address: fundAddress, fundFactory, routerAddress }}
            //   minAmount={parseFloat(minAmount)}
            //   address={address}
            //   disabled={minAmount < 0}
            // />
            <InvestinDepositModal
                web3={web3}
                fund={{ name: fundName, address: fundAddress, fundFactory, routerAddress }}
                minAmount={parseFloat(minAmount)}
                address={address}
                disabled={minAmount < 0}
                afterInvestCallback={()=>console.log("investment done!!")}
                            />
          }
        </Row>
      </Card>
    </div>
  );
};

export default FundGridCard;
