import React, { useEffect, useRef, useState } from 'react';

import { ethers } from 'ethers';
import { ethersProvider } from '../../utils/web3/getProvider';

import 'react-toastify/dist/ReactToastify.css';

// redux web3 state
import { useSelector } from 'react-redux';
// import FundA from '../../contracts/Fund.json';
import { ReactComponent as TractorIcon } from '../../assets/img/NoOfActiveInvestments.svg';
import { ReactComponent as MoneyIcon } from '../../assets/img/money.svg';

import pancakeSwapLogo from '../../assets/img/pancakeswap.png';
import waultLogo from '../../assets/img/wault.png';
// import defiFundLogo from '../../assets/img/IVN-defi.png';


import copy from 'copy-to-clipboard';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Spinner,
} from 'reactstrap';


import { API_URL, INVESTIN_MULTI_CALL, ROUTER_PANCAKESWAP_ADDR, ROUTER_DEFI_ADDR } from '../../utils/constants';
import { useParams } from 'react-router';
import DetailCards from './detailCards';

import notify from '../../utils/notify';
import { useHistory } from 'react-router';
import Editable from '../../components/Editable';
import WalletProviderPopup from '../../layouts/Landing/ConnectWalletModal';
// import { investinMulticall2Abi } from '../../utils/getAbi';
import Fusion_V3_V4_MulticallAbi from '../../contracts/Fusion_V3_V4_Multicall.json';

import getAssetsWithBalancesUsingEthers from '../../utils/getContractAssetsUsingEthers';
import {
  getFactoryAddress,
  // getRouterAddress
} from '../../utils/getAbi';
import { InvestinDepositModal } from '../../components/InvestinDepositModal';
import { getStrategiesWithBalances } from '../../utils/strategyHelper';
// import InvestinModal from '../../components/InvestinModal';

import Odometer from 'react-odometerjs';
// import 'odometer/themes/odometer-theme-default.css';
import { CustomAreaChart } from 'investin-ui-components-package';


const Tables = () => {
  // const dispatch = useDispatch();
  let { fAddress } = useParams();
  const history = useHistory();

  const web3 = useSelector((state) => state.web3Reducer.web3);
  const address = useSelector((state) => state.addressReducer.address);
  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );

  const [paramFundAddress, setParamFundAddress] = useState('')
  const [fundName, setFundName] = useState(null);
  const [fundAbout, setFundAbout] = useState("");
  const [minAmount, setMinAmount] = useState(0);
  const [minReturn, setMinReturn] = useState(0);
  const [managerAddress, setManagerAddress] = useState('');
  const [routerAddress, setRouterAddress] = useState('');
  const [fundFactory, setFundFactory] = useState('');
  const [allTimePerformance, setAllTimePerformance] = useState(0);
  const [oneDayPerformance, setoneDayPerformance] = useState(0);
  const [weekPerformance, setWeekPerformance] = useState(0);
  const [monthPerformance, setMonthPerformance] = useState(0);

  const [chartData, setChartData] = useState(undefined);
  const [hoverValue, setHoverValue] = useState(0);

  const [loading, setLoading] = useState(true);
  const [isManager, setIsManager] = useState(false);

  const [chartTimeframe, setChartTimeframe] = useState(30);

  const [aum, setAum] = useState(0);
  const [numberOfActiveInvestments, setNumberOfActiveInvestments] = useState(0)
  const [fundStatus, setFundStatus] = useState(false);
  const [performanceFee, setPerformanceFee] = useState(0);
  const [pieData, setPieData] = useState([]);


  const [openModal, setOpenModal] = useState(false);

  const displayConnectWalletPopup = (value) => {

    setOpenModal(!openModal);
  };

  const handleFundNameChange = (event) => {
    if (event.key === 13) {
      console.log("handleFundNameChange enter pressed");
    }
    console.log("handleFundNameChange fund name:", event.target.value)
    setFundName(event.target.value);
  }

  useEffect(() => {

    if (!fAddress && (!fundAddress || fundAddress === '0x0000000000000000000000000000000000000000')) {
      alert('please create a fund to view this page (manager-details) ');
      history.push('../../user/dashboard');
      return;
    }

    let fundFactoryAddr; // needed below 

    (async () => {
      
      if (!fAddress) {
        // since fAddress assigning warning   Assignments to the 'fAddress' variable from inside React Hook useEffect will be lost after each render.
        // To preserve the value over time, store it in a useRef Hook and keep the mutable value in the '.current' property
         // eslint-disable-next-line 
        fAddress = fundAddress;
        // console.log(`fAddress :::: `, fAddress);
      }
      setParamFundAddress(fAddress);

      try {
        const investinMulticallInstance = new ethers.Contract(INVESTIN_MULTI_CALL, Fusion_V3_V4_MulticallAbi, ethersProvider);
        // console.log('investinMulticallInstance:', investinMulticallInstance);
        let fDetails = await investinMulticallInstance.getFundDetailsMulti(fAddress);
        let { managerAddress, managerFundStatus, minAmount, minReturn, performanceFeePercentage, baseTokenValue, numberOfActiveInvestments, routerAddress } = fDetails;
        // console.log('getFundDetailsMulti :', fDetails);

        fundFactoryAddr = getFactoryAddress(routerAddress);
        setFundFactory(fundFactoryAddr);
        setRouterAddress(routerAddress);
        setManagerAddress(managerAddress);
        setMinAmount(parseFloat(`${ethers.utils.formatEther(minAmount.toString(), 'ether')}`).toFixed(4));
        setAum(parseFloat(`${ethers.utils.formatEther(baseTokenValue.toString(), 'ether')}`).toFixed(4));
        setNumberOfActiveInvestments(numberOfActiveInvestments.toString());
        setFundStatus(managerFundStatus);
        if (routerAddress === ROUTER_DEFI_ADDR) {
          performanceFeePercentage = (((parseFloat(performanceFeePercentage.toString()) / 100000000)).toFixed(4) * 100);
          setMinReturn(parseFloat(parseFloat(minReturn.toString()) - 100000000) / 1000000);
          // console.error("TESTING performanceFeePercentage:", performanceFeePercentage);
        } else {
          performanceFeePercentage = (((parseFloat(performanceFeePercentage.toString()) / 10000)).toFixed(4) * 100);
          setMinReturn(parseFloat(parseFloat(minReturn.toString()) - 10000) / 100);
          // console.error("else TESTING performanceFeePercentage:", performanceFeePercentage);
        }
        setPerformanceFee(performanceFeePercentage);

      
          //  console.error('fetching assets routerAddress......:',routerAddress);
          if (routerAddress && routerAddress !== '0x0000000000000000000000000000000000000000') {
            // console.error("routerAddress  SET");
              try {
                const data = await getAssetsWithBalancesUsingEthers(routerAddress,true,fAddress,true);
                // console.log('FETCHED getAssetsWithBalancesUsingEthers: ');
                setPieData(data);
                if (routerAddress === ROUTER_DEFI_ADDR) {
                      let stData = await getStrategiesWithBalances(fAddress);
                      setPieData([...data, ...stData]);
                    }
              } catch (error) {
                console.error('getAssetsWithBalancesUsingEthers error: ', error);
              }
              // console.log('assets fetched ......');
          } else {
            // console.log("routerAddress not set so not asssets fetched");
          }

        try {
          const { fund } = await (await fetch(
            `${API_URL}/fund/${fAddress}/decentralised`
          )).json();
          // console.log(`fund deatils from api : response : `, fund)

          if (fund) {
            setFundName(fund.name)
            setFundAbout(fund.about)
          }

          const data = await fetchPerformanceData(30);
          setAllTimePerformance(Object.values(data[`30_days_data`]).slice(-1)[0].toFixed(2));
          let days = data[`${chartTimeframe}_days_data`];
          let dayKeys = Object.keys(days);
          let performance24hour = (days[dayKeys[29]] - days[dayKeys[28]]).toFixed(2);
          setoneDayPerformance(performance24hour);
          const t30d = (days[dayKeys[29]] - days[dayKeys[0]]).toFixed(2);
          const tweekr = (days[dayKeys[29]] - days[dayKeys[22]]).toFixed(2);
          setMonthPerformance(t30d);
          setWeekPerformance(tweekr);
        } catch (error) {
          console.error("fetch chart:", error);
        }


      } catch (error) {
        console.error('useEffect getFundDetails error: ', error);
      }


    })();

  }, [fAddress ]);// eslint-disable-line react-hooks/exhaustive-deps
  
  useEffect(() => {
    // console.log(`setIsManager fundAddress : `, fundAddress)
    // console.log(`setIsManager fAddress : `, fAddress)
    
    if(!fAddress){
      fAddress = paramFundAddress;
    }
    setIsManager(fundAddress !== '' && fundAddress === fAddress && fundAddress !== '0x0000000000000000000000000000000000000000');
  }, [fundAddress]);


  

  useEffect(() => {
    setLoading(true);
    (async () => {
      try {
        const data = await fetchPerformanceData()
        if (data) {
          console.log("chart data from API :", data)

          let days = data[`${chartTimeframe}_days_data`];
          let x = [];
          for (const [key, value] of Object.entries(days)) {
            x.push({ timestamp: key, value: value })
          }
          // console.log("x :", x);

          setChartData(x);
          setHoverValue(x[x.length - 1].value)
        }
      } catch (error) {
        console.error('error in api :', error);
      }
      setLoading(false);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTimeframe]);

  const fetchPerformanceData = async () => {
    if(!fAddress){
      fAddress = paramFundAddress;
    }
    const response = await fetch(
      `${API_URL}/fund/${fAddress}/decentraisedPerformance/days/${chartTimeframe}`
    );
    return await response.json();
  }

  const copyOnClick = () => {
    // console.log("fundaddress fAddress", fundAddress, fAddress);
    if (!fAddress)
      fAddress = fundAddress
    copy(fAddress);
    notify('Address copied...');
  };



  const handleFundDetailsChange = async ({ name, managerName, about }) => {
    const timestamp = Date.now();
    console.log('message : ', timestamp);
    const signature = await web3.eth.personal.sign(
      timestamp.toString(),
      address
    );
    console.log("fundchange : ", name)

     await fetch(
      `${API_URL}/fund/${fundAddress}/updateFundDetails`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fundName: name,
          managerName,
          about,
          signature,
          timestamp,
        }),
      }
    );
  }



  const inputRef = useRef();

  const onKeyPressFundName = (e) => {
    // console.log("e.keyCode e", e);

    // console.log("e.keyCode", e.keyCode);

    // console.log("e.key", e.key);
    if (e.key === 'Enter') {
      handleFundNameChange(e);
      // console.log("enter pressed text:", e.target.value);
      handleFundDetailsChange({ name: fundName })
    }
  }

  return (
    <>
      <div className="content ">
        {openModal && (
          <WalletProviderPopup
            open={openModal}
            handleClose={displayConnectWalletPopup}
            onWeb3Connect={() => console.log("connected")}
          />
        )}
        <Row>
          <Col md="12">
            <Card>
              <CardBody>
                <Row>
                  <Col md="0.5" className="d-flex justify-content-left align-items-left">
                    <div className="justify-content-start ml-2">
                      <img
                        height="50"
                        width="50"
                        src={`https://robohash.org/${fAddress}`}
                        alt=""
                      />
                    </div>
                  </Col>
                  <Col>
                    {
                      isManager ?
                        <div className="justify-content-start ml-2 ">
                          <Editable
                            text={fundName}
                            placeholder="Click here to set a name for your fund"
                            childRef={inputRef}
                            type="input"
                            style={{ color: 'white' }}
                            labelText="Fund name : "
                          >
                            <input
                              id="one"
                              ref={inputRef}
                              type="text"
                              name="task"
                              className=""
                              placeholder="Click here to set a name for your fund"
                              value={fundName}
                              maxLength={21}
                              onKeyPress={onKeyPressFundName}
                              onChange={handleFundNameChange}
                              onBlur={() => handleFundDetailsChange({ name: fundName })}
                            />
                          </Editable>
                          <p onClick={copyOnClick} title="Click to Copy Address">
                            Fund address : {paramFundAddress}
                          </p>
                        </div>
                        :
                        <div className="justify-content-start ml-2 ">
                          <p style={{ fontWeight: 'bold' }}>{fundName ?? "Fund name : Anonymous"} </p>
                          <p onClick={copyOnClick} title="Click to Copy Address">
                            {paramFundAddress}
                          </p>
                        </div>
                    }
                  </Col>
                  {routerAddress && routerAddress !== ROUTER_DEFI_ADDR &&
                    <Col md="2" className="swapLogo d-flex justify-content-right align-items-right">
                      <p style={{ paddingTop: "1rem" }}>Powered by </p>

                      <img
                        src={routerAddress === ROUTER_PANCAKESWAP_ADDR ?
                          pancakeSwapLogo : waultLogo
                        }
                        alt="dex logo"
                        style={{
                          backgroundColor: (routerAddress === ROUTER_PANCAKESWAP_ADDR) ? "none" : "white",
                        }}
                      />
                    </Col>
                  }
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Container fluid size="md">
          <Row>
            <Col md="3" sm="6" className="text-center mb-3 mt-3">
              <p style={{ fontSize: '12px' }}>24H Change </p>
              <p
                style={{
                  fontSize: '15px',
                  color: oneDayPerformance >= 0 ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {oneDayPerformance} %
              </p>
            </Col>
            <Col md="3" sm="6" className="text-center mb-3 mt-3">
              <p style={{ fontSize: '12px' }}>1W Change </p>
              <p
                style={{
                  fontSize: '15px',
                  color: weekPerformance >= 0 ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {weekPerformance} %
              </p>
            </Col>
            <Col md="3" sm="6" className="text-center mb-3 mt-3">
              <p style={{ fontSize: '12px' }}>1M Change </p>
              <p
                style={{
                  fontSize: '15px',
                  color: monthPerformance >= 0 ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {monthPerformance} %
              </p>
            </Col>
            <Col md="3" sm="6" className="text-center mb-3 mt-3">
              <p style={{ fontSize: '12px' }}>All Time </p>
              <p
                style={{
                  fontSize: '15px',
                  color: allTimePerformance >= 0 ? 'green' : 'red',
                  fontWeight: 'bold',
                }}
              >
                {allTimePerformance} %
              </p>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Card className="card-chart"  style={{ backgroundColor: '#151839 !important', borderRadius: "15px" }}>
                <CardHeader>
                  <Row>
                    <Col className="text-left" sm="4">
                      <h5 className="card-category font-weight-bold">
                        Performance
                      </h5>
                      <CardTitle tag="h2" className={`text-left ${hoverValue >= 0 ? 'white-odometer' : 'red-odometer'}`}>
                        <Odometer value={hoverValue} /> <span> % </span>
                      </CardTitle>
                    </Col>
                  
                  <Col className="text-right" sm="8"
                      style={{ paddingTop: '2%' }}
                    >
                      <Button
                        className={`btn-round ${chartTimeframe === 7 ? 'active btn-active' : 'btn-blue'}`}
                        onClick={() => setChartTimeframe(7)}
                        style={{ fontSize: '10px' }}
                        size="sm"
                        id='1w'
                      >  1W  </Button>
                      <Button
                        className={`btn-round ${chartTimeframe === 30 ? 'active btn-active' : 'btn-blue'}`}
                        style={{ fontSize: '10px' }}
                        size="sm"
                        onClick={() => setChartTimeframe(30)}
                      >1M</Button>
                      <Button
                        className={`btn-round ${chartTimeframe === 60 ? 'active btn-active' : 'btn-blue'}`}
                        style={{ fontSize: '10px' }}
                        size="sm"
                        onClick={() => setChartTimeframe(60)}
                      > 2M</Button>

                      <Button
                        className={`btn-round ${chartTimeframe === 90 ? 'active btn-active' : 'btn-blue'}`}
                        onClick={() => setChartTimeframe(90)}
                        style={{ fontSize: '10px' }}
                        size="sm"
                      >3M </Button>

                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row className="justify-content-center">
                    <Col sm="11">
                      <CustomAreaChart data={chartData}/>
                    </Col>
                  </Row>
                </CardBody>

                {loading && (
                  <div
                    id="overlay"
                    // onClick={() => closeOverlay()}
                    style={{
                      position: 'absolute',
                      width: '100%',
                      height: '100%',
                      top: '0',
                      bottom: '0',
                      backgroundColor: 'rgba(0,0,0,0.5)',
                      zIndex: '9',
                      borderRadius: '5px',
                      cursor: 'pointer',
                    }}
                  >
                    {true && (
                      <Spinner
                        style={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          // transform: "translate(-50%, -50%)",
                          // msTransform: "translate(-50%,-50%)"
                        }}
                      ></Spinner>
                    )}
                  </div>
                )}

                <CardFooter>
                  <div className="card-footer">
                    <Row>
                      <Col lg="3">
                        <Row className="justify-content-center mt-2">
                          <div className="justify-content-center  mr-2 ">

                            <MoneyIcon height="45" width="45" style={{ color: "#4055d4" }} />

                          </div>
                          <div className="justify-content-center">
                            <p style={{ fontSize: '12px' }}>AUM</p>

                            <p
                              className="text-center"
                              style={{ fontSize: '12px' }}
                            >
                              ${aum}
                            </p>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3">
                        <Row className="justify-content-center mt-2 ">
                          <div className="justify-content-center  mr-2 ">

                            <TractorIcon height="45" width="45" style={{ color: "#4055d4" }} />
                          </div>

                          <div className="justify-content-center">
                            <p style={{ fontSize: '12px' }}>
                              Number of Investors
                            </p>

                            <p
                              className="text-center"
                              style={{ fontSize: '12px' }}
                            >
                              {numberOfActiveInvestments}
                            </p>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3">
                        <Row className="justify-content-center mt-2 ">
                          <div className="justify-content-center  mr-2 ">

                            <MoneyIcon height="45" width="45" style={{ color: "#4055d4" }} />

                          </div>

                          <div className="justify-content-center">
                            <p style={{ fontSize: '12px' }}>
                              Minimum investable amount
                            </p>

                            <p
                              className="text-center"
                              style={{ fontSize: '12px' }}
                            >
                              ${minAmount}
                            </p>
                          </div>
                        </Row>
                      </Col>
                      <Col lg="3">
                        <Row className="justify-content-center mt-2">
                          {
                            !web3 && <button className="btn btn-round btn-submit" onClick={displayConnectWalletPopup}>
                              Investin
                            </button>
                          }
                          {
                            web3 && address &&
                            // <InvestinModal
                            // web3={web3}
                            // fund={{ name: fundName, address: paramFundAddress, fundFactory: fundFactory, routerAddress }}
                            // minAmount={parseFloat(minAmount)}
                            // address={address}
                            // disabled={minAmount < 0}
                            // />
                            <InvestinDepositModal
                              web3={web3}
                              fund={{ name: fundName, address: paramFundAddress, fundFactory, routerAddress }}
                              minAmount={parseFloat(minAmount)}
                              address={address}
                              disabled={minAmount < 0}
                            />
                          }
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          </Row>
          <div>
            <Row>
              <DetailCards
                fundAbout={fundAbout}
                fAddress={fAddress}
                managerAddress={managerAddress}
                isManager={isManager}
                handleFundDetailsChange={handleFundDetailsChange}
                aum={aum}
                numberOfActiveInvestments={numberOfActiveInvestments}
                performanceFee={performanceFee}
                fundStatus={fundStatus}
                minAmount={minAmount}
                minReturn={minReturn}
                pieData={pieData}
              />
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Tables;