import TetherTokenA from '../contracts/TetherToken.json';
import { INVESTIN_BALANCE_CHECKER, TETHERTOKEN_ADDR } from './constants';
import { routerAbi} from './getAbi';

import { ethers } from 'ethers';
import { ethersProvider } from './web3/getProvider';
import { getTokensBalance } from "@mycrypto/eth-scan";

import zipobject from 'lodash.zipobject'
import BigNumber from 'bignumber.js';

import COINGECKO_TOKENS from './coingeckoTokens.json';
const CoinGecko = require('coingecko-api');

//2. Initiate the CoinGecko API Client
const CoinGeckoClient = new CoinGecko();

const getTokenSymbolsWithAddresses = async (tokenAddresses) => {

  try {
    let newData = []
    const promises = []
    newData = tokenAddresses.map((t) =>  { return {tokenAddress: t} });
    for (const tokenAddress of tokenAddresses) {
      const tokenInstance = new ethers.Contract(`${tokenAddress}`, TetherTokenA, ethersProvider);
      promises.push(tokenInstance.symbol());
      // console.log('getTokenSymbolsWithAddresses : ', newData);
    }
    try {
      const results = await Promise.allSettled(promises);
      newData = zipobject(newData.map(d => d.tokenAddress), await Promise.all(results.filter((results) => results.status === "fulfilled").map((r) => r.value)))
      newData = Object.keys(newData).map((n)=> {return {tokenAddress: n, symbol: newData[n]}}) 
    } catch (error) {
      console.error("getTokenSymbolsWithAddresses error:",error);
    }
    return newData;
  } catch (error) {
    console.error("getTokenSymbolsWithAddresses error:", error);
    return [];
  }

  
}


// if usdtEvaluation is false then it will give quantites
// if balancesRequired=false will send only whitelisted tokens from router
const getAssetsWithBalancesUsingEthers = async (routerAddress ,balancesRequired = true, fundAddress = '0x', usdtEvaluation = false, tokenList = [] ) => {
  let list = [];
  try {
    const routerInstance = new ethers.Contract(routerAddress, routerAbi(1), ethersProvider);
      list = tokenList.length > 0 ? tokenList : await routerInstance.getTokenWhiteList();
  } catch (error) {
     console.error("getTokenWhiteList error:", error);
  }
    
  let data = await getTokenSymbolsWithAddresses(list);

  try {
    if (!balancesRequired) {
      return data;
    }
    
    // if usdtEvaluation requested get all the prices from coingecko
    if (usdtEvaluation) {
      const symbols = data.map((d) => d.symbol.toLowerCase());
      const coingeckoTokens = COINGECKO_TOKENS.filter((token) => symbols.includes(token.symbol))
      const coingekoIds = coingeckoTokens.map((t) => t.id);
      const fetchPrices = (await CoinGeckoClient.simple.price({ ids: coingekoIds, vs_currencies: "usd" })).data
      coingeckoTokens.map((c) => c['price'] = fetchPrices[c.id].usd)
      data.map((d) => d['price'] = coingeckoTokens.find((c) => c.symbol.toLowerCase() === d.symbol.toLowerCase())?.price ?? 0)
    }
  } catch (error) {
    console.error("getTokensBalance error:", error);
  }

  
  let tokensBalances = [];
   try {
    // console.log("balanceChecker ethersProvider :", ethersProvider);
    // console.log(object)
    tokensBalances = await getTokensBalance(ethersProvider, fundAddress, list, { contractAddress: INVESTIN_BALANCE_CHECKER });
    // console.log("balanceChecker getTokensBalances :", tokensBalances);
  } catch (error) { 
    console.error("getTokensBalance error:", error);
  }
  

  
  try {
    for (const token of data) {
    //   const tokenInstance = new web3.eth.Contract( TetherTokenA,`${token.tokenAddress}`);
        // const tokenInstance = new ethers.Contract(`${token.tokenAddress}`, TetherTokenA, ethersProvider);
      
        //QUANTITY or BALANCE
        let quantity = 0;
        try {
          // quantity = await tokenInstance.balanceOf(fundAddress);
          quantity = (new BigNumber(tokensBalances[`${token.tokenAddress}`]))
        } catch (error) {
          console.error("error in getAssetsBalances-balanceOf (incorrect tokenAddr or fundAddr) SO skipping it: ", error);
          continue;
        }
        //   quantity = web3.utils.fromWei(`${quantity}`, 'ether');
          quantity = parseFloat(`${ethers.utils.formatEther(`${quantity}`, 'ether')}`).toFixed(6);
        // usdtEvaluation
        let balance = quantity;
        if ( token.tokenAddress !== TETHERTOKEN_ADDR) {
          const price = token.price ?? 0
          balance = quantity * parseFloat(price)
        }
        token['balance'] = balance;
      
    }
  } catch (error) {
    console.error("error in getAssetsBalances : ", error);
  }
  // console.log("getAssetsWithBalancesUsingEthers :", data);
  return data;
}

export default getAssetsWithBalancesUsingEthers;