import web3Reducer from './web3Reducer';
import investmentsReducer from './investmentsReducer';
import addressReducer from './addressReducer';
import fundAddressReducer from './fundAddressReducer';
import factoryAddressReducer from './factoryAddressReducer';
import usdtBalanceReducer from './usdtBalanceReducer';
import { combineReducers } from 'redux';
import despositingReducer from './despositingReducer';
import approvedAmtReducer from './approvedAmtReducer';
import approvingReducer from './approvingReducer';
import chainIdReducer from './chainIdReducer';
import fireConfettiReducer from './fireConfettiReducer';
import pricesReducer from './pricesReducer';


const reducers = combineReducers({
  web3Reducer,
  investmentsReducer,
  addressReducer,
  fundAddressReducer,
  factoryAddressReducer,
  usdtBalanceReducer,
  approvingReducer,
  approvedAmtReducer,
  despositingReducer,
  chainIdReducer,
  fireConfettiReducer,
  pricesReducer
});

export default reducers;
