import Dashboard from '../src/views/Dashboard';
import Investments from '../src/views/Investments';
// import Market from '../src/views/Market';
import Funds from '../src/views/Funds';
import FundDetails from '../src/views/FundDetails/index';
import Faq from '../src/views/Faq';
import TermsAndConditions from '../src/views/TermsAndConditions';
// import Help from '../src/views/Help';
import StakingView from '../src/views/Staking';
// import Stack from '../src/views/Staks';
import Strategies from '../src/views/Strategies';


var routes = [
  {
    path: '/dashboard',
    name: 'user Dashboard',
    // icon: "tim-icons icon-chart-pie-36",
    iconImageName: 'DashboardIcon',
    component: Dashboard,
    layout: '/user',
  },
  {
    path: '/funds',
    name: 'Funds',
    // icon: "tim-icons icon-bell-55",
    iconImageName: 'FundIcon',
    component: Funds,
    layout: '/user',
  },
  {
    path: '/investments',
    name: 'Investments',
    // icon: "tim-icons icon-atom",
    iconImageName: 'InvestmentIcon',
    component: Investments,
    layout: '/user',
  },
  {
    path: '/strategies',
    name: 'user Strategies ',
    // icon: "tim-icons icon-atom",
    iconImageName: 'BulbIcon',
    component: Strategies,
    layout: '/user',
  },
  // {
  //   path: '/market',
  //   name: 'Market',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'MarketIcon',
  //   component: Market,
  //   layout: '/user',
  // },
  // {
  //   path: '/staking',
  //   name: 'Staking-old',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'LiveHelpRoundedIcon',
  //   component: Stack,
  //   layout: '/user',
  // },
  {
    path: '/staking2',
    name: 'Farming',
    // icon: "tim-icons icon-chart-pie-36",
    iconImageName: 'Staking',
    component: StakingView,
    layout: '/user',
  },
  {
    path: '/faq',
    name: 'Faq',
    // icon: "tim-icons icon-pin",
    iconImageName: 'CommentRoundedIcon',
    component: Faq,
    layout: '/user',
  },
  // {
  //   path: '/help',
  //   name: 'Help',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'LiveHelpRoundedIcon',
  //   component: Help,
  //   layout: '/user',
  // },
  {
    path: '/details/:fAddress',
    name: 'Details',
    // icon: "tim-icons icon-pin",
    iconImageName: 'MarketIcon',
    component: FundDetails,
    layout: '/user',
    style: 'hidden',
  },
  // {
  //   path: '/faq',
  //   name: 'Faq',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'MarketIcon',
  //   component: Faq,
  //   layout: '/user',
  //   style: 'hidden',
  // },
  // {
  //   path: '/help',
  //   name: 'Help',
  //   // icon: "tim-icons icon-pin",
  //   iconImageName: 'MarketIcon',
  //   component: Help,
  //   layout: '/user',
  //   style: 'hidden',
  // },
  {
    path: '/terms',
    name: 'terms',
    // icon: "tim-icons icon-pin",
    iconImageName: 'CommentRoundedIcon',
    component: TermsAndConditions,
    layout: '/user',
  }
];
export default routes;
