import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import ReactGA from 'react-ga';
import { Router, Route, Switch, Redirect } from 'react-router-dom';

import UserLayout from './layouts/User/User.js';
import ManagerLayout from './layouts/Manager';
import OwnerLayout from './layouts/Owner.js';
// import Details from "views/FundDetails";
import './assets/css/nucleo-icons.css';
import "./styles.scss"
import { Provider } from 'react-redux';
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

// reducers
import reducers from './redux/reducers';
import { createStore } from 'redux';
import RootWeb3 from './components/RootWeb3';

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);
const queryClient = new QueryClient()
const hist = createBrowserHistory();

    // console.error(`On Landing: ${window.location.origin}`);
    ReactGA.initialize('UA-172955058-2');  //('G-FV1SPEGT9H');
    // To Report Page View 
    // ReactGA.pageview('/');
    ReactGA.pageview(window.location.origin +window.location.pathname+ window.location.search);

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Provider store={store}>
      <Router history={hist}>
        <RootWeb3 />
        <Switch>
          {/* <Route path="/" exact component={LandingPage} /> */}
          <Route path="/user" render={(props) => <UserLayout {...props} />} />
          <Route
            path="/manager"
            render={(props) => <ManagerLayout {...props} />}
          />
          <Route path="/owner" render={(props) => <OwnerLayout {...props} />} />
          {/* <Route path="/" component={Hello} /> */}
          <Route exact path="/">
            <Redirect to="/user/dashboard" />
          </Route>
          <Route exact path="/admin/dashboard">
            <Redirect to="/user/dashboard" />
          </Route>
        </Switch>
      </Router>
    </Provider>
  </QueryClientProvider>,
  document.getElementById('root')
);
