import React, { useState, useEffect } from "react";

const Editable = ({
    text,
    type,
    placeholder,
    children,
    childRef,
    labelText,
    ...props
}) => {
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        if (childRef && childRef.current && isEditing === true) {
            childRef.current.focus();
        }
    }, [isEditing, childRef]);

    const handleKeyDown = (event, type) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey];
        if (
            (type === "textarea" && keys.indexOf(key) > -1) ||
            (type !== "textarea" && allKeys.indexOf(key) > -1)
        ) {
            setEditing(false);
        }
    };

    return (
        <section {...props}>
            {isEditing ? (
                <div
                    onBlur={() => { setEditing(false) }}
                    onKeyDown={e => handleKeyDown(e, type)}
                >
                    <p>{labelText}</p>
                    {children}
                    <i className="tim-icons icon-pencil" style={{ color: "white", paddingLeft: "5px", marginBottom: '2px' }} />
                </div>
            ) : (
                <div
                    onClick={() => setEditing(true)}
                >
                    <p style={{ display: 'inline' }}>{labelText}</p>
                    <span style={{}}>
                        {text || placeholder || "Editable content"}
                    </span>
                    {!isEditing && <i className="tim-icons icon-pencil" style={{ color: "white", paddingLeft: "5px", marginBottom: '2px' }} />}
                </div>
            )}

        </section>

    );
};

export default Editable;