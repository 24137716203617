import { ROUTER_PANCAKESWAP_ADDR, ROUTER_WAULTSWAP_ADDR, ROUTER_DEFI_ADDR, CHAIN_ID,PANCAKESWAP_V2_ROUTER_ADDR,WAULTSWAP_V2_ROUTER_ADDR} from './constants';

// const KOVAN_DEX_MAPPING = (routerAddress) => {
//     if (routerAddress == ROUTER_PANCAKESWAP_ADDR) {
//         return KOVAN_UNISWAP_V2_ROUTER_ADDR; //removed
//     }
//     else if (routerAddress == ROUTER_WAULTSWAP_ADDR) {
//         return KOVAN_SUSHISWAP_V2_ROUTER_ADDR; // removed 
//     }
//     else if (routerAddress == ROUTER_DEFI_ADDR) {
//         return KOVAN_UNISWAP_V2_ROUTER_ADDR;
//     }
//     else {
//         console.error('Invalid routerAddress , CHAIN_ID:', routerAddress, CHAIN_ID);
//         return '';
//     }
// };

// const BSC_DEX_MAPPING = (routerAddress) => {
    
//     if (routerAddress == ROUTER_PANCAKESWAP_ADDR) {
//         return PANCAKESWAP_V2_ROUTER_ADDR;
//     }
//     else if (routerAddress == ROUTER_WAULTSWAP_ADDR) {
//         return WAULTSWAP_V2_ROUTER_ADDR;
//     }
//     else if (routerAddress == ROUTER_DEFI_ADDR) {
//         return PANCAKESWAP_V2_ROUTER_ADDR;
//     }
//     else {
//         console.error('Invalid routerAddress , CHAIN_ID:', routerAddress, CHAIN_ID);
//         return '';
//     }
// };

// Old way was to fetch chain-id as give now direct from env file   
// export const routerToDexMapping_OLD = (routerAddress) => {

//       switch (CHAIN_ID) {
//           case '42':
//             return KOVAN_DEX_MAPPING(routerAddress);
//         case '56':
//             return BSC_DEX_MAPPING(routerAddress);
//           default:
//               console.error('Invalid CHAIN_ID:',CHAIN_ID);
//               return '';
//             break;
//     }
// }

export const routerToDexMapping = (routerAddress) => {
    
    if (routerAddress === ROUTER_PANCAKESWAP_ADDR) {
        return PANCAKESWAP_V2_ROUTER_ADDR;
    }
    else if (routerAddress === ROUTER_WAULTSWAP_ADDR) {
        return WAULTSWAP_V2_ROUTER_ADDR;
    }
    else if (routerAddress === ROUTER_DEFI_ADDR) {
        return PANCAKESWAP_V2_ROUTER_ADDR;
    }
    else {
        console.error('Invalid routerAddress , CHAIN_ID:', routerAddress, CHAIN_ID);
        return '';
    }
};
