import { SET_FIRE_CONFETTI } from "../actions";

const fireConfettiReducer = (state = false, action) => {
  const fireConfetti = action.fireConfetti;
  switch (action.type) {
    case SET_FIRE_CONFETTI:
      return {
        fireConfetti,
      };
    default:
      return state;
  }
};

export default fireConfettiReducer;