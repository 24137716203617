import React, { useEffect } from 'react';
import Row from 'reactstrap/lib/Row';
import StakeCard from '../components/StakeCard'
import { Alert } from 'reactstrap';
import { useSelector } from 'react-redux';
import BigNumber from 'bignumber.js'
// import IERC20Abi from "../contracts/IERC20.json";
import { useHistory } from 'react-router';
// import { MaxUint256 } from '@ethersproject/constants'

import farmingAbi from "../contracts/Farming.json";
import farming10xAbi from "../contracts/Farming10x.json";
import { FARMING_ADDRESS, FARMING_USERS_ADDRESS } from "../utils/constants";


BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3
export const TOKEN_PER_BLOCK = new BigNumber(1 * 10 ** 18)
export const CAKE_PER_BLOCK = new BigNumber(3 * 10 ** 18)
export const BLOCKS_PER_YEAR = new BigNumber((60 / BSC_BLOCK_TIME) * 60 * 24 * 365)

export const getPoolApy = (
  stakingTokenPrice,
  rewardTokenPrice,
  totalStaked,
  tokenPerBlock
) => {
  // console.log("BLOCKS_PER_YEAR:",BLOCKS_PER_YEAR.toNumber())
  const totalRewardPricePerYear = new BigNumber(rewardTokenPrice).times(tokenPerBlock).times(BLOCKS_PER_YEAR)
  // console.log(`totalRewardPriceYear : `, totalRewardPricePerYear.toNumber());
  const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked);
  // console.log(`totalStakingTokenInPool : `, totalStakingTokenInPool.toNumber());

  const apy = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100);
  
  return (apy.isNaN() || !apy.isFinite()) ? null : apy.toNumber()
}

const StakingView = (props) => {
  const web3 = useSelector((state) => state.web3Reducer.web3);
  const address = useSelector((state) => state.addressReducer.address);
  const history = useHistory();

  useEffect(() => {
    if (!web3) {
      console.log('No web3');
      alert('Please connect Wallet First to View Investments');
      history.push('../../user/dashboard');
      return;
    }

    // if (!FARMING_ADDRESS) {
    //   alert('Staking Comming Soon...');
    //   history.push('../../user/dashboard');
    //   // return <div><h2>Coming Soon...</h2></div>
    //   return;
    // }

    // const interval = setInterval(async () => {
    // }, 5000);
    // return () => clearInterval(interval);
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="content">
      <h2 style={{ textAlign: 'center' }}>INVESTIN FARMS </h2>
     
      <Alert color="danger" style={{width: "40%", textAlign: "center", margin: "25px auto"}}>
        <b>Farm are closed! Will be live on Solana soon!</b>
      </Alert>

      <Row className=" justify-content-around  align-item-center">  
       
        {
          web3 && address && 
          <>
            <StakeCard
              web3={web3}
              heading="Pool for Investin Managers & Investors"
              multiplier="10x"
              abi={farming10xAbi}
              address={address}
              FARMING_ADDRESS_HERE={FARMING_USERS_ADDRESS}
            />

            <StakeCard
              web3={web3}
              heading="Pool for IVN holder"
              multiplier="1x"
              abi={farmingAbi}
              address={address}
              FARMING_ADDRESS_HERE={FARMING_ADDRESS}
            />
          </>
        }
      </Row>
    </div>
  );
};

export default StakingView;
