import React, { useEffect, useState } from 'react'
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Input
} from 'reactstrap'
import { Spinner } from 'reactstrap';

const AssetDropdown = (props) => {

    const { items, initialValue, callback } = props
    const [selectedItem, setSelectedItem] = useState(initialValue)
    const [assets, setAssets] = useState([...items]);

    useEffect(() => {
        // console.log("prop items : ", items)
        setAssets([...items])
        if (initialValue) {
            setSelectedItem(initialValue)
        } else {
            setSelectedItem(items[0])
            
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const handleItemClick = (e) => {
        setSelectedItem(e.currentTarget.textContent);
        callback(e.currentTarget.textContent);
        setAssets([...items])
    }

    const searchCoins = (event) => {
        if (!event || event.target.value === '') {
            setAssets(items);
            return;
        }
        const searchString = event.target.value;
        setAssets(
            items.filter((item) =>
                item.toLowerCase().includes(searchString.toLowerCase())
            )
        );
    };

    return (
        <div className="d-flex justify-content-center">
            <UncontrolledDropdown>
                <DropdownToggle
                    caret
                    data-toggle="dropdown"
                    type="button"
                    onClick={() => setAssets([...items])}
                >
                    {selectedItem ? selectedItem : <Spinner type="grow" size="sm" color="secondary" />}
                </DropdownToggle>
                <DropdownMenu
                    aria-labelledby="dropdownMenuLink"
                    className="coins-dropdown"
                    style={{
                        maxHeight: '400px',
                        overflowY: 'auto',
                        background: '#1E1D2B',
                    }}>
                    <Input
                        type="text"
                        placeholder="Search "
                        autoFocus
                        style={{
                            background: '#1E1D2B',
                            border: '2px solid white',
                            color: 'white',
                        }}
                        onChange={searchCoins}
                    />
                    {assets && assets.map((asset) => <DropdownItem onClick={handleItemClick} key={asset}>{asset}</DropdownItem>)}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}

export default AssetDropdown
