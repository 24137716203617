import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import { Card, Col, Row, Input } from 'reactstrap';

import { ethers } from 'ethers';
import { ethersProvider } from '../utils/web3/getProvider';

import WalletProviderPopup from '../layouts/Landing/ConnectWalletModal';

import { roundDownTo4Decimals } from '../utils/helperFunctions';
import FundDefiAbi from '../contracts/FundDefi.json';

import InvestinStaking from '../contracts/InvestinStaking.json';
import TetherTokenA from '../contracts/TetherToken.json';

import {
  getSTIcon,
  getProtocolIcon,
  calculateAPY,
  handleHarvestManagerOrUser,
  handleDepositStrategyManager,
  handleWithdrawStrategyManager,
  handleApproveStrategyManager,
  handleRemoveStrategyManager,
  getBaseAndStrategyTokenBalanceManager,
  getPendingRewards,
  getAllowanceManager,
  getPoolApyFromApr
} from '../utils/strategyHelper';

import CustomButton from '../components/button';
import { MaxUint256 } from '@ethersproject/constants';
import { SkeletonLoader } from '../components/SkeletonLoader';
// import { TRX_URL } from 'utils/constants';
import { ReactComponent as ExternalLink } from '../assets/img/external-link.svg';
  

const StrategyListCardManager = (props) => {
  const disableStrategies = props.disableStrategies;
  const onlyShowDesposited = props.onlyShowDesposited;
  const setStrategyData = props.setStrategyData;

  const { strategyAddress, name : strategyName , protocol,  functionName , rewardPoolAddress, lpTokenAddress ,pid, token0CoingeckoId , token1CoingeckoId , rewardtokenCoingeckoId ,APY : hardCodedAPY } = props?.strategy;
  
  const fundAddress = useSelector(
    (state) => state.fundAddressReducer.fundAddress
  );
  const prices = useSelector(state => state.pricesReducer.prices)

  const [fundInstance, setFundInstance] = useState({});

  const [AUM, setAUM] = useState('');
  const [APY, setAPY] = useState('');
  // const [strategyInstance, setStrategyInstance] = useState({});
  const strategyInstance = new ethers.Contract(strategyAddress,InvestinStaking,ethersProvider);

  const [baseTokenInstance, setBaseTokenInstance] = useState({});
  const [strategyTokenPrice, setStrategyTokenPrice] = useState('0')

  const [baseTokenBalance, setBaseTokenBalance] = useState(0);
  const [strategyTokenBalance, setStrategyTokenBalance] = useState('0');

  const web3 = useSelector((state) => state.web3Reducer.web3);
  const address = useSelector((state) => state.addressReducer.address);
  const [openModal, setOpenModal] = useState(false);
  const [renderSubcomponent, setRenderSubcomponent] = useState(false);

  const [depositing, setDepositing] = useState(false);
  const [withdrawing, setWithdrawing] = useState(false);
  const [approveLoading, setApproveLoading] = useState(false);
  const [depositAmt, setDepositAmt] = useState(0);
  const [withdrawAmt, setWithdrawAmt] = useState(0);
  const [baseTokenSymbol, setBaseTokenSymbol] = useState('');

  const [allowance, setAllowance] = useState(0);

  const [harvesting, setHarvesting] = useState(false);
  const [pendingRewards, setPendingRewards] = useState(0);

  useEffect(() => {

    setAUM('');
    setAPY('');
    (async () => {
      try {
       
         let strategyTokenPrice = await strategyInstance.getStrategyTokenPrice();
        strategyTokenPrice = roundDownTo4Decimals(parseFloat(ethers.utils.formatEther(strategyTokenPrice, 'ether')));
        // console.log(' StrategyTokenPrice:', strategyTokenPrice);
        setStrategyTokenPrice(strategyTokenPrice);

        let totalsupply = await strategyInstance.totalSupply();
        totalsupply = roundDownTo4Decimals(
          parseFloat(ethers.utils.formatEther(totalsupply, 'ether'))
        );
        const AUM = roundDownTo4Decimals(parseFloat(totalsupply*parseFloat(strategyTokenPrice)),2)
        setAUM(AUM);
              
      } catch (error) {
        console.error(`st manager side error`, error);
      }

    })();
       
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    try {
      ( async () => {
          let fundInstance = {};
          if (fundAddress) {

            fundInstance = new ethers.Contract(fundAddress,FundDefiAbi,ethersProvider);
            setFundInstance(fundInstance);

                // for FUND there is no allowance so have to loop and check getStrategyList  
                // const allowance = await baseTokenInstance.methods.allowance(address, strategyAddress).call();
            if (!disableStrategies) {
                // const strategyList = await fundInstance.methods.getStrategyList().call();
                const strategyList = await fundInstance.getStrategyList();
                setAllowance(0);
                for (const st of strategyList) {
                  if (st === strategyAddress) {
                    setAllowance(MaxUint256);
                  }
                } 
            }
        
            const baseToken = await strategyInstance.baseToken();
            const baseTokenInstance = new ethers.Contract(baseToken,TetherTokenA,ethersProvider);
            setBaseTokenInstance(baseTokenInstance);

            const baseTokenSymbol = await baseTokenInstance.symbol();
            setBaseTokenSymbol(baseTokenSymbol);
            
            let balance = await strategyInstance.balanceOf(fundAddress);
            balance = ethers.utils.formatEther(balance, 'ether');
            setStrategyTokenBalance(balance);

            let baseTokenBalance = await baseTokenInstance.balanceOf(fundAddress);
            baseTokenBalance = ethers.utils.formatEther(baseTokenBalance, 'ether');
            setBaseTokenBalance(baseTokenBalance);
          }

       })()
    } catch (error) {
      console.error("St user error fetching fundAddress:",error)
    }
  }, [fundAddress])


  useEffect(() => {
    try {
      ( async () => {
            let APY = hardCodedAPY;
              if(protocol==='Alpaca'){
                let APR;
                if(strategyName==='ibBUSD-ALPACA'){
                    APR = prices && prices['ibBUSD']?.lendingApr;
                } else if(strategyName==='ibBNB-ALPACA'){
                    APR = prices && prices['ibBNB']?.lendingApr;
                } else if(strategyName==='ibUSDT-ALPACA'){
                    APR = prices && prices['ibUSDT']?.lendingApr;
                }
                APY = getPoolApyFromApr(APR);
                setAPY(APY);
                setStrategyData(strategyAddress, APY, AUM);
                return;
              }

              if ((strategyName !== "beltBUSD" && strategyName !== "beltUSDC" && strategyName !== "beltUSDT")) {
                let pendingRewards = await strategyInstance.viewPendingRewards();
                  pendingRewards = roundDownTo4Decimals(parseFloat(ethers.utils.formatEther(pendingRewards, 'ether')),2);
                  setPendingRewards(pendingRewards);
                    if(prices && prices['binancecoin']){
                    APY = await calculateAPY(strategyInstance , strategyName, protocol , rewardPoolAddress , lpTokenAddress,  pid,  functionName , token0CoingeckoId , token1CoingeckoId , rewardtokenCoingeckoId ,prices)
                  }
              } else {
                // for Belt stable startegies no rewards
                //  console.log("BELT ST :: ", strategyName);
              }
            
            setAPY(APY);
            setStrategyData(strategyAddress, APY, AUM);
       })()
    } catch (error) {
      console.error("St user error fetching APY:",error)
    }
  }, [prices])


  const displayConnectWalletPopup = (value) => {
    setOpenModal(!openModal);
  };

   const handleHarvest = async () => {
    setHarvesting(true);
     await handleHarvestManagerOrUser(web3, strategyAddress, address);
    setHarvesting(false);

    //refreshing amt
     const pendingRewards = await getPendingRewards(strategyInstance);
        setPendingRewards(pendingRewards);
  };

   const updateBalances = async () => {

        let totalsupply = await strategyInstance.totalSupply();
        totalsupply = roundDownTo4Decimals(
          parseFloat(ethers.utils.formatEther(totalsupply, 'ether'))
        );
        const AUM = roundDownTo4Decimals(parseFloat(totalsupply * parseFloat(strategyTokenPrice), 2));
        setAUM(AUM);

         let balance = await strategyInstance.balanceOf(fundAddress);
          balance = ethers.utils.formatEther(balance, 'ether');
          setStrategyTokenBalance(balance);

          let baseTokenBalance = await baseTokenInstance.balanceOf(fundAddress);
          baseTokenBalance = ethers.utils.formatEther(baseTokenBalance, 'ether');
          setBaseTokenBalance(baseTokenBalance);
  }

   const handleDeposit = async () => {
    setDepositing(true);
  
     await handleDepositStrategyManager( web3,fundAddress,strategyAddress,address,depositAmt);
     
    setDepositing(false);
     
    //refreshing amts
    const obj=  await getBaseAndStrategyTokenBalanceManager(strategyInstance,baseTokenInstance,fundAddress)
    setStrategyTokenBalance(obj.strategyTokenBalance);
     setBaseTokenBalance(obj.baseTokenBalance);
     //update  
     await updateBalances();
  };

  const handleWithdraw = async () => {
    setWithdrawing(true);

    await handleWithdrawStrategyManager( web3,fundAddress,strategyAddress,address,withdrawAmt);
    
    setWithdrawing(false);
     
     //refreshing amts
    const obj=  await getBaseAndStrategyTokenBalanceManager( strategyInstance,baseTokenInstance,fundAddress)
    setStrategyTokenBalance(obj.strategyTokenBalance);
     setBaseTokenBalance(obj.baseTokenBalance);
     //update
     await updateBalances();
  };

  const handleApprove = async () => {

   setApproveLoading(true);

   await handleApproveStrategyManager(web3,fundAddress, strategyAddress, address);

   setApproveLoading(false);
    
    const allowance = await getAllowanceManager( fundInstance, strategyAddress);
    setAllowance(allowance);
  };

  const handleRemoveApprove = async () => {
          setApproveLoading(true);

    await handleRemoveStrategyManager(web3,fundAddress, strategyAddress, address);
          setApproveLoading(false);
     const allowance = await getAllowanceManager( fundInstance, strategyAddress);
    setAllowance(allowance);
     
  };

   if (onlyShowDesposited && ( strategyTokenBalance===0||strategyTokenBalance==='0' || strategyTokenBalance==='0.0')) {
    return <></>;
    }

  return (
    <div className="list">
      {openModal && (
        <WalletProviderPopup
          open={openModal}
          handleClose={displayConnectWalletPopup}
          onWeb3Connect={() => console.log('connected')}
        />
      )}
      <Card className='strategy-card'>
        
        <Row className="justify-content-center card-header">
          <Col lg="1" className="protocol-icon" style={{ textAlign: 'center' }}>
            {
              getProtocolIcon(protocol)
            }
          </Col>


          <Col lg="2" md="12" sm="12" className="strategy-col protocol-name">
            <Row >
              
              <Col lg="8" sm="6" className="strategy-col st-name-col">
                  <a target="_blank" rel="noopener noreferrer"
                  href={`https://investin-pro.github.io/Investin_docs/strategy/#pancakewault-swap-farms`}
                  // style={{ color: 'white', textDecoration: 'underline' }}
                  className="strategy-name"
                >  <b className="strategy-name">
                  {strategyName && strategyName.length > 25
                    ? `${strategyName.substring(0, 21)}..  `
                    : strategyName}
                   </b>
                </a>
              </Col>

              <Col lg="4" sm="3" className="hide-mobile" style={{ textAlign: 'left', paddingLeft : 0, paddingRight : 0 }}> 
              {
                  getSTIcon(strategyName)
               }
              </Col>
              
            </Row>
          </Col>



          <Col lg="1"  className="strategy-col">
            <b className="strategy-col-heading">
              APY
            </b>
            <p className="loader">{APY ? `${parseFloat(APY).toFixed(2)}%` : <SkeletonLoader />}</p>
          </Col>

          <Col lg="1" className="strategy-col hide-mobile">
            <b className="strategy-col-heading">
              AUM
            </b>
            <p>{AUM!=='' ? `$${AUM}` : <SkeletonLoader />}</p>
          </Col>

          <Col lg="1" className="strategy-col hide-mobile">
            <b className="strategy-col-heading">
              Token
            </b>
            <p>{baseTokenSymbol || <SkeletonLoader />}</p>
          </Col>

             <Col lg="2" className="hide-mobile"></Col>
          

          <Col lg="1" className="strategy-col">
            <b className="strategy-col-heading">
              Balance
            </b>
            {
              strategyTokenBalance !== '' &&
                 <p>{roundDownTo4Decimals(parseFloat(strategyTokenBalance))}</p>
              
            }
          </Col>

          <Col lg="1" className="strategy-col hide-mobile">
            <b className="strategy-col-heading">
              Value
            </b>
            {
              strategyTokenBalance !== '' &&
                <p>${ roundDownTo4Decimals(parseFloat(parseFloat(strategyTokenPrice)*parseFloat(strategyTokenBalance)),2)}</p>
            }
          </Col>


          <Col lg="1" className="strategy-col hide-mobile" >
            {
              web3 ?
               <>
                {
                   (allowance > 0) ?
                    <CustomButton size='lg' onClick={handleRemoveApprove} loading={approveLoading}  disabled={disableStrategies} text="DELETE" color="primary" />
                    :
                    <CustomButton size='lg' onClick={handleApprove} loading={approveLoading} disabled={disableStrategies} text="ADD" color="primary" />
                }
                </>
                
                :
                <>
                 <CustomButton size='lg' onClick={displayConnectWalletPopup} loading={false}  disabled={false} text="ADD/DELETE" color="primary" />
                </>
             
            }
          </Col>

         
          <Col lg="1" className="strategy-col expand-btn">  
            <Button
              style={{ color: 'white' }}
              className="custom-btn btn-1"
              onClick={() => setRenderSubcomponent(!renderSubcomponent)}
            >
              {renderSubcomponent ? 'ᐁ' : ' ᐅ'}
            </Button>
          </Col>
        </Row>
        {renderSubcomponent && (

          <div style={{ marginTop: '2%', marginRight :'5%', padding: '1rem' }}>
            <Row className="show-mobile">
              <Col lg="4" className="strategy-col">
                <b className="strategy-col-heading">
                  AUM
                </b>
                <p className="loader">{AUM!=='' ? `$${AUM}` : <SkeletonLoader />}</p>
              </Col>

              <Col lg="4" className="strategy-col">
                <b className="strategy-col-heading">
                  BaseToken
                </b>
                <p className="loader">{baseTokenSymbol || <SkeletonLoader />}</p>
              </Col>

              <Col lg="4" className="strategy-col">
                <b className="strategy-col-heading">
                  Value
                </b>
                {
                  strategyTokenBalance !== '' &&
                    <p className="loader">${ roundDownTo4Decimals(parseFloat(parseFloat(strategyTokenPrice)*parseFloat(strategyTokenBalance)),2)}</p>
                }
              </Col>
            </Row>
            <Row className="show-mobile">
              <Col lg="12" className="strategy-col" >
                {
                  web3 ?
                  <>
                    {
                      (allowance > 0) ?
                    <CustomButton size='lg' onClick={handleRemoveApprove} loading={approveLoading}  disabled={disableStrategies} text="DELETE" color="primary" />
                    :
                    <CustomButton size='lg' onClick={handleApprove} loading={approveLoading} disabled={disableStrategies} text="ADD" color="primary" />
                    }
                    </>
                    
                    :
                    <>
                    <CustomButton size='lg' onClick={displayConnectWalletPopup} loading={false}  disabled={false} text="ADD/DELETE" color="primary" />
                    </>
                
                }
              </Col>
            </Row>
          <Row>
              <Col lg="1" md="1">
                <></>
              </Col>
              <Col lg="3" md="3">
                <Row>
                  <Col lg="12" md="12">
                    <div style={{ padding: '1rem'}}  className="strategy-sub-container-box">
                       <p style={{ fontSize: '15px',padding: '0.2rem', color: 'white' }}>
                        Rewards Earned : {pendingRewards} </p>
                      <>
                        {
                          web3 ?
                      <CustomButton size='lg' disabled={!pendingRewards} onClick={handleHarvest} loading={harvesting} text={`Harvest`} color="primary" />
                            :
                      <CustomButton size='lg' onClick={displayConnectWalletPopup} loading={false} text={`Harvest`} color="primary" />
                        }
                      </>
                    </div>
                  </Col>
                </Row>
                <Row style={{padding :'1rem'}}>
                  <Col lg="12" md="12"> 
                    <h3 style={{marginBottom :'2px', color:'white'}}>Fees</h3>
                    <p>Deposit fee: 0%</p>
                    <p>Withdrawal Fee: 0%</p>
                    <a target="_blank" rel="noopener noreferrer"
                      href={`https://bscscan.com/address/${strategyAddress}`}
                      style={{color: 'white' , textDecoration : 'underline'}}
                    > View Contract <ExternalLink /></a>
                  </Col>
                </Row>
              </Col>


            <Col lg="4" md="4">
              <div className="strategy-sub-container-box">
                <Row>
                  <Col lg="6" md="6">
                    <p style={{ textAlign: 'left', fontSize: '15px', color: 'white' }}>Balance of {baseTokenSymbol}: {roundDownTo4Decimals(parseFloat(baseTokenBalance))}</p>
                  </Col>
                  <Col lg="6" md="6" style={{ textAlign: 'right' }}>
                      <button style={{ color: 'white', textDecoration: 'underline', background: 'transparent', border: 'none' }}
                    onClick={()=> setDepositAmt(baseTokenBalance)}>
                      MAX
                    </button>
                  </Col>
                </Row>
                <Input
                  type="number"
                 className="strategy-input"
                  placeholder="Enter an Amount"
                  value={depositAmt ? depositAmt : ''}
                  onChange={(e) => setDepositAmt(e.target.value)}
                />
                  <br />
                  <>
                    {
                      web3 ?
                <CustomButton size='lg' onClick={handleDeposit} loading={depositing} disabled={disableStrategies || (allowance <= 0) } text="Deposit" color="primary" />
                        :
                <CustomButton size='lg' onClick={displayConnectWalletPopup} loading={false} disabled={false} text="Deposit" color="primary" />

                    }
                  </>
              </div>
              </Col>
              

            <Col lg="4" md="4">
              <div  className="strategy-sub-container-box">
                <Row>
                  <Col lg="6" md="6">
                    <p style={{ textAlign: 'left',fontSize: '15px', color: 'white' }}>Balance : {roundDownTo4Decimals(parseFloat(strategyTokenBalance))}</p>
                  </Col>
                  <Col lg="6" md="6" style={{ textAlign: 'right' }}>
                    <button style={{ color: 'white', textDecoration: 'underline', background: 'transparent', border: 'none' }}
                    onClick={()=> setWithdrawAmt(strategyTokenBalance)}>
                      MAX
                    </button>
                    
                  </Col>
                </Row>
                <Input
                    type="number"
                    className="strategy-input"
                  placeholder="Enter an Amount"
                  value={withdrawAmt ? withdrawAmt : ''}
                  onChange={(e) => setWithdrawAmt(e.target.value)}
                />
                  <br />
                  <>
                    {
                      web3 ?
                <CustomButton size='lg' onClick={handleWithdraw} loading={withdrawing} disabled={disableStrategies}  text="Withdraw" color="primary" />
                        :
                <CustomButton size='lg' onClick={displayConnectWalletPopup} loading={false} disabled={false}  text="Withdraw" color="primary" />

                    }
                  </>
              </div>
            </Col>
            </Row>
            </div>
        )}
      </Card>
    </div>
  );
};

export default StrategyListCardManager;
