import { ethersProvider } from '../utils/web3/getProvider';
import {
  INVESTIN_MULTI_CALL,
  ROUTER_PANCAKESWAP_ADDR,
  ROUTER_WAULTSWAP_ADDR,
  ROUTER_DEFI_ADDR,
  CHAIN_ID
} from '../utils/constants';
import {
  getFactoryAddress,
} from '../utils/getAbi';
import Fusion_V3_V4_MulticallAbi from '../contracts/Fusion_V3_V4_Multicall.json';

import { ethers } from 'ethers';
import { useApiFunds } from './useApiFunds';
import  { useMemo } from 'react';

import { useQuery } from 'react-query';


const fetchFunds = async () => {
  try {
     const investinMulticallContract = new ethers.Contract(INVESTIN_MULTI_CALL, Fusion_V3_V4_MulticallAbi, ethersProvider);
    
    const list0 = await investinMulticallContract.getAllV4FundDetails(0, 0, { gasLimit: 41000000 });
    const list1 = await investinMulticallContract.getAllV3FundDetails(0, 0, { gasLimit: 41000000 });
    let list2 = []; 
    const list3 = await investinMulticallContract.getAllV3FundDetails(1, 0, { gasLimit: 41000000 });

    switch (CHAIN_ID) {
      case '42':
        list2 = [];
        break;
      case '56':
        list2 = await investinMulticallContract.getAllV3FundDetails(0, 100, { gasLimit: 41000000 });
        break;
    default:
        console.error('Invalid CHAIN_ID:',CHAIN_ID);
        break;
    }
    
    const fundDetailsMulticall = [...list0,...list1, ...list2, ...list3];
    // console.log("fundDetailsMulticall :", list0);

      let funds = [];
      for (let i of fundDetailsMulticall) {
            let { fundAddress, minAmount, baseTokenValue, minReturn, performanceFeePercentage, currentPerformance, baseTokenAddress, routerAddress } = i;
            if (fundAddress === "0x0000000000000000000000000000000000000000") {
              continue;
            }

            let fundName = `${fundAddress.substring(0, 3)}...${fundAddress.substr(fundAddress.length - 5)}`;
            minAmount = ethers.utils.formatEther(minAmount);
            let totalAmount = ethers.utils.formatEther(baseTokenValue, 'ether');
             if (routerAddress === ROUTER_DEFI_ADDR) {
               currentPerformance = (((parseFloat(currentPerformance) / 100000000) - 1).toFixed(4) * 100);
               performanceFeePercentage = (((parseFloat(performanceFeePercentage) / 100000000)).toFixed(4) * 100);
               //  console.error("TESTING check for currentPerformance,performanceFeePercentage",currentPerformance,performanceFeePercentage);
             } else {
               currentPerformance = (((parseFloat(currentPerformance) / 10000) - 1).toFixed(4) * 100);
               performanceFeePercentage = (((parseFloat(performanceFeePercentage) / 10000)).toFixed(4) * 100);
               //  console.error("Else TESTING check for currentPerformance,performanceFeePercentage",currentPerformance,performanceFeePercentage);
            }
            let obj = { fundAddress, fundName, minAmount, minReturn, performanceFeePercentage, currentPerformance, totalAmount, baseTokenAddress, routerAddress, fundFactory : getFactoryAddress(routerAddress) };
            funds.push(obj);
         }
      console.log("Multicall fetched funds :", funds);
      return funds;
  } catch (error) {
    console.error("fundDetailsMulticall error: ", error);
  }
}


export const useFunds = () => {
  const {
    // isLoading,
    data: apiFunds } = useApiFunds();

  const {
    // _, error,
    data: funds } = useQuery('contractFunds', () => fetchFunds(), { cacheTime: 60000, refetchInterval: 60000 });
  const sortedFunds = {
    pancakeSwapFunds: [],
    waultSwapFunds: [],
    StrategyFunds: []
  };
  return useMemo(() => {

    if (apiFunds && funds) {
      funds.map((fund) => {
        const apiFund = apiFunds.find((f) => f.address === fund.fundAddress);
        if (apiFund) {
          const labels = Object.keys(apiFund[`${7}_days_data`]);
          const data = Object.values(apiFund[`${7}_days_data`]);
          fund.fundName = apiFund.name ?? fund.fundName;
          fund.labels = labels;
          fund.data = data;
        }
        // console.log(`api single Fund searched in mutlicall: `, fund);
        return fund;
      })
    }
    if (funds) {
      sortedFunds.pancakeSwapFunds = funds.filter(f => f.routerAddress === ROUTER_PANCAKESWAP_ADDR)
      sortedFunds.waultSwapFunds = funds.filter(f => f.routerAddress === ROUTER_WAULTSWAP_ADDR)
      sortedFunds.StrategyFunds = funds.filter(f => f.routerAddress === ROUTER_DEFI_ADDR)
    }
    // console.log("funds all :", funds);
    console.log("sorted funds:", sortedFunds);
    return sortedFunds;
  }, [apiFunds, funds]) // eslint-disable-line react-hooks/exhaustive-deps

}
