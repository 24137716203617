import React, { useEffect, useState } from 'react';

// nodejs library that concatenates classes
// import classNames from 'classnames';
// react plugin used to create charts
import { Line } from 'react-chartjs-2';
import DepositModal from '../components/DepositModal';
import DashboardWithdrawalModal from '../components/DashboardWithdrawalModal';
import { CustomAreaChart } from 'investin-ui-components-package';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';
// import copy from 'copy-to-clipboard';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from 'reactstrap';

// core components
import { chartExample1 } from '../variables/charts.js';

// redux web3 state
import { useSelector } from 'react-redux';
import { chart1_2_options } from '../variables/charts';
import WalletProviderPopup from '../layouts/Landing/ConnectWalletModal';
import { API_URL } from '../utils/constants';
import DashboardCard from './DashboardViews/DashboardCard';
// import notify from '../utils/notify';


const Dashboard = (props) => {
  const web3 = useSelector((state) => state.web3Reducer.web3);
  const globalAddress = useSelector((state) => state.addressReducer.address);
  const investments = useSelector(
    (state) => state.investmentsReducer.investments
  );
  const balanceUSDT = useSelector((state) => state.usdtBalanceReducer.balance);

  const [walletConnectSpinner, setWalletConnectSpinner] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [address, setAddress] = useState('0x');
  // eslint-disable-next-line
  const [walletBalance, setWalletBalance] = useState('');
  const [investedAmount, setInvestedAmount] = useState('');

  const [, setDisplayOverlay] = useState(true);
  const [newUser, setNewUser] = useState(true);

  const [selectedFund, setSelectedFund] = useState('');
  const [fundNameAddrobj, setFundNameAddrobj] = useState('');

  const [hoverValue, setHoverValue] = useState(0);
  const [chartData, setChartData] = useState(undefined);
  const [chartTimeframe, setChartTimeframe] = useState(30);
  const [loading, setLoading] = useState(false);


  const fetchActiveInvestments = async () => {
    if (investments && investments.length !== 0) {
      let totalamt = investments
        .map((i) => {
          if (i.fundAddress ==='' || i.fundAddress === "0x0000000000000000000000000000000000000000")
          {
            return 0;
          }
          return parseFloat(i.amount);
        })
        .reduce((a, b) => a + b, 0);
      setInvestedAmount(parseFloat(`${totalamt}`).toFixed(4));

      const activeInvesments = investments.filter(
        (i) => i.status === 'Active'
      );

      const investedFundAddresses = activeInvesments.map((i) => i.fundAddress);
      
      const uniqueAddresses = [...new Set(investedFundAddresses)];

      const fundNameAddrobjset = []; //unique

      for (const address of uniqueAddresses) {
        const obj = activeInvesments.find((i) => i.fundAddress === address);
        let newobj = {};
        try {
          
          const { fund } = await (await fetch(`${API_URL}/fund/${address}/decentralised`)).json();
          
          newobj = fund; 
          // console.log("api fetch:", newobj);
        } catch (error) {
          console.error("error in api for fetching fundname :", error);
        }
       
        fundNameAddrobjset.push({ name: newobj?.name ?? (obj.fundName), address: obj.fundAddress })
      }
      
      setSelectedFund(fundNameAddrobjset[0]?? undefined)
      setFundNameAddrobj(fundNameAddrobjset);
      setNewUser(false);
    } else {
      setNewUser(true);
    }
  }

  useEffect(() => {
    if (!web3) {
      fetchActiveInvestments();
    } else {
      setWalletConnectSpinner(true);
      fetchFundsAndInvestedAmount(web3);
    }
  }, [web3]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {
      fetchActiveInvestments();
  }, [investments, globalAddress, web3]); // eslint-disable-line react-hooks/exhaustive-deps



  // const copyOnClick = () => {
  //   copy(address);
  //   notify('Address copied...');
  // };

  const closeOverlay = () => {
    // displayOverlay
    setDisplayOverlay(false);
  };

  useEffect(() => {
    if (selectedFund) {
      setLoading(true);
      (async () => {
        const response = await fetch(
          `${API_URL}/fund/${selectedFund.address}/decentraisedPerformance/days/${chartTimeframe}`
        );
         const data = await response.json();
        console.log("chart data from API :", data)
        let days = data?.[`${chartTimeframe}_days_data`];
          let x = [];
          for (const [key, value] of Object.entries(days)) {
            x.push({ timestamp: key, value: value })
          }
          // console.log("x :", x);

          setChartData(x);
          setHoverValue(x[x.length - 1].value)
        setLoading(false);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartTimeframe, selectedFund]);




  const [openModal, setOpenModal] = useState(false);

  const displayConnectWalletPopup = (value) => {
    if (typeof value === 'string' && value !== '') {
      setWalletConnectSpinner(true);
    }
    setOpenModal(!openModal);
  };

  // can take in web3?

  // fetch contract info here, maybe just create a drizzle state here ?
  const fetchFundsAndInvestedAmount = async (web3) => {
    const accounts = await web3.eth.getAccounts();
    const address = accounts[0];
    setAddress(address);

    let walletBalanceE = await web3.eth.getBalance(address);
    walletBalanceE = web3.utils.fromWei(walletBalanceE, 'ether');
    setWalletBalance(parseFloat(walletBalanceE).toFixed(4));

    setWalletConnected(true);
    setWalletConnectSpinner(false);
  };

  return (
    <div className="content">
      {walletConnectSpinner && (
        <div
          id="overlay"
          onClick={() => closeOverlay()}
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            top: '0',
            bottom: '0',
            backgroundColor: 'rgba(0,0,0,0.5)',
            zIndex: '9',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <Spinner
            style={{
              position: 'absolute',
              top: '45%',
              left: '40%',
            }}
          ></Spinner>
        </div>
      )}
      {openModal && (
        <WalletProviderPopup
          open={openModal}
          handleClose={displayConnectWalletPopup}
          onWeb3Connect={fetchFundsAndInvestedAmount}
        />
      )}
      <Row>

        <DashboardCard
          heading="WALLET BALANCE"
          content={`${walletBalance ? walletBalance : 0} BNB &
            ${balanceUSDT ?? 0} BUSD`}
          color="blue"
          icon="icon-money-coins"
          useCol
        />
        <DashboardCard
          heading="INVESTED AMOUNT"
          content={`${investedAmount ? investedAmount : 0} BUSD`}
          color="violet"
          icon="icon-bank"
          useCol
        />

        <DashboardCard
          heading="WALLET"
          content={`${address}`}
          color="cerulean"
          icon="icon-wallet-43"
          allowCopy
          useCol
          isAddress={true}
        />
      </Row>

      <Row>
        <Col xs="12">
          <Card className="card-chart">
            <CardHeader>
              <Row>
                 <Col className={`text-left ${hoverValue >= 0 ? 'white-odometer' : 'red-odometer'}`} sm="4">
                  <h5 className="card-category font-weight-bold">
                    Performance
                  </h5>
                  <CardTitle tag="h2" style={{ color: 'red !important' }}>
                    <Odometer value={hoverValue} duration={1000} /> <span>%</span>
                  </CardTitle>
                </Col>

                <Col className="text-right" sm="5" style={{ paddingTop: '2%' }}>
                  <Button
                    className={`btn-round ${chartTimeframe === 7 ? 'active btn-active' : 'btn-blue'
                      }`}
                      id="1W"
                    size="sm"
                    style={{ fontSize: '10px' }}
                    onClick={() => setChartTimeframe(7)}
                  >
                      1W
                  </Button>
                  <Button
                    className={`btn-round ${chartTimeframe === 30 ? 'active btn-active' : 'btn-blue'
                      }`}
                     size="sm"
                    id='1w'
                    style={{ fontSize: '10px' }}
                    onClick={() => setChartTimeframe(30)}
                  >
                      1M
                  </Button>
                  <Button
                    color="secondary"
                    id="2M"
                     size="sm"
                    style={{ fontSize: '10px' }}
                    className={`btn-round ${chartTimeframe === 60 ? 'active btn-active' : 'btn-blue'
                      }`}
                    onClick={() => setChartTimeframe(60)}
                  >
                      2M
                  </Button>
                  <Button
                    color="secondary"
                    className={`btn-round ${chartTimeframe === 90 ? 'active btn-active' : 'btn-blue'
                      }`}
                    id="3M"
                    size="sm"
                    style={{ fontSize: '10px' }}
                    onClick={() => setChartTimeframe(90)}
                  >
                      3M
                  </Button>
                </Col>
                
                <Col className="text-right" sm="3">
                  <UncontrolledDropdown >
                    <DropdownToggle
                      caret
                      data-toggle="dropdown"
                      type="button"
                      size="sm"
                      className="btn btn-round btn-submit"
                    >
                      {selectedFund
                        ? selectedFund.name
                        : 'Choose Fund'}
                    </DropdownToggle>
                    <DropdownMenu aria-labelledby="dropdownMenuLink">
                      {fundNameAddrobj &&
                        fundNameAddrobj.map((i) => {
                          return (
                            <DropdownItem
                              onClick={() =>  setSelectedFund(i)}
                              key={i.name}
                            >
                              {i.name}
                            </DropdownItem>
                          );
                        })}
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </Col>
              </Row>
            </CardHeader>

            <CardBody>
             <div className="chart-area">
                  <Row className="justify-content-center">
                    <Col sm="11">
                      <CustomAreaChart data={chartData}/>
                    </Col>
                  </Row>
                  </div>
            </CardBody>
            {/* if no wallet connected display connect wallet; if wallet connected but new user display start investing */}

            {!walletConnectSpinner && !walletConnected && (
              <div
                id="overlay"
                onClick={() => closeOverlay()}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: '9',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: ' center',
                }}
              >
                <Button
                  className="btn-main-theme-secondary btn-align-center"
                  // style={{
                  //   position: 'absolute',
                  //   top: '50%',
                  //   left: '50%',
                  //   transform: 'translate(-50%, -50%)',
                  //   msTransform: 'translate(-50%,-50%)',
                  // }}
                  onClick={displayConnectWalletPopup}
                >
                  Connect Wallet
                </Button>
              </div>
            )}
            {walletConnected && newUser && (
              <div
                id="overlay"
                onClick={() => closeOverlay()}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: '9',
                  borderRadius: '5px',
                  cursor: 'pointer',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: ' center',
                }}
              >
                <Button
                  className="btn-main-theme-secondary btn-align-center"
                  onClick={() => props.history.push('/user/Funds')}
                >
                  Start Investing
                </Button>
              </div>
            )}

            {!newUser && (walletConnectSpinner || loading) && (
              <div
                id="overlay"
                onClick={() => closeOverlay()}
                style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
                  top: '0',
                  bottom: '0',
                  backgroundColor: 'rgba(0,0,0,0.5)',
                  zIndex: '9',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                {!walletConnectSpinner && (
                  <Spinner
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      // transform: "translate(-50%, -50%)",
                      // msTransform: "translate(-50%,-50%)"
                    }}
                  ></Spinner>
                )}
              </div>
            )}
           
          </Card>
        </Col>
      </Row>
      <Row className="justify-content-center" style={{ marginTop: '5px' }}>
        <Col lg="2">
          <DepositModal />
        </Col>
        <Col lg="1">
          <></>
        </Col>
        <Col lg="2">
          <DashboardWithdrawalModal />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
