import React from 'react'

import { Area, AreaChart, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import Odometer from 'react-odometerjs';
import 'odometer/themes/odometer-theme-default.css';

const CustomToolTip = (props) => {
    const { active, payload, label } = props;
    if (active) {
        return (
            <div className="performance-tooltip">
                <h5>{label}</h5>
                <div className={`${payload[0].value >= 0 ? 'white-odometer' : 'red-odometer'}`}>
                    <h2>
                        <Odometer value={payload[0].value} /> <span> % </span>
                    </h2>
                </div>
            </div>
        );
    }
    return null;
}


export const CustomAreaChart = (props) => {
    const data = props.data
    const Mockdata = [
        {
          "timestamp": "22 Aug",
          "value": -9.093
        },
        {
          "timestamp": "23 Aug",
          "value": -9.093
        },
        {
          "timestamp": "24 Aug",
          "value": -14.95
        },
        {
          "timestamp": "25 Aug",
          "value": -14.95
        },
        {
          "timestamp": "26 Aug",
          "value": -11.74
        },
        {
          "timestamp": "27 Aug",
          "value": 1.35
        },
        {
          "timestamp": "28 Aug",
          "value": 18.25
        }, 
        {
          "timestamp": "31 Aug",
          "value": 19.25
        },
        {
          "timestamp": "23 Aug",
          "value": 29.25
        },
        {
          "timestamp": "24 Aug",
          "value": 19.25
        },
        {
          "timestamp": "25 Aug",
          "value": 15.25
        },
        {
          "timestamp": "26 Aug",
          "value": 10.25
        },
        {
          "timestamp": "27 Aug",
          "value": 9.25
        },
        {
          "timestamp": "28 Aug",
          "value": 12.25
        },  
        {
          "timestamp": "31 Aug",
          "value": 19.25
        },
        {
          "timestamp": "23 Aug",
          "value": 29.25
        },
        {
          "timestamp": "24 Aug",
          "value": 19.25
        },
        {
          "timestamp": "25 Aug",
          "value": 15.25
        },
        {
          "timestamp": "26 Aug",
          "value": 10.25
        },
        {
          "timestamp": "27 Aug",
          "value": 9.25
        },
        {
          "timestamp": "28 Aug",
          "value": 12.25
        },
        {
          "timestamp": "31 Aug",
          "value": 8.25
        },
        {
          "timestamp": "23 Aug",
          "value": 19.25
        },
        {
          "timestamp": "24 Aug",
          "value": 39.25
        },
        {
          "timestamp": "25 Aug",
          "value": 45.25
        },
        {
          "timestamp": "26 Aug",
          "value": 30.25
        },
        {
          "timestamp": "27 Aug",
          "value": 39.25
        },
        {
          "timestamp": "28 Aug",
          "value": 22.25
        }, 
        {
          "timestamp": "31 Aug",
          "value": 9.25
        },
        {
          "timestamp": "23 Aug",
          "value": 11.25
        },
        {
          "timestamp": "24 Aug",
          "value": 21.25
        },
        {
          "timestamp": "25 Aug",
          "value": 5.25
        },
        {
          "timestamp": "26 Aug",
          "value": 3.25
        },
        {
          "timestamp": "27 Aug",
          "value": -1.25
        },
        {
          "timestamp": "28 Aug",
          "value": -5.25
        },
      
        {
          "timestamp": "31 Aug",
          "value": -19.25
        },
        {
          "timestamp": "23 Aug",
          "value": -29.25
        },
        {
          "timestamp": "24 Aug",
          "value": -19.25
        },
        {
          "timestamp": "25 Aug",
          "value": -15.25
        },
        {
          "timestamp": "26 Aug",
          "value": -10.25
        },
        {
          "timestamp": "27 Aug",
          "value": -9.25
        },
        {
          "timestamp": "28 Aug",
          "value": -12.25
        },
      ] 
    if(!data) {
        return <></>
    }

    // const formatXAxis = (tickItem) => {
    // return tickItem.toLocaleDateString();
    // }
    
    return (
        <div><ResponsiveContainer width="100%" height={350}>
            <AreaChart
                width={500}
                height={350}
                data={data}
            >
                <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="0%" stopColor="#B409D4" stopOpacity={0.9} />
                        <stop offset="100%" stopColor="#10A3D6" stopOpacity={0.5} />
                    </linearGradient>
                    {/* <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                          </linearGradient> */}
                </defs>
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
               
                <XAxis dataKey="timestamp"  
                    hide={true}
                     domain={['dataMin', 'dataMax']} 
                     allowDataOverflow={true} 
                     axisLine={false} 
                     tickLine={false} 
                     padding={{ left: 50, right :50 }}
                    //  angle={35}
                    // dx={15}
                    // dy={20}
                    // minTickGap={-200}
                    //  domain={['auto', 'auto']} 
                    // tickFormatter={formatXAxis}
                    // tickFormatter={(i)=> {
                    //     let x = i.split(' ');
                    //     console.log("x:",x);
                    //     // if(data.length<10){
                    //     //     return i
                    //     // }
                    //     console.log("xx:",parseInt(x[0]) % 7);

                    //     if((parseInt(x[0]) % 7)==0){
                    //         return i
                    //     }
                    //     return '';
                    // } }
                    //   textAnchor="start"
                    //   domain={['auto', 'auto']} 
                     />

                <YAxis 
                    domain={[-10, 'auto']} 
                    tickLine={false} 
                    axisLine={false} 
                    //  ticks={[-60,-40,-20, 0, 20, 40,60,80,100,'dataMax']}
                    //   domain={[-20, 'dataMax']} 
                    //    tickCount={20} 
                />

                
                <Tooltip content={<CustomToolTip />} />
                <Area type="monotone" dataKey="value" stroke="#8884d8" strokeWidth="3" fill="url(#colorUv)" baseline={0} />
                {/* <Brush dataKey="date" height={30} stroke="#8884d8" /> */}
                <CartesianGrid opacity={0.1} vertical={false} />
            </AreaChart>
        </ResponsiveContainer></div>
    )
}

