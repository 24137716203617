import React, { useState } from 'react';
import { Row, Col, Button, Spinner } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import PercentButtons from '../../views/TradeViews/PercentButtons';
import Divider from '@material-ui/core/Divider';
import HelpIcon from '@material-ui/icons/Help';

import { withStyles } from '@material-ui/core/styles';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

import { ROUTER_DEFI_ADDR, TETHERTOKEN_ADDR } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  actionButton: {
    margin: 'auto',
    width: 'fit-content',
  },
  textAlignCenter: {
    textAlign: 'center'
  }
}));
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}))(InputBase);

export default function WithdrawInvestmentModal({ amount, usdtBalance, assets, routerAddress, inActive = false, withdrawCallback }) {


  const classes = useStyles()
  const [open, setOpen] = React.useState(false);
  const [selectedAmount, setSelectedAmount] = useState(0);
  const [withdrawLoading, setWithdrawLoading] = useState(false);
  const [withdrawErr, setWithdrawErr] = useState({ value: false, showErrMessage: false });
  const [selectedValue, setSelectedValue] = useState('2');
  const [selectedToken, setSelectedToken] = useState('DAI'); //(assets[0].symbol);  //('DAI');
  const [selectedTokenAddress, setSelectedTokenAddress] = useState('0x0000000000000000000000000000000000000000');  //(assets[0].tokenAddress);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
    if (event.target.value === '2') {
      setSelectedTokenAddress('0x0000000000000000000000000000000000000000');
    }
    else {
      console.log(`assets : `, assets)
      let token = assets.find((a) => a.symbol === event.target.value);
      if (token) {
        console.log("handleChange tokenAddr: ", token.tokenAddress)
        setSelectedTokenAddress(token.tokenAddress);
      } else {
        setSelectedTokenAddress(TETHERTOKEN_ADDR);
      }
    }
  };

  const handleChange2 = (event) => {
    let tokenSelected = event.target.value;
    setSelectedToken(tokenSelected);
    console.log("tokenSelected:", tokenSelected);
    let token = assets.find((a) => a.symbol === tokenSelected);
    console.log("handleChange2 tokenAddr: ", token.tokenAddress)
    setSelectedTokenAddress(token.tokenAddress);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedAmount(0)
  };

  const handleWithdraw = async () => {
    if (routerAddress !== ROUTER_DEFI_ADDR && !inActive) {
      setWithdrawLoading(true);

      try {
        await withdrawCallback(amount, selectedTokenAddress, routerAddress);
      } catch (error) {
        
      }
      setWithdrawLoading(false);
        handleClose();
        return
    }

    if (selectedAmount <= 0 || selectedAmount > amount) {
      setWithdrawErr({ value: true, showErrMessage: true });
    } else {
      setWithdrawErr({ value: false, showErrMessage: false });
      setWithdrawLoading(true);

      try {
        if (inActive) {
          // await withdrawCallback(amount, TETHERTOKEN_ADDR);
          await withdrawCallback(selectedAmount, TETHERTOKEN_ADDR, routerAddress);
        }
        else {
          // await withdrawCallback(amount, selectedTokenAddress);
          await withdrawCallback(selectedAmount, selectedTokenAddress, routerAddress);
        }
      } catch (error) { console.error(error) } finally {
        handleClose();
      }
      setWithdrawLoading(false);
    }
  }

  return (
    <div>
      <button className="btn btn-round btn-submit" onClick={handleClickOpen}>
        Manage
      </button>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Divider />
        <DialogTitle id="form-dialog-title">
          <Row style={{ color: 'black' }}>
            <Col className="d-flex justify-content-center">Withdraw</Col>
          </Row>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Withdrawable Amount = <b> {amount} BUSD </b>
          </DialogContentText>
          <DialogContentText>
            {
              (routerAddress !== ROUTER_DEFI_ADDR && !inActive) ?
                <b>currently only full withdraw is available</b>
                :
                <b>Please enter an amount you would like to withdraw</b>
            }
          </DialogContentText>


          {
            (routerAddress === ROUTER_DEFI_ADDR || inActive) &&

            <>
              <TextField
                autoFocus
                variant="outlined"
                margin="dense"
                id="name"
                label="Amount"
                type="number"
                fullWidth
                error={withdrawErr.value}
                helperText={withdrawErr.showErrMessage ? "Amount should be less than the invested amount" : ""}
                // value={amount}
                value={selectedAmount ? selectedAmount : ''}
                onChange={(e) => setSelectedAmount(e.target.value)}
              />
              <PercentButtons value={amount} callback={(value) => setSelectedAmount(value)} />
            </>
          }

          {
            !inActive &&

            <div style={{ textAlign: "center", marginTop: '1rem' }}>
              Withdraw in a selected Token
              <Radio checked={selectedValue === '1'}
                onChange={handleChange}
                value="1"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'Withdraw in a selected Token' }}
              />
              <p style={{ display: "contents", color: "rgb(0 0 0 / 87%)" }}>
                Withdraw in all tokens
                {/* warning for using href="#" */}
                {/* eslint-disable-next-line */}
                <a href="#" data-toggle="tooltip" title="Withdraws assets directly as allocated in fund. Cheapest withdrawal method as no swaps are executed and withdrawal is done directly.">
                  <HelpIcon
                    className="ml-2"
                    id="performanceFeeTooltip"
                    fontSize="small"
                    style={{ color: '#7639a7' }}
                  />
                </a>
              </p>

              <Radio checked={selectedValue === '2'}
                onChange={handleChange}
                value="2"
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'Withdraw in all tokens' }}
              />
              <br />
              {
                (selectedValue === '1') &&
                <NativeSelect
                  id="demo-customized-select-native"
                  // id="demo-simple-select-autowidth"
                  value={selectedToken}
                  onChange={handleChange2}
                  input={<BootstrapInput />}
                >
                  {assets && assets.map((asset) => <option key={asset.tokenAddress} value={asset.symbol}>{asset.symbol}</option>)}
                </NativeSelect>
              }
            </div>
          }


        </DialogContent>


        <DialogActions className={classes.actionButton} >
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleWithdraw} color="success" disabled={withdrawLoading}>
            {withdrawLoading &&
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />}
            {
              !withdrawLoading && "Confirm"
            }

          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

WithdrawInvestmentModal.propTypes = {
  amount: PropTypes.number,
  usdtBalance: PropTypes.number,
  assets: PropTypes.array,
  withdrawCallback: PropTypes.func
}
