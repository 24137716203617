import Dashboard from './views/DashboardOwner.js';

var routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'tim-icons icon-chart-pie-36',
    component: Dashboard,
    layout: '/owner',
  },
];
export default routes;
