import React from 'react';
import TradeTable from '../components/OwnerTable';

// reactstrap components
import { Card, CardHeader, CardTitle, Row, Col } from 'reactstrap';

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bigChartData: 'data1',
    };
  }
  setBgChartData = (name) => {
    this.setState({
      bigChartData: name,
    });
  };
  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Total value locked</h5>
                  <CardTitle tag="h3">0.0000 BUSD</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">total clients</h5>
                  <CardTitle tag="h3">100000</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Investor list</h5>
                  <TradeTable />
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">contract balance</h5>
                  <CardTitle tag="h3">0.0000 ETH</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">INVESTED AMOUNT</h5>
                  <CardTitle tag="h3">0.0000 BUSD</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">Fund name and get balnce</h5>
                  <CardTitle tag="h3">0</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">add vaults</h5>
                  <CardTitle tag="h3">0.0000 ETH</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">
                    get Fund vault and add gas drop down{' '}
                  </h5>
                  <CardTitle tag="h3">0.0000 BUSD</CardTitle>
                </CardHeader>
              </Card>
            </Col>
            <Col lg="4">
              <Card className="card-chart">
                <CardHeader>
                  <h5 className="card-category">
                    change vault status and ivn token function query{' '}
                  </h5>
                  <CardTitle tag="h3">0</CardTitle>
                </CardHeader>
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default Dashboard;
