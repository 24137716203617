import { API_URL } from '../utils/constants'
import { useQuery } from 'react-query'

export const useApiFunds = () => {
    const { isLoading,
        // error,
        data } = useQuery('apiFunds', () =>
        fetch(`${API_URL}/decentralisedFund`).then(res =>
            res.json()
        ),
        {refetchOnWindowFocus:false}
    )

    return { isLoading, data };
}
