import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import FundGridCard from './FundGridCard';
// import { useState ,useEffect } from "react";
import 'react-toastify/dist/ReactToastify.css';

const FundGridView = (props) => {
  const dataLimit = 12;
  const pageLimit = 3;
  const { funds: data, searchText, selectedFilter } = props;

  const [pages, setPages] = useState(Math.round(data?.length / dataLimit) ?? 0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    if (data) {
      setPages(Math.round(data.filter((f) => parseFloat(f.totalAmount) > 0).length / dataLimit) ?? 0)
    }
  }, [data])

  function goToNextPage() {
    setCurrentPage((page) => page + 1);
  }

  function goToPreviousPage() {
    setCurrentPage((page) => page - 1);
  }

  const filterFunds = (items) => {
    switch (selectedFilter) {
      case "Highest Performance":
        return items.sort((a, b) => parseFloat(b.currentPerformance) - parseFloat(a.currentPerformance))
      case "Lowest Performance":
        return items.sort((a, b) => parseFloat(a.currentPerformance) - parseFloat(b.currentPerformance))
      case "Highest AUM":
        return items.sort((a, b) => parseFloat(b.totalAmount) - parseFloat(a.totalAmount))
      case "Lowest AUM":
        return items.sort((a, b) => parseFloat(a.totalAmount) - parseFloat(b.totalAmount))
      default:
        return data
    }
  }

  const fundsFiltered = useMemo(() => {
    if (!data) return []
    const nonZeroFunds = data.filter((f) => parseFloat(f.totalAmount) > 1); // non-zero funds >=0
    if (searchText !== "" && selectedFilter !== "") {
      return filterFunds(nonZeroFunds.filter((fund) => fund.fundName.toLowerCase().includes(searchText.toLowerCase())));
    } else if (searchText === "" && selectedFilter !== '') {
      return filterFunds(nonZeroFunds)
    } else if (searchText !== "") {
      return nonZeroFunds.filter((fund) => fund.fundName.toLowerCase().includes(searchText.toLowerCase()));
    } else {
      return nonZeroFunds
    }
  }, [selectedFilter, data, searchText]) // eslint-disable-line react-hooks/exhaustive-deps


  function changePage(event) {
    const pageNumber = Number(event.target.textContent);
    setCurrentPage(pageNumber);
  }

  const getPaginatedData = () => {
    if (fundsFiltered){
      const startIndex = currentPage * dataLimit - dataLimit;
      const endIndex = startIndex + dataLimit;
       
      return fundsFiltered.slice(startIndex, endIndex);
    }
  };

  const getPaginationGroup = () => {
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit;
    return new Array(pageLimit).fill().map((_, idx) => start + idx + 1);
  };

  return (
    <div>
      <Row>
        {fundsFiltered &&
          getPaginatedData().map((fund, i) => {
            return (
              <Col lg="4" key={i}>
                <FundGridCard fund={fund} />
              </Col>
            );
          })}
      </Row>
      {data &&
        <>
          <Row className="justify-content-center mt-5 ml-0 mr-0">
            <nav aria-label="Page navigation example">

              <Pagination
                className="pagination pagination-success"
                listClassName="pagination-warning"
              >

                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === 1 ? 'violet' : 'theme-primary'
                      }`}
                    onClick={() => setCurrentPage(1)}
                  >
                    <span aria-hidden={true}>
                      <FirstPageIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === 1 ? 'violet' : 'theme-primary'
                      }`}
                    onClick={goToPreviousPage}
                  >
                    <span aria-hidden={true}>
                      <NavigateBeforeIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                {getPaginationGroup().map((item, index) => (
                  <PaginationItem key={index} active={currentPage === item}>
                    <PaginationLink onClick={changePage}>
                      {item}
                    </PaginationLink>
                  </PaginationItem>
                ))}


                <PaginationItem disabled={currentPage === pages}>
                  <PaginationLink
                    aria-label="Previous"
                    className={`${currentPage === pages ? 'violet' : 'theme-primary'
                      }`}
                    onClick={goToNextPage}
                  >
                    <span aria-hidden={true}>
                      <NavigateNextIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>

                <PaginationItem disabled={currentPage === pages}>
                  <PaginationLink
                    aria-label="Previous"

                    className={`${currentPage === pages ? 'violet' : 'theme-primary'
                      }`}
                    onClick={() => setCurrentPage(pages)}
                  >
                    <span aria-hidden={true}>
                      <LastPageIcon className="tim-icons icon-double-left" />
                    </span>
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </nav>
          </Row>
        </>
      }
    </div>
  );
};

export default FundGridView;
